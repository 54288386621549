import { EPageType } from '../../typings/enums';

export const APP_TITLE = 'Gipsy Hill Brewing Company';
export const SITE_TITLE = 'South London Craft Beer';
export const SITE_AUTHOR = 'Gipsy Hill Brewing Company Ltd';
export const GHBC_POST_CODE = 'SE27 9SF';
export const SITE_DESCRIPTION =
  'At Gipsy Hill Brewing, we make full flavour, moreish, natural beers. We are a South London brewery based in Gipsy Hill. Drink it and talk to people.';
export const CURRENT_YEAR = new Date().getFullYear();

export const DEFAULT_CURRENCY = '£';

export const BETTER_REST_ENDPOINT = 'wp-json/better-rest-endpoints/v1';
export const WP_V2_ENDPOINT = 'wp-json/wp/v2';
export const CPT_PDP_ENDPOINT = 'wp-json/cpt/pdp/v1';
export const CPT_PLP_ENDPOINT = 'wp-json/cpt/plp/v1';
export const CPT_OPTIONS_ENDPOINT = 'wp-json/cpt/acf/v1/options';
export const JWT_AUTH_ENDPOINT = 'wp-json/jwt-auth/v1';
export const AUTH_ENDPOINT = 'wp-json/cpt/auth/v1';
export const CART_ENDPOINT = 'wp-json/cocart/v1';

export const BLOG_ITEMS_PER_PAGE = 7;
export const EVENTS_ITEMS_PER_PAGE = 4;
export const ITEMS_PER_PAGE = 12;
export const ITEMS_PER_REQUEST = 12;

export const APP_KEYWORDS =
  'Gipsy Hill Brewing Company,Gipsy Hill Brew,Gipsy Hill,Craft Beer,JPPdesigns,PixelTwist';

export const APP_VERSION = '0.1.0';
export const APP_LANG = 'en';

export const SUCCESS_STATUS_CODE = 200;
export const BAD_REQUEST_STATUS_CODE = 400;
export const NOT_FOUND_STATUS_CODE = 404;
export const FORBIDDEN_STATUS_CODE = 403;
export const HTTP_UNPROCESSABLE_ENTITY = 422;
export const SERVER_ERROR_STATUS_CODE = 500;

export const HEADER_SCROLLING_THRESHOLD = 300;

export const RESERVED_SLUGS = Object.values(EPageType);
export const GHBC_SITE_WIDE_MESSAGE = 'GHBC_SITE_WIDE_MESSAGE';
export const GHBC_CHECKOUT_FORM = 'GHBC_CHECKOUT_FORM';

export const GIFT_CARD_PREFIX = 'GIP_EGFT';
