import { IPdpStoreState } from 'common/redux/pdp/reducer';

export const pdpTransformer = (pdp: any): IPdpStoreState => {
  const {
    ID,
    id,
    acf,
    slug,
    title,
    yoast,
    virtual,
    price,
    featured_image,
    image,
    stock_status,
    regular_price,
    sale_price,
    date_on_sale_from,
    date_on_sale_to,
    sku,
    pdp_type,
    ...rest
  } = pdp;

  return {
    ID: ID || id,
    id,
    acf: pdp.acf_fields || acf,
    slug,
    title,
    yoast: yoast || pdp.yoast_meta,
    virtual,
    price,
    stock_status,
    regular_price,
    sale_price: sale_price ? sale_price : undefined,
    date_on_sale_from: date_on_sale_from ? date_on_sale_from : undefined,
    date_on_sale_to: date_on_sale_to ? date_on_sale_to : undefined,
    image,
    featured_image,
    sku,
    pdp_type,
    ...rest,
  };
};
