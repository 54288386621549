/* tslint:disable */
import Provider from '../Provider';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const envConfig = publicRuntimeConfig;

export type Config = {
  readonly PORT: string;
  readonly SERVER_PORT: string;
  readonly PROTOCOL: string;

  readonly LOG_ROCKET_ID: string;

  readonly APP_LANG: string;

  readonly INTERNAL_URL: string;

  readonly API_URL: string;
  readonly API_BASE_URL: string;

  readonly SITE_URL: string;
  readonly SITE_BASE_URL: string;

  readonly SHOP_URL: string;
  readonly SHOP_BASE_URL: string;

  readonly STRIPE_PUBLIC: string;
  readonly STRIPE_SECRET: string;
  readonly STRIPE_FULL_INTEGRATION: 'true' | 'false';

  readonly RECAPTCHA_SITE_KEY_V2: string;
  readonly RECAPTCHA_SECRET_V2: string;
  readonly RECAPTCHA_SITE_KEY_V3: string;
  readonly RECAPTCHA_SECRET_V3: string;

  readonly AUTH_ENABLED: 'true' | 'false';

  readonly APPLE_PAY_ENABLED: 'true' | 'false';

  readonly AGE_VERIFICATION: 'true' | 'false';

  readonly RUN_ENV: 'dev' | 'development' | 'prod' | 'test';
  readonly NODE_ENV: 'development' | 'production' | 'test';
};

const ConfigProvider = new Provider<Config & NodeJS.ProcessEnv, keyof Config>(
  'ConfigProvider',
  // @ts-ignore
  envConfig as Config
);

export default ConfigProvider;
