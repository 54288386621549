import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import { getValidURLQueries } from '@jpp/atoms/AnchorLink/utils';
import { EPageType } from 'common/typings/enums';

import './AnchorLink.scss';

export interface IAnchorLinkProps {
  className?: string;
  href: string;
  rel?: string;
  target?: Core.TAnchorTarget;
  behaviour?: Core.TLinkBehaviour;
  onClick?: Core.TOnClick;
  withStyles?: boolean;
  isExternal?: boolean;
  queryParams?: Record<string, any>;
  removeSaSQuery?: boolean;
}

const AnchorLink: FunctionComponent<IAnchorLinkProps> = ({
  children,
  className,
  behaviour = 'router',
  href,
  target,
  onClick,
  isExternal = false,
  queryParams = {},
  removeSaSQuery = true,
}) => {
  const router = useRouter();
  const query = getValidURLQueries(
    { ...router.query, ...queryParams },
    removeSaSQuery
  );
  const isActive = router?.asPath === href;
  const termsPages = [
    EPageType.TermsAndConditions,
    EPageType.ReturnPolicy,
    EPageType.CookiePolicy,
    EPageType.PrivacyPolicy,
  ];
  const isTermsPage = termsPages.some((termPage) => `/${termPage}` === href);
  const shouldRenderAnchor =
    behaviour === 'anchor' || target === '_blank' || isTermsPage || isExternal;

  const defaultProps: Partial<
    Pick<IAnchorLinkProps, 'onClick' | 'className' | 'target' | 'href' | 'rel'>
  > = {
    className: classNames('AnchorLink', className, {
      'AnchorLink--is-active': isActive,
      'AnchorLink--is-external': isExternal,
    }),
    onClick,
  };

  if (shouldRenderAnchor) {
    defaultProps.target = isTermsPage ? undefined : '_blank';
    defaultProps.href = `${href}${query && !isExternal ? '?' + query : ''}`;
    defaultProps.rel = 'noopener noreferrer';

    return <a {...defaultProps}>{children}</a>;
  }

  return (
    <Link
      href={{
        pathname: href.startsWith('/') ? href : `/${href}`,
        query,
      }}
    >
      <a {...defaultProps}>{children}</a>
    </Link>
  );
};

export default AnchorLink;
