import { EGaEvents } from 'common/tracking/types';
import { pushToDataLayer } from 'common/tracking/utils';
import { ECurrencyCode } from 'common/typings/enums';
import { APP_TITLE } from 'common/utils/constants';
import { getPriceWithoutCurrency } from 'common/utils/shared/product';

export const trackGoogleAddToBag = (
  name: string,
  id: number | string,
  sku: string,
  price: string,
  quantity: number,
  category?: string
) => {
  return pushToDataLayer({
    event: EGaEvents.AddToCart,
    ecommerce: {
      currencyCode: ECurrencyCode.GBP,
      add: {
        actionField: {
          list: 'Shopping cart',
        },
        products: {
          name,
          id,
          sku,
          price: parseFloat(price).toFixed(2),
          totalPrice: parseFloat(price) * quantity,
          brand: APP_TITLE,
          quantity,
          category,
        },
      },
    },
  });
};

export const trackGoogleUpdateCart = (
  name: string,
  id: number,
  sku: string,
  price: string,
  quantity: number,
  category?: string
) => {
  return pushToDataLayer({
    event: EGaEvents.UpdateCart,
    ecommerce: {
      currencyCode: ECurrencyCode.GBP,
      add: {
        actionField: {
          list: 'Shopping cart',
        },
        products: {
          name,
          id,
          sku,
          price: getPriceWithoutCurrency(price),
          totalPrice: parseFloat(price) * quantity,
          brand: APP_TITLE,
          quantity,
          category,
        },
      },
    },
  });
};

export const trackGoogleRemoveFromCart = (
  name: string,
  id: number,
  sku: string,
  price: string
) => {
  return pushToDataLayer({
    event: EGaEvents.RemoveFromCart,
    ecommerce: {
      currencyCode: ECurrencyCode.GBP,
      remove: {
        actionField: {
          list: 'Shopping cart',
        },
        products: {
          name,
          id,
          sku,
          price: getPriceWithoutCurrency(price),
          brand: APP_TITLE,
        },
      },
    },
  });
};
