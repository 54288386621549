import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { EPosition } from 'common/typings/enums';

import './WYSIWYG.scss';

interface IWYSIWYGProps {
  className?: string;
  textAlign?: EPosition;
  content?: string;
}

export const WYSIWYG: FunctionComponent<IWYSIWYGProps> = ({
  className,
  content,
  textAlign,
}) => {
  if (!content) {
    console.info('WYSIWYG: No content provided');
    return null;
  }
  return (
    <div
      className={classNames(className, 'WYSIWYG', {
        'WYSIWYG--text-center': textAlign === EPosition.Center,
      })}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default WYSIWYG;
