import classNames from 'classnames';
import React from 'react';

import Button from '@jpp/molecules/Button';
import { getPackSizeClasses } from '@jpp/molecules/SellBlock/SellBlock';
import { IPdpAvailableQuantity, IPdpProduct } from 'common/redux/pdp/reducer';
import { ECtaTheme, EPageType, ESize } from 'common/typings/enums';

interface IMiniKegPackSizesProps {
  className?: string;
  canProduct?: IPdpProduct;
  quantities: IPdpAvailableQuantity[];
  selectedPackSize: IPdpAvailableQuantity;
  slug: string;
}

const ROOT_CLASSNAME = 'PackSizes';

const MiniKegPackSizes: React.FunctionComponent<IMiniKegPackSizesProps> = ({
  className,
  quantities,
  selectedPackSize,
  canProduct,
  slug,
}) => {
  const { label, value } = quantities[0];

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        getPackSizeClasses(quantities)
      )}
    >
      <ul className={`${ROOT_CLASSNAME}__list`}>
        <li key={label} className={`${ROOT_CLASSNAME}__item`}>
          <Button
            className={classNames(`${ROOT_CLASSNAME}__button`, {
              [`${ROOT_CLASSNAME}__button--is-selected`]:
                selectedPackSize.label === label,
            })}
            size={ESize.Sm}
            theme={ECtaTheme.TintPsiOutline}
            behaviour="router"
            link={`/${EPageType.Product}/${slug.replace(/[0-9]+/g, value)}`}
            isFull={true}
          >
            {label}
          </Button>
        </li>

        {canProduct && (
          <li key="can" className={`${ROOT_CLASSNAME}__item`}>
            <Button
              className={`${ROOT_CLASSNAME}__button`}
              size={ESize.Sm}
              theme={ECtaTheme.TintPsiOutline}
              behaviour="router"
              isFull={true}
              link={`/${EPageType.Product}/${canProduct.post_name}`}
            >
              440ml Can
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MiniKegPackSizes;
