import qs from 'querystring';

import { getQueryParamFirstValue } from 'common/utils/shared/route';

const SUBSCRIBE_AND_SAVE = 'subscribe_and_save';

export const getValidURLQueries = (
  query: qs.ParsedUrlQuery,
  removeSaSQuery = true
): string => {
  const allowedQueries = [
    'coupon',
    'coupon-code',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'source',
    'mc_cid',
    SUBSCRIBE_AND_SAVE,
  ];
  const queryEntries = Object.entries(query);

  if (queryEntries.length === 0) {
    return '';
  }

  const queries: string[] = [];
  queryEntries.forEach(([key, value]) => {
    if (allowedQueries.includes(key)) {
      if (removeSaSQuery && key === SUBSCRIBE_AND_SAVE) {
        return;
      }
      return queries.push(`${key}=${getQueryParamFirstValue(value)}`);
    }
    return;
  });
  return queries.filter((q) => !!q).join('&');
};
