import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import LoginForm from '@jpp/molecules/Auth/LoginForm';
import RegisterForm from '@jpp/molecules/Auth/RegisterForm';
import ModalCore from '@jpp/organisms/Modal';
import { IModalChildProps } from '@jpp/organisms/Modal/ModalCore';
import { EPriority } from 'common/typings/enums';

import './AuthModal.scss';

const ROOT_CLASSNAME = 'AuthModal';

export enum EAuthModalTab {
  Login = 'login',
  Register = 'register',
}

export type TAuthModal = { defaultTab?: EAuthModalTab } & IModalChildProps;

const AuthModal: React.FunctionComponent<TAuthModal> = ({
  className,
  bodyOverflow,
  isVisible = false,
  defaultTab = EAuthModalTab.Login,
  onClose,
}) => {
  const [tab, setTab] = React.useState<EAuthModalTab>(defaultTab);

  const isLoginTab = tab === EAuthModalTab.Login;

  const tabDetails = {
    [EAuthModalTab.Login]: 'Login',
    [EAuthModalTab.Register]: 'Sign Up',
  };

  return (
    <ModalCore
      isVisible={isVisible}
      bodyOverflow={bodyOverflow}
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${tab}`
      )}
      onClose={onClose}
    >
      <Heading
        priority={EPriority.Four}
        className={`${ROOT_CLASSNAME}__heading`}
        children={tabDetails[tab]}
      />

      {isLoginTab ? (
        <LoginForm
          className={`${ROOT_CLASSNAME}__login-form`}
          withHeading={false}
          onSuccess={onClose}
          hasSimpleLink={true}
        />
      ) : (
        <RegisterForm
          className={`${ROOT_CLASSNAME}__register-form`}
          withHeading={false}
          onSuccess={onClose}
        />
      )}

      <div className={`${ROOT_CLASSNAME}__terms`}>
        {isLoginTab ? (
          <>
            Don't have an account? &nbsp;
            <button
              className={`${ROOT_CLASSNAME}__tab-button`}
              type="button"
              onClick={() => setTab(EAuthModalTab.Register)}
            >
              Sign up
            </button>
          </>
        ) : (
          <>
            Already have an account? &nbsp;
            <button
              className={`${ROOT_CLASSNAME}__tab-button`}
              type="button"
              onClick={() => setTab(EAuthModalTab.Login)}
            >
              Login
            </button>
          </>
        )}
      </div>
    </ModalCore>
  );
};

export default AuthModal;
