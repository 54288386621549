import { IReduxState } from 'common/redux/createStore';
import { IPdpProduct } from 'common/redux/pdp/reducer';
import { EMenuType } from 'common/typings/enums';
import { TReduxError } from 'common/typings/types';

export const getAppTitle = (state: IReduxState): string => {
  return state?.core.title;
};

export const getAppDescription = (state: IReduxState): string => {
  return state?.core.description;
};

export const getLoadingState = (state: IReduxState): boolean => {
  return state?.core.isLoading;
};

export const getErrorState = (state: IReduxState): boolean => {
  return state?.core.hasError;
};

export const getError = (state: IReduxState): TReduxError | undefined => {
  return state?.core.error;
};

export const getOptions = (state: IReduxState): Core.IOptions => {
  return state?.core.options;
};

export const getSponsors = (
  state: IReduxState
): Core.ISponsor[] | undefined => {
  const options = getOptions(state);
  return options && options.sponsors;
};

export const getUpsellProducts = (state: IReduxState): IPdpProduct[] => {
  const options = getOptions(state);
  return options && options.upsell_products;
};

export const getMenuProducts = (state: IReduxState): IPdpProduct[] => {
  const options = getOptions(state);
  return options && options.menu_products;
};

export const getSiteWideMessage = (
  state: IReduxState
): Core.IOptions['site_wide_message'] => {
  const options = getOptions(state);
  return options && options.site_wide_message;
};

export const getMenuState = (state: IReduxState): boolean => {
  return state?.core.isMenuOpen;
};

export const getMenu = (
  state: IReduxState,
  menuType: EMenuType
): Core.IMenuItem[] => {
  return state?.core?.menus[menuType];
};
