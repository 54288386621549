import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { setAppError, setAppLoading } from 'common/redux/core/actions';
import { IReduxDispatch, IReduxState } from 'common/redux/createStore';
import axios from 'common/utils/axios/internal';
import { EVENTS_ITEMS_PER_PAGE } from 'common/utils/constants';

import {
  CLEAR_CATEGORY_EVENTS,
  CLEAR_EVENTS,
  CLEAR_EVENTS_STATE,
  CLEAR_FEATURED_EVENTS,
  GET_CATEGORY_EVENTS,
  GET_EVENTS,
  GET_EVENTS_FAILED,
  GET_EVENTS_SUCCESS,
  GET_FEATURED_EVENTS,
  GET_FEATURED_EVENTS_FAILED,
  GET_FEATURED_EVENTS_SUCCESS,
} from './constants';
import { IEventListState } from './reducer';

export const getEvents: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (page?: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    dispatch({ type: GET_EVENTS });

    const url = `/events/${page || 1}/${EVENTS_ITEMS_PER_PAGE}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));

        // We check for the error as wordpress doesn't return a 404.
        if (response.data.length === 0) {
          const error = {
            message: 'Events not found',
            hasError: true,
            code: 404 as Core.TErrorCode,
          };
          dispatch(setAppError(true));
          return dispatch(getEventsFailed(error));
        }

        return dispatch(getEventsSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getEventsFailed(error));
      });
  };
};

export const getCategoryEvents: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (category: string, page?: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    const isFeatured = category === 'featured';
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    if (isFeatured) {
      dispatch({ type: GET_FEATURED_EVENTS });
    } else {
      dispatch({ type: GET_CATEGORY_EVENTS });
    }

    const url = `/events-category/${category}/${
      page || 1
    }/${EVENTS_ITEMS_PER_PAGE}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));

        // We check for the error as wordpress doesn't return a 404.
        if (response.data.length === 0) {
          const error = {
            message: isFeatured
              ? 'Featured Events not found'
              : `${category} Events not found`,
            hasError: true,
            code: 404 as Core.TErrorCode,
          };
          dispatch(setAppError(true));

          if (isFeatured) {
            return dispatch(getFeaturedEventsFailed(error));
          }
          return dispatch(getCategoryEventsFailed(error));
        }

        if (isFeatured) {
          return dispatch(getFeaturedEventsSuccess(response.data));
        }

        return dispatch(getCategoryEventsSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));

        if (isFeatured) {
          return dispatch(getFeaturedEventsFailed(error));
        }

        return dispatch(getCategoryEventsFailed(error));
      });
  };
};

export const getEventsSuccess = (payload: IEventListState) => ({
  type: GET_EVENTS_SUCCESS,
  payload,
});

export const getEventsFailed = (error: Core.IErrorResponse | AxiosError) => ({
  type: GET_EVENTS_FAILED,
  payload: {
    error,
  },
});

export const getFeaturedEventsSuccess = (payload: IEventListState) => ({
  type: GET_FEATURED_EVENTS_SUCCESS,
  payload,
});

export const getFeaturedEventsFailed = (
  error: Core.IErrorResponse | AxiosError
) => ({
  type: GET_FEATURED_EVENTS_FAILED,
  payload: {
    error,
  },
});

export const getCategoryEventsSuccess = (payload: IEventListState) => ({
  type: GET_FEATURED_EVENTS_SUCCESS,
  payload,
});

export const getCategoryEventsFailed = (
  error: Core.IErrorResponse | AxiosError
) => ({
  type: GET_FEATURED_EVENTS_FAILED,
  payload: {
    error,
  },
});

export const clearAllEvents = () => ({
  type: CLEAR_EVENTS,
});

export const clearFeaturedEvents = () => ({
  type: CLEAR_FEATURED_EVENTS,
});

export const clearCategoryEvents = () => ({
  type: CLEAR_CATEGORY_EVENTS,
});

export const clearEventsState = () => ({
  type: CLEAR_EVENTS_STATE,
});
