import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Heading from '@jpp/atoms/Heading';
import useGoogleReCaptcha, {
  EReCaptchaSize,
  IReCaptchaFormValues,
  RECAPTCHA_FIELDS,
} from '@jpp/hooks/useGoogleReCaptcha';
import Alert from '@jpp/molecules/Alert';
import { EAlertType } from '@jpp/molecules/Alert/Alert';
import Button from '@jpp/molecules/Button/Button';
import InputField from '@jpp/molecules/Form/InputField/InputField';
import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import { EAuthPage } from '@jpp/templates/Auth/Auth';
import { ECtaTheme, EPageType, EPriority } from 'common/typings/enums';
import { TElement } from 'common/typings/types';

import './ForgotPasswordForm.scss';

export interface IForgotPasswordFormProps {
  className?: string;
  element?: TElement;
  withHeading?: boolean;
}

export interface IStoreForgotPasswordFormProps {
  isLoggedIn: boolean;
}

export interface IForgotPasswordFormValues extends IReCaptchaFormValues {
  email: string;
  errorMessage?: string;
  successMessage?: string;
}

export type TForgotPasswordForm = IForgotPasswordFormProps &
  IStoreForgotPasswordFormProps &
  FormikProps<IForgotPasswordFormValues>;

export type TForgotPasswordFormFields = Omit<
  IForgotPasswordFormValues,
  'errorMessage' | 'successMessage'
>;

export const FORGOT_PASSWORD_FORM_FIELDS: TForgotPasswordFormFields = {
  email: 'email',
  ...RECAPTCHA_FIELDS,
};

const ROOT_CLASSNAME = 'ForgotPasswordForm';

const ForgotPasswordForm: FunctionComponent<TForgotPasswordForm> = ({
  className,
  isSubmitting,
  element = 'div',
  errors,
  isLoggedIn,
  withHeading = true,
  isValid,
  setFieldValue,
  submitCount,
  dirty,
  status,
}) => {
  const hasStatus = !!status;
  const Element: TElement = element;
  const isFormSubmitDisabled = !isValid || isSubmitting || !!status;
  const { recaptchaRef, siteKey } = useGoogleReCaptcha(
    setFieldValue,
    FORGOT_PASSWORD_FORM_FIELDS.token,
    submitCount,
    dirty
  );

  if (isLoggedIn) {
    return null;
  }

  return (
    <>
      <Element
        className={classNames(className, ROOT_CLASSNAME, {
          [`${ROOT_CLASSNAME}--has-errors`]: !!errors?.errorMessage,
        })}
      >
        {hasStatus && (
          <Alert
            className={`${ROOT_CLASSNAME}__alert`}
            isVisible={true}
            alertType={EAlertType.Success}
          >
            {status}
          </Alert>
        )}

        {withHeading && (
          <Heading
            priority={EPriority.Five}
            className={`${ROOT_CLASSNAME}__heading`}
          >
            Forgot Password
          </Heading>
        )}

        <Form className={`${ROOT_CLASSNAME}__form`}>
          {errors?.errorMessage && (
            <div className={`${ROOT_CLASSNAME}__form-errors`}>
              <p dangerouslySetInnerHTML={{ __html: errors?.errorMessage }} />
            </div>
          )}

          <InputField
            name={FORGOT_PASSWORD_FORM_FIELDS.email}
            type="email"
            label="Email Address"
            hasBoldLabel={true}
            required={true}
          />

          <ReCAPTCHA
            className={`${ROOT_CLASSNAME}__recaptcha`}
            ref={recaptchaRef}
            size={EReCaptchaSize.Invisible}
            sitekey={siteKey}
          />

          <div className={`${ROOT_CLASSNAME}__cta-wrapper`}>
            <Button
              type="submit"
              isFull={true}
              theme={isFormSubmitDisabled ? ECtaTheme.TintPsi : ECtaTheme.Brand}
              behaviour="action"
              disabled={isFormSubmitDisabled}
              className={classNames(`${ROOT_CLASSNAME}__button`, {
                [`${ROOT_CLASSNAME}__button--is-disabled`]:
                  isFormSubmitDisabled,
              })}
            >
              Submit
            </Button>

            <Button
              type="button"
              isFull={true}
              link={`/${EPageType.Auth}/${EAuthPage.Login}`}
              theme={ECtaTheme.BrandOutline}
              className={`${ROOT_CLASSNAME}__button`}
            >
              Head back
            </Button>
          </div>
        </Form>
      </Element>
      {isSubmitting && <ContainerLoader isVisible={true} />}
    </>
  );
};

export default ForgotPasswordForm;
