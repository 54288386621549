export enum ECurrencyCode {
  GBP = 'GBP',
}

export enum ELayout {
  Core = 'core',
}

export enum ETheme {
  Brand = 'brand', // Orange
  Alpha = 'alpha', // Orange (80%)
  Beta = 'beta', // Orange (60%)
  Gamma = 'gamma', // Orange (40%)
  Delta = 'delta', // Orange (20%)
  TintAlpha = 'tint-alpha',
  TintBeta = 'tint-beta',
  TintGamma = 'tint-gamma',
  TintDelta = 'tint-delta',
  TintPsi = 'tint-psi',
  TintOmega = 'tint-omega',
}

export enum ECtaTheme {
  Brand = 'brand',
  BrandOutline = 'brand-outline',
  Alpha = 'alpha', // Orange (80%)
  Beta = 'beta', // Orange (60%)
  Gamma = 'gamma', // Orange (40%)
  Delta = 'delta', // Orange (20%)
  TintAlpha = 'tint-alpha',
  TintAlphaOutline = 'tint-alpha-outline',
  TintBeta = 'tint-beta',
  TintGamma = 'tint-gamma',
  TintDelta = 'tint-delta',
  TintOmega = 'tint-omega',
  TintPsi = 'tint-psi',
  TintPsiOutline = 'tint-psi-outline',
}

export enum ETextTheme {
  Brand = 'brand',
  TintAlpha = 'tint-alpha',
  TintOmega = 'tint-omega',
}

export enum EContrast {
  Light = 'light',
  Gray = 'gray',
  Dark = 'dark',
}

export enum EPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum EFontFamily {
  Oswald = 'oswald',
  Lato = 'lato',
}

export enum ETaxonomy {
  Category = 'category',
  PostTag = 'post_tag',
}

export enum EPageType {
  Default = '',
  Auth = 'auth',
  Index = '/',
  Home = 'home',
  Shop = 'shop',
  FamilyBeers = 'family-beers',
  Contact = 'contact',
  MyAccount = 'my-account',
  Page = 'page',
  Archive = 'archive',
  Blog = 'blog',
  Products = 'products',
  Product = 'product',
  Experience = 'experience',
  Bars = 'bars',
  Bar = 'bar',
  Faqs = 'faqs',
  Faq = 'faq',
  Events = 'events',
  Event = 'event',
  Post = 'post',
  Category = 'category',
  PostTag = 'tag',
  Payment = 'payment',
  Checkout = 'checkout',
  OrderConfirmation = 'order-confirmation',
  OrderCanceled = 'order-canceled',
  Cart = 'cart',
  PrivacyPolicy = 'privacy-policy',
  ReturnPolicy = 'return-policy',
  CookiePolicy = 'cookie-policy',
  TermsAndConditions = 'terms-and-conditions',
}

export enum EPriority {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
}

export enum ESize {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export enum EParallaxSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum EColumn {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Ten = '10',
  Eleven = '11',
  Twelve = '12',
}

export enum EDisplayMode {
  Block = 'block',
  Inline = 'inline',
}

export enum EMenuType {
  PrimaryMenu = 'primaryMenu',
  MobileMenu = 'mobileMenu',
  FooterMenuOne = 'footerMenuOne',
  FooterMenuTwo = 'footerMenuTwo',
  TermsMenu = 'termsMenu',
}

export enum EPaymentGateway {
  All = 'all',
  Stripe = 'stripe',
  PayPal = 'paypal',
}

export enum EOrderStatus {
  Completed = 'completed',
  Pending = 'pending',
  Processing = 'processing',
  OnHold = 'on-hold',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  Failed = 'failed',
  Trash = 'trash',
}

export enum EStatus {
  Any = 'any',
  Draft = 'draft',
  Pending = 'pending',
  Private = 'private',
  Publish = 'publish',
}

export enum EBillingPeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum EBillingInterval {
  One = '1',
  Two = '2',
  Three = '3',
}

export enum ESubscriptionStatus {
  Pending = 'pending',
  Active = 'active',
  OnHold = 'on-hold',
  Expired = 'expired',
  Cancelled = 'cancelled',
  PendingCancel = 'pending-cancel',
}

export {};
