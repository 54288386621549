import React from 'react';

import SellBlockHeading from '@jpp/molecules/SellBlock/components/SellBlockHeading';
import SellBlockPrice from '@jpp/molecules/SellBlock/components/SellBlockPrice';
import SellBlockQuantitySelector from '@jpp/molecules/SellBlock/components/SellBlockQuantitySelector';
import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';
import { EProductStock } from 'common/typings/enums/product';

import {
  getRootSellBlockClasses,
  SELL_BLOCK_ROOT_CLASSNAME,
} from '../../utils';
import SellBlockCtas from '../SellBlockCtas';
import MiniKegPackSizes from './components/MiniKegPackSizes';

const BundleSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy,
}) => {
  const { default_product, can_product } = pdp.acf_fields! || pdp.acf!;

  const slug = default_product.slug || pdp.slug;
  const stockAmount = pdp.stock_quantity || default_product.stock_quantity || 0;
  const availableQuantities: IPdpAvailableQuantity[] = [
    { label: 'Mini Keg (5L)', value: '1' },
  ];

  if (can_product) {
    availableQuantities.push({
      label: can_product.post_title,
      value: '1',
    });
  }

  const [selectedPackSize] = React.useState<IPdpAvailableQuantity>(
    availableQuantities[0]
  );
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(1);
  const isOnBackorder = pdp.stock_status === EProductStock.OnBackorder;

  React.useEffect(() => {
    setSelectedQuantity(1);
  }, []);

  const onQuantityChanged = (value: number) => {
    setSelectedQuantity(value);
  };

  return (
    <div
      className={getRootSellBlockClasses(
        renderedBy,
        availableQuantities,
        className,
        pdp.pdp_type
      )}
    >
      {availableQuantities && (
        <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__size-wrapper`}>
          <SellBlockHeading availableQuantities={availableQuantities} />

          <MiniKegPackSizes
            quantities={availableQuantities}
            selectedPackSize={selectedPackSize}
            canProduct={can_product}
            slug={slug}
          />
        </div>
      )}

      <SellBlockPrice
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />

      <SellBlockQuantitySelector
        onQuantityChanged={onQuantityChanged}
        maxAmount={stockAmount}
        selectedQuantity={selectedQuantity}
        isOnBackorder={isOnBackorder}
      />

      <SellBlockCtas
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />
    </div>
  );
};

export default BundleSellBlock;
