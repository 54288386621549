import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';

import { LabelHeading } from '@jpp/atoms/Heading';
import Icon from '@jpp/atoms/Icon';
import Image from '@jpp/atoms/Image';
import Button from '@jpp/molecules/Button';
import ModalCore from '@jpp/organisms/Modal';
import { IModalChildProps } from '@jpp/organisms/Modal/ModalCore';
import WYSIWYG from '@jpp/shared/WYSIWYG';
import { getOptions } from 'common/redux/core/selectors';
import { IReduxState } from 'common/redux/createStore';
import {
  ECtaTheme,
  EPageType,
  EPosition,
  EPriority,
} from 'common/typings/enums';
import { GHBC_AGE_VERIFICATION } from 'common/utils/constants/cookies';
import { daysToSeconds, getExpiryDate } from 'common/utils/shared/time';

import './AgeVerificationModal.scss';

const ROOT_CLASSNAME = 'AgeVerificationModal';

const AgeVerificationModal: React.FunctionComponent<IModalChildProps> = ({
  className,
  isVisible: _isVisible = true,
  onClose,
}) => {
  const hasCookie = Cookies.get(GHBC_AGE_VERIFICATION) === 'true';
  const router = useRouter();
  const {
    age_verification_copy,
    age_verification_heading,
    age_verification_image,
    age_verification_terms,
  } = useSelector<IReduxState, Core.IOptions>((state) => getOptions(state));
  const isTermsPage = router.asPath.includes(EPageType.TermsAndConditions);
  const shouldShow = _isVisible && !hasCookie && !isTermsPage;
  const [isVisible, setIsVisible] = React.useState<boolean>(shouldShow);

  const handleOnClick = () => {
    Cookies.set(GHBC_AGE_VERIFICATION, 'true', {
      expires: getExpiryDate(daysToSeconds(182)),
      path: '/',
    });
    setIsVisible(false);
  };

  React.useEffect(() => {
    setIsVisible(shouldShow);
  }, [router.asPath]);

  if (!isVisible) {
    return null;
  }

  return (
    <ModalCore
      isVisible={true}
      hasOverlayClose={false}
      hasOverlay={false}
      parentClassName={classNames(`${ROOT_CLASSNAME}__parent`, {
        [`${ROOT_CLASSNAME}__parent--is-active`]: isVisible,
      })}
      bodyOverflow={false}
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-active`]: isVisible,
      })}
      onClose={onClose}
      hasCloseIcon={false}
    >
      <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
        <Image
          className={`${ROOT_CLASSNAME}__image`}
          image={age_verification_image}
        />
        <Icon.Logo className={`${ROOT_CLASSNAME}__logo`} inline={true} />
      </div>
      <div className={`${ROOT_CLASSNAME}__content`}>
        <LabelHeading
          className={`${ROOT_CLASSNAME}__heading`}
          heading={age_verification_heading}
          priority={EPriority.Two}
        />

        <WYSIWYG
          textAlign={EPosition.Center}
          className={`${ROOT_CLASSNAME}__copy`}
          content={age_verification_copy}
        />

        <div className={`${ROOT_CLASSNAME}__cta-wrapper`}>
          <Button
            type="button"
            behaviour="action"
            className={`${ROOT_CLASSNAME}__cta`}
            theme={ECtaTheme.TintAlpha}
            onClick={handleOnClick}
          >
            I am over 18!
          </Button>

          <Button
            type="button"
            behaviour="router"
            className={`${ROOT_CLASSNAME}__cta ${ROOT_CLASSNAME}__cta--secondary`}
            theme={ECtaTheme.TintAlphaOutline}
            link={`/${EPageType.TermsAndConditions}`}
          >
            I am not of age
          </Button>
        </div>

        <p className={`${ROOT_CLASSNAME}__terms`}>
          {age_verification_terms}
          <br /> Visit{' '}
          <a
            className={`${ROOT_CLASSNAME}__text-link`}
            href="https://www.drinkaware.co.uk/?utm_source=gipsyhillbrewery&utm_medium=website&utm_campaign=responsible_drinking_message"
          >
            <Icon.Drinkaware className={`${ROOT_CLASSNAME}__drinkaware`} />
          </a>{' '}
          for the facts
        </p>
      </div>
    </ModalCore>
  );
};

export default AgeVerificationModal;
