import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import './Flex.scss';

export interface IFlexProps {
  style: CSSProperties;
  className: string;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  col?: Core.TColumn;
  colXs?: Core.TColumn;
  colSm?: Core.TColumn;
  colMd?: Core.TColumn;
  colLg?: Core.TColumn;
  colXl?: Core.TColumn;
  auto?: boolean;
  center?: boolean;
  tagElement?: Core.TTag;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

export const Flex: React.FunctionComponent<Partial<IFlexProps>> = ({
  children,
  auto = false,
  tagElement = 'div',
  center = false,
  className,
  xs,
  sm,
  md,
  lg,
  xl,
  col,
  colXs,
  colSm,
  colMd,
  colLg,
  colXl,
  ...props
}) => {
  const TagElement = `${tagElement}` as unknown as React.FunctionComponent<any>;

  const combinedClasses = {
    flex: !col,
    'flex--auto': auto,
    'flex-xs': xs,
    'flex-sm': sm,
    'flex-md': md,
    'flex-lg': lg,
    'flex-xl': xl,
    [`flex-${col}`]: !!col,
    [`flex-xs-${colXs}`]: !!colXs,
    [`flex-sm-${colSm}`]: !!colSm,
    [`flex-md-${colMd}`]: !!colMd,
    [`flex-lg-${colLg}`]: !!colLg,
    [`flex-xl-${colXl}`]: !!colXl,
    'mx-auto': center,
  };

  return (
    <TagElement {...props} className={classNames(className, combinedClasses)}>
      {children}
    </TagElement>
  );
};

export default Flex;
