import * as Sentry from '@sentry/node';
import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Heading from '@jpp/atoms/Heading';
import useGoogleReCaptcha, {
  EReCaptchaSize,
  IReCaptchaFormValues,
  RECAPTCHA_FIELDS,
} from '@jpp/hooks/useGoogleReCaptcha';
import useIsAuthEnabled from '@jpp/hooks/useIsAuthEnabled';
import Button from '@jpp/molecules/Button/Button';
import InputField from '@jpp/molecules/Form/InputField/InputField';
import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import Section from '@jpp/shared/Grid/Section/Section';
import { ECtaTheme, EPriority } from 'common/typings/enums';
import { TElement, TFuncValueVoid, TFuncVoid } from 'common/typings/types';
import axios from 'common/utils/axios/internal';
import { EMAIL_REGEX } from 'common/utils/shared';

import './RegisterForm.scss';

export interface IRegisterFormProps {
  className?: string;
  element?: TElement;
  shouldRedirect?: boolean;
  withHeading?: boolean;
  redirectPath?: string;
  onSuccess?: TFuncVoid;
}

export interface IStoreRegisterFormProps {
  isLoggedIn: boolean;
}

export interface IDispatchRegisterFormProps {
  onLogin: (form: { data: any; customer: any }, shouldGetCart: boolean) => void;
  onRegister: TFuncValueVoid<Omit<IRegisterFormValues, 'errorMessage'>>;
  onClearAuth: TFuncVoid;
}

export interface IRegisterFormValues extends IReCaptchaFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  errorMessage?: string;
}

export type TRegisterForm = IRegisterFormProps &
  IStoreRegisterFormProps &
  IDispatchRegisterFormProps &
  FormikProps<IRegisterFormValues>;

export type TRegisterFormFields = Omit<IRegisterFormValues, 'errorMessage'>;

export const REGISTER_FORM_FIELDS: TRegisterFormFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  ...RECAPTCHA_FIELDS,
};

const ROOT_CLASSNAME = 'RegisterForm';

const RegisterForm: FunctionComponent<TRegisterForm> = ({
  className,
  isSubmitting,
  element = 'div',
  withHeading = true,
  isLoggedIn,
  errors,
  setFieldValue,
  submitCount,
  values,
  dirty,
  isValid,
}) => {
  const isAuthEnabled = useIsAuthEnabled();
  const { recaptchaRef, siteKey, onRecaptchaChange } = useGoogleReCaptcha(
    setFieldValue,
    REGISTER_FORM_FIELDS.token,
    submitCount,
    dirty
  );
  const Element: TElement = element;
  const [hasCheckedEmail, setHasCheckedEmail] = useState<boolean>(false);
  const [canRegister, setCanRegister] = useState<boolean>(false);

  const isFormSubmitDisabled =
    !isValid || isSubmitting || (!canRegister && hasCheckedEmail);

  const checkEmail = async () => {
    if (!EMAIL_REGEX.test(values.email?.trim())) {
      return;
    }

    try {
      const { data: isRegistered } = await axios.post<boolean>(
        '/auth/check-email',
        {
          email: values.email?.toLowerCase().trim(),
        }
      );
      setCanRegister(!isRegistered);
      setHasCheckedEmail(true);
    } catch (e) {
      Sentry.captureException(e);
      setCanRegister(true);
      setHasCheckedEmail(false);
    }
  };

  useEffect(() => {
    if (isAuthEnabled) {
      checkEmail();
    }
  }, [values.email]);

  if (isLoggedIn) {
    return null;
  }

  return (
    <>
      <Element
        className={classNames(className, ROOT_CLASSNAME, {
          [`${ROOT_CLASSNAME}--has-errors`]: !!errors?.errorMessage,
        })}
      >
        {withHeading && (
          <Heading
            priority={EPriority.Five}
            className={`${ROOT_CLASSNAME}__heading`}
          >
            Create account
          </Heading>
        )}

        <Form className={`${ROOT_CLASSNAME}__form`}>
          <Section withPadding={false}>
            <Row>
              <Flex colLg={12}>
                {errors?.errorMessage && (
                  <div className={`${ROOT_CLASSNAME}__form-errors`}>
                    <p
                      dangerouslySetInnerHTML={{ __html: errors?.errorMessage }}
                    />
                  </div>
                )}
              </Flex>
            </Row>

            <Row>
              <Flex colLg={6}>
                <InputField
                  label="First Name"
                  name={REGISTER_FORM_FIELDS.firstName}
                  required={true}
                  hasBoldLabel={true}
                />
              </Flex>
              <Flex colLg={6}>
                <InputField
                  label="Last Name"
                  name={REGISTER_FORM_FIELDS.lastName}
                  required={true}
                  hasBoldLabel={true}
                />
              </Flex>
            </Row>

            <Row>
              <Flex colLg={6}>
                <InputField
                  label="Email Address"
                  type="email"
                  name={REGISTER_FORM_FIELDS.email}
                  required={true}
                  hasBoldLabel={true}
                />
              </Flex>

              <Flex colLg={6}>
                <InputField
                  label="Phone Number"
                  name={REGISTER_FORM_FIELDS.phoneNumber}
                  required={true}
                  hasBoldLabel={true}
                />
              </Flex>
            </Row>

            <Row>
              <Flex colLg={6}>
                <InputField
                  type="password"
                  label="Password"
                  name={REGISTER_FORM_FIELDS.password}
                  autoComplete="on"
                  required={true}
                  hasBoldLabel={true}
                />
              </Flex>

              <Flex colLg={6}>
                <InputField
                  type="password"
                  label="Confirm Password"
                  name={REGISTER_FORM_FIELDS.confirmPassword}
                  autoComplete="on"
                  required={true}
                  hasBoldLabel={true}
                />
              </Flex>
            </Row>

            <Row>
              <Flex colLg={12}>
                {!canRegister && hasCheckedEmail && (
                  <p className={`${ROOT_CLASSNAME}__error-email`}>
                    An account already exists with this email.
                  </p>
                )}

                <ReCAPTCHA
                  className={`${ROOT_CLASSNAME}__recaptcha`}
                  ref={recaptchaRef}
                  size={EReCaptchaSize.Normal}
                  onChange={onRecaptchaChange}
                  sitekey={siteKey}
                />
              </Flex>
            </Row>
          </Section>

          <div className={`${ROOT_CLASSNAME}__cta-wrapper`}>
            <Button
              type="submit"
              theme={isFormSubmitDisabled ? ECtaTheme.TintPsi : ECtaTheme.Brand}
              behaviour="action"
              isFull={true}
              disabled={isFormSubmitDisabled}
              className={classNames(`${ROOT_CLASSNAME}__button`, {
                [`${ROOT_CLASSNAME}__button--is-disabled`]:
                  isFormSubmitDisabled,
              })}
            >
              Register
            </Button>
          </div>
        </Form>
      </Element>

      {isSubmitting && <ContainerLoader isVisible={true} />}
    </>
  );
};

export default RegisterForm;
