import { IAuthStoreState } from 'common/redux/auth/reducer';
import { ICartLineItem } from 'common/redux/wooCommerce/cart/reducer';
import { ETrackingEvents } from 'common/tracking/types';
import { pushToDataLayer } from 'common/tracking/utils';

export const trackCustomer = ({
  stripe_data,
  error,
  token,
  meta,
  meta_data,
  ...customer
}: IAuthStoreState) => {
  return pushToDataLayer({
    event: ETrackingEvents.Customer,
    customerIdentity: {
      ...customer,
    },
  });
};

export const trackAbandonedCart = ({
  email,
  lineItems,
  customer,
}: {
  customer: IAuthStoreState;
  email: string;
  lineItems: ICartLineItem[];
}) => {
  const { stripe_data, error, token, meta, meta_data, ...auth } = customer;
  return pushToDataLayer({
    event: ETrackingEvents.AbandonedCart,
    abandonedCart: {
      customer: auth,
      email: auth.email || email,
      lineItems,
      lineItemIds: lineItems.map((item) => item.product_id),
    },
  });
};
