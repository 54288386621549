import classNames from 'classnames';
import React, { ChangeEvent, ReactText } from 'react';

import FormLabel from '@jpp/atoms/FormLabel';
import Icons from '@jpp/atoms/Icon';
import { TOption } from 'common/typings/types';

import './Select.scss';

interface ISelectProps {
  className?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  selectedOption?: string;
  options: TOption[];
  defaultValue?: ReactText;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const ROOT_CLASSNAME = 'Select';

const Select: React.FunctionComponent<ISelectProps> = ({
  className,
  name,
  label,
  options,
  disabled = false,
  required = false,
  selectedOption,
  defaultValue,
  onChange,
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      {label && <FormLabel text={label} name={name} />}

      <div className={`${ROOT_CLASSNAME}__select-wrapper`}>
        <select
          className={`${ROOT_CLASSNAME}__field`}
          name={name}
          required={required}
          disabled={disabled}
          onChange={onChange}
          defaultValue={defaultValue}
        >
          {options?.map((option) => {
            if (!option) {
              return null;
            }

            const [[value, optionLabel]] = Object.entries(option);
            const isSelected = selectedOption === optionLabel;

            return (
              <option
                key={`${value} ${optionLabel}`}
                aria-selected={isSelected}
                value={value}
              >
                {optionLabel}
              </option>
            );
          })}
        </select>

        <Icons.Arrow className={`${ROOT_CLASSNAME}__icon`} />
      </div>
    </div>
  );
};

export default Select;
