import React, { useState } from 'react';

import SellBlockCtas from '@jpp/molecules/SellBlock/components/SellBlockCtas';
import SellBlockPrice from '@jpp/molecules/SellBlock/components/SellBlockPrice';
import SellBlockQuantitySelector from '@jpp/molecules/SellBlock/components/SellBlockQuantitySelector';
import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';
import { EProductStock } from 'common/typings/enums/product';

import { getRootSellBlockClasses } from '../../utils';

const MerchandiseSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy,
}) => {
  const { acf, acf_fields } = pdp;
  const { default_product } = acf_fields || acf || {};
  const maxAmount = pdp.stock_quantity || default_product?.stock_quantity || 0;
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const isOnBackorder = pdp.stock_status === EProductStock.OnBackorder;
  const selectedPackSize = { label: 'Merch', value: '1' };
  const onQuantityChanged = (value: number) => {
    setSelectedQuantity(value);
  };

  return (
    <div
      className={getRootSellBlockClasses(
        renderedBy,
        undefined,
        className,
        pdp.pdp_type
      )}
    >
      <SellBlockPrice
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />

      <SellBlockQuantitySelector
        onQuantityChanged={onQuantityChanged}
        maxAmount={maxAmount}
        selectedQuantity={selectedQuantity}
        isOnBackorder={isOnBackorder}
      />

      <SellBlockCtas
        pdp={pdp}
        renderedBy={renderedBy}
        selectedQuantity={selectedQuantity}
        selectedPackSize={selectedPackSize}
      />
    </div>
  );
};

export default MerchandiseSellBlock;
