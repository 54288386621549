import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Heading from '@jpp/atoms/Heading';
import Loading from '@jpp/atoms/Loading';
import useGoogleReCaptcha, {
  EReCaptchaSize,
  IReCaptchaFormValues,
  RECAPTCHA_FIELDS,
} from '@jpp/hooks/useGoogleReCaptcha';
import Alert, { EAlertType } from '@jpp/molecules/Alert/Alert';
import Button from '@jpp/molecules/Button/Button';
import InputField from '@jpp/molecules/Form/InputField/InputField';
import { EAuthPage } from '@jpp/templates/Auth/Auth';
import { ECtaTheme, EPageType, EPriority } from 'common/typings/enums';
import { TElement } from 'common/typings/types';

import './ResetPasswordForm.scss';

export interface IResetPasswordFormProps {
  className?: string;
  element?: TElement;
  resetCode?: string;
  withHeading?: boolean;
}

export interface IStoreResetPasswordFormProps {
  isLoggedIn: boolean;
}

export interface IResetPasswordFormValues extends IReCaptchaFormValues {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
  errorMessage?: string;
}

export type TResetPasswordForm = IResetPasswordFormProps &
  IStoreResetPasswordFormProps &
  FormikProps<IResetPasswordFormValues>;

export type TResetPasswordFormFields = Omit<
  IResetPasswordFormValues,
  'errorMessage'
>;

export const RESET_PASSWORD_FORM_FIELDS: TResetPasswordFormFields = {
  email: 'email',
  code: 'code',
  password: 'password',
  confirmPassword: 'confirmPassword',
  ...RECAPTCHA_FIELDS,
};

export const RESET_CODE_MAX_LENGTH = 8;

const ROOT_CLASSNAME = 'ResetPasswordForm';

const ResetPasswordForm: FunctionComponent<TResetPasswordForm> = ({
  className,
  isSubmitting,
  resetCode,
  element = 'div',
  errors,
  withHeading = true,
  submitCount,
  isLoggedIn,
  isValid,
  dirty,
  setFieldValue,
  status,
}) => {
  const hasStatus = !!status;
  const Element: TElement = element;
  const isFormSubmitDisabled = !isValid || isSubmitting || !!status;
  const [hasValidCode] = React.useState(
    !!resetCode && resetCode.length === RESET_CODE_MAX_LENGTH
  );
  const { recaptchaRef, siteKey, onRecaptchaChange } = useGoogleReCaptcha(
    setFieldValue,
    RESET_PASSWORD_FORM_FIELDS.token,
    submitCount,
    dirty
  );

  React.useEffect(() => {
    if (hasValidCode && resetCode) {
      setFieldValue(RESET_PASSWORD_FORM_FIELDS.code, resetCode);
    }
  }, []);

  if (isLoggedIn) {
    return null;
  }

  return (
    <>
      <Element
        className={classNames(className, ROOT_CLASSNAME, {
          [`${ROOT_CLASSNAME}--has-errors`]: !!errors?.errorMessage,
        })}
      >
        {hasStatus && (
          <Alert
            className={`${ROOT_CLASSNAME}__alert`}
            isVisible={true}
            alertType={EAlertType.Success}
          >
            {status}
          </Alert>
        )}

        {errors?.errorMessage && (
          <Alert
            className={`${ROOT_CLASSNAME}__alert`}
            isVisible={true}
            alertType={EAlertType.Error}
          >
            <p dangerouslySetInnerHTML={{ __html: errors.errorMessage }} />
          </Alert>
        )}

        {withHeading && (
          <Heading
            priority={EPriority.Five}
            className={`${ROOT_CLASSNAME}__heading`}
          >
            Reset Password
          </Heading>
        )}

        <Form className={`${ROOT_CLASSNAME}__form`}>
          <InputField
            name={RESET_PASSWORD_FORM_FIELDS.email}
            type="email"
            label="Email Address"
            hasBoldLabel={true}
            required={true}
          />

          <InputField
            name={RESET_PASSWORD_FORM_FIELDS.code}
            label="Code"
            required={true}
            hasBoldLabel={true}
            maxLength={RESET_CODE_MAX_LENGTH}
            readOnly={hasValidCode}
          />

          <InputField
            type="password"
            label="Password"
            hasBoldLabel={true}
            name={RESET_PASSWORD_FORM_FIELDS.password}
            autoComplete="on"
            required={true}
          />

          <InputField
            type="password"
            label="Confirm Password"
            hasBoldLabel={true}
            name={RESET_PASSWORD_FORM_FIELDS.confirmPassword}
            autoComplete="on"
            required={true}
          />

          <ReCAPTCHA
            className={`${ROOT_CLASSNAME}__recaptcha`}
            ref={recaptchaRef}
            size={EReCaptchaSize.Normal}
            sitekey={siteKey}
            onChange={onRecaptchaChange}
          />

          <div className={`${ROOT_CLASSNAME}__cta-wrapper`}>
            <Button
              type="submit"
              isFull={true}
              theme={isFormSubmitDisabled ? ECtaTheme.TintPsi : ECtaTheme.Brand}
              behaviour="action"
              disabled={isFormSubmitDisabled}
              className={classNames(`${ROOT_CLASSNAME}__button`, {
                [`${ROOT_CLASSNAME}__button--is-disabled`]:
                  isFormSubmitDisabled,
              })}
            >
              {isSubmitting ? (
                <Loading className={`${ROOT_CLASSNAME}__loading`} />
              ) : (
                'Submit'
              )}
            </Button>

            <Button
              type="button"
              isFull={true}
              link={`/${EPageType.Auth}/${EAuthPage.Login}`}
              theme={ECtaTheme.BrandOutline}
              className={`${ROOT_CLASSNAME}__button`}
            >
              Head back
            </Button>
          </div>
        </Form>
      </Element>
    </>
  );
};

export default ResetPasswordForm;
