import { useRouter } from 'next/router';
import React from 'react';

import useIsLoggedIn from '@jpp/hooks/useIsLoggedIn';
import Section from '@jpp/shared/Grid/Section';
import { EPageType, ETheme } from 'common/typings/enums';
import { isClient } from 'common/utils/shared';

import './Auth.scss';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';

interface IAuthProps {
  className?: string;
  page: {
    authPage: EAuthPage;
  };
}

export interface IAuthChildProps {
  className?: string;
}

export enum EAuthPage {
  ResetPassword = 'reset-password',
  ForgotPassword = 'forgot-password',
  Login = 'login',
  Logout = 'logout',
  Register = 'register',
}

export const AUTH_ROOT_CLASSNAME = 'Auth';

const Auth: React.FunctionComponent<IAuthProps> = ({ page }) => {
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const componentMap: Record<
    EAuthPage,
    React.ComponentType<IAuthChildProps>
  > = {
    [EAuthPage.ResetPassword]: ResetPassword,
    [EAuthPage.ForgotPassword]: ForgotPassword,
    [EAuthPage.Login]: Login,
    [EAuthPage.Logout]: Logout,
    [EAuthPage.Register]: Register,
  };
  const authPage = page.authPage;
  const Component = componentMap[authPage];

  if ((isLoggedIn && authPage !== EAuthPage.Logout) || !Component) {
    if (isClient()) {
      router.replace(`/${EPageType.Shop}`);
    }
    return null;
  }

  return (
    <Section
      withPadding={false}
      theme={ETheme.Delta}
      className={AUTH_ROOT_CLASSNAME}
    >
      <Component className={`${AUTH_ROOT_CLASSNAME}__page`} {...page} />
    </Section>
  );
};

export default Auth;
