import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { ESize } from 'common/typings/enums';

import './FormLabel.scss';

export interface IFormLabelProps {
  className?: string;
  size?: ESize;
  text: string;
  name: string;
  helpText?: string;
  isBold?: boolean;
}

const ROOT_CLASSNAME = 'FormLabel';

export const FormLabel: FunctionComponent<IFormLabelProps> = ({
  text,
  name,
  size = ESize.Md,
  isBold = false,
  helpText,
  children,
  className,
}) => {
  return (
    <>
      <label
        id={name}
        htmlFor={name}
        aria-label={text}
        className={classNames(
          ROOT_CLASSNAME,
          `${ROOT_CLASSNAME}--${size}`,
          className,
          {
            [`${ROOT_CLASSNAME}--with-children`]: !!children,
            [`${ROOT_CLASSNAME}--is-bold`]: isBold,
          }
        )}
      >
        {text}
      </label>
      {helpText && <p className={`${ROOT_CLASSNAME}__copy`}>{helpText}</p>}
      {children}
    </>
  );
};

export default FormLabel;
