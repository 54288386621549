import React from 'react';

import { IPdpAvailableQuantity, IPdpProduct } from 'common/redux/pdp/reducer';
import { EProductStock } from 'common/typings/enums/product';

import { ESellBlockRenderedBy, ISellBlockProps } from '../../types';
import {
  getRootSellBlockClasses,
  SELL_BLOCK_ROOT_CLASSNAME,
} from '../../utils';
import PackSizes from '../PackSizes';
import SellBlockCtas from '../SellBlockCtas';
import SellBlockHeading from '../SellBlockHeading';
import SellBlockPrice from '../SellBlockPrice';
import SellBlockQuantitySelector from '../SellBlockQuantitySelector';

export const StandardSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy = ESellBlockRenderedBy.ProductWidget,
}) => {
  const {
    default_product,
    default_quantity,
    available_quantities,
    mini_keg_product,
    keg_product,
    beer_info,
    subscribe_and_save_product_12,
    subscribe_and_save_product_24,
  } = pdp.acf_fields || pdp.acf!;

  const subscribeAndSaveProducts = [
    subscribe_and_save_product_12,
    subscribe_and_save_product_24,
  ].filter(Boolean) as IPdpProduct[];
  const availableQuantities = available_quantities || [default_quantity];
  const stockAmount =
    pdp.stock_quantity || default_product?.stock_quantity || 0;

  const hasMiniKeg = beer_info.sold_as.includes('mini-keg');
  const hasKeg = beer_info.sold_as.includes('keg');

  const [selectedPackSize, setSelectedPackSize] =
    React.useState<IPdpAvailableQuantity>({
      label: default_quantity?.label,
      value: default_quantity?.value,
    });
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(1);

  React.useEffect(() => {
    setSelectedQuantity(1);
  }, []);

  const onQuantityChanged = (value: number) => {
    setSelectedQuantity(value);
  };

  const onPackSizeSelected = (qty: IPdpAvailableQuantity) => {
    setSelectedPackSize(qty);
  };

  const packSizeValue = parseInt(selectedPackSize.value, 10);
  const maxAmount =
    packSizeValue > 1
      ? parseInt(`${stockAmount / packSizeValue}`, 10)
      : stockAmount;

  return (
    <div
      className={getRootSellBlockClasses(
        renderedBy,
        availableQuantities,
        className,
        pdp.pdp_type
      )}
    >
      {(availableQuantities || Boolean(subscribeAndSaveProducts.length)) && (
        <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__size-wrapper`}>
          {availableQuantities && (
            <SellBlockHeading availableQuantities={availableQuantities} />
          )}

          <PackSizes
            pdp={pdp}
            renderedBy={renderedBy}
            subscribeAndSaveProducts={subscribeAndSaveProducts}
            kegProduct={hasKeg ? keg_product : undefined}
            miniKegProduct={hasMiniKeg ? mini_keg_product : undefined}
            quantities={availableQuantities}
            selectedPackSize={selectedPackSize}
            onPackSizeSelected={onPackSizeSelected}
          />
        </div>
      )}

      <SellBlockPrice
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />

      <SellBlockQuantitySelector
        onQuantityChanged={onQuantityChanged}
        maxAmount={maxAmount}
        selectedQuantity={selectedQuantity}
        isOnBackorder={pdp.stock_status === EProductStock.OnBackorder}
      />

      <SellBlockCtas
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />
    </div>
  );
};

export default StandardSellBlock;
