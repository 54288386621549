import classNames from 'classnames';
import React, { ReactText, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@jpp/atoms/Loading';
import {
  EProductCtaText,
  getProductCtaText,
} from '@jpp/atoms/ProductCta/constants';
import { Button } from '@jpp/molecules/Button/Button';
import { BackInStockModal } from '@jpp/organisms/Modal';
import { IReduxState } from 'common/redux/createStore';
import { IPdpAcfFields, IPdpStoreState } from 'common/redux/pdp/reducer';
import {
  addToCart,
  setIsAddingToCart,
} from 'common/redux/wooCommerce/cart/actions';
import {
  getIsAddedToCart,
  getIsAddingToCart,
  getIsAddToCartFailed,
} from 'common/redux/wooCommerce/cart/selectors';
import { trackGoogleAddToBag } from 'common/tracking/cart';
import { trackProductAddToBag } from 'common/tracking/product';
import { ECtaTheme } from 'common/typings/enums';
import { EProductStock, EProductType } from 'common/typings/enums/product';

import './ProductCta.scss';

export interface IProductCtaProps {
  className?: string;
  product: IPdpStoreState;
  productId: ReactText;
  quantity?: number;
  packSize?: number;
  theme?: ECtaTheme;
  isFull?: boolean;
  isDisabled?: boolean;
  productType?: EProductType;
  customData?: Record<string, ReactText | undefined>;
  showBackInStockButton?: boolean;
}

const ROOT_CLASSNAME = 'ProductCta';

const ProductCta: React.FunctionComponent<IProductCtaProps> = ({
  className,
  productId,
  quantity = 1,
  packSize: propPackSize = 1,
  theme: _theme = ECtaTheme.Brand,
  isFull = true,
  product,
  isDisabled,
  productType = EProductType.Simple,
  customData,
  showBackInStockButton = false,
}) => {
  const [isBackInStockVisible, setIsBackInStockVisible] =
    useState<boolean>(false);
  const {
    stock_quantity,
    stock_status,
    post_title,
    sku,
    sale_price,
    regular_price,
  } = product;

  const val = propPackSize;
  const packSize = val < 1 ? 1 : val;
  const isOnBackorder = stock_status === EProductStock.OnBackorder;
  const isDefaultGreaterThanMaxAmount =
    Boolean(packSize) && Boolean(stock_quantity)
      ? packSize * quantity > stock_quantity
      : false;
  const acf = product.acf_fields || product.acf || ({} as IPdpAcfFields);
  const isInStock = isOnBackorder
    ? true
    : stock_status === EProductStock.InStock;
  const isOOStock = stock_status === EProductStock.OutOfStock;
  const title = post_title || product.title;
  const price = sale_price || regular_price || product.price;
  const insufficientStock = isOnBackorder
    ? false
    : isDefaultGreaterThanMaxAmount;
  const dispatch = useDispatch();
  const isAddingToBag = useSelector((state: IReduxState) =>
    getIsAddingToCart(state)
  );
  const isAddedToBag = useSelector((state: IReduxState) =>
    getIsAddedToCart(state)
  );
  const addToCartFailed = useSelector((state: IReduxState) =>
    getIsAddToCartFailed(state)
  );
  const theme = isInStock ? _theme : ECtaTheme.TintPsi;
  const disabled = isOnBackorder
    ? false
    : !isInStock || insufficientStock || isDisabled;

  const onAddToBag = () => {
    const totalQty = packSize * quantity;
    const data: Core.IAddToCart = {
      productId,
      quantity: totalQty,
      cart_item_data: {
        ...customData,
      },
    };
    dispatch(setIsAddingToCart(true));
    dispatch(addToCart(data));
    trackProductAddToBag(
      title,
      productId,
      sku,
      price,
      totalQty,
      acf?.default_product_range?.name,
      product.slug,
      (product?.image || product?.featured_image)?.url
    );
    trackGoogleAddToBag(
      title,
      productId,
      sku,
      price,
      totalQty,
      acf?.default_product_range?.name
    );
  };

  const handleBackInStockOnClick = () => {
    setIsBackInStockVisible(true);
  };

  const handleBackInStockOnClose = () => {
    setIsBackInStockVisible(false);
  };

  const handleOnClick = async (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (isAddingToBag || !isInStock) {
      return;
    }

    await onAddToBag();
  };

  const getCtaLabel = (): string => {
    if (isAddedToBag) {
      return EProductCtaText.AddedToCart;
    }

    if (addToCartFailed) {
      return EProductCtaText.AddToCartFailed;
    }

    if (isOOStock) {
      return EProductCtaText.SoldOut;
    }

    if (insufficientStock) {
      return EProductCtaText.InsufficientStock;
    }

    if (!isInStock) {
      return EProductCtaText.SoldOut;
    }

    return getProductCtaText[productType];
  };

  return (
    <>
      {isBackInStockVisible && showBackInStockButton && (
        <BackInStockModal
          className="ProductCta__modal--back-in-stock"
          onClose={handleBackInStockOnClose}
          bodyOverflow={true}
          pdp={product}
        />
      )}

      <div className={classNames(ROOT_CLASSNAME, className)}>
        {showBackInStockButton && !isInStock && !isOnBackorder && (
          <Button
            className={classNames(
              'ProductCta__button',
              'ProductCta__button--back-in-stock'
            )}
            behaviour="action"
            theme={ECtaTheme.Brand}
            onClick={handleBackInStockOnClick}
            isFull={isFull}
          >
            Notify when back in stock
          </Button>
        )}

        <Button
          className={classNames('ProductCta__button', {
            ['ProductCta__button--is-disabled']: disabled,
          })}
          behaviour="action"
          disabled={disabled}
          onClick={handleOnClick}
          theme={disabled ? ECtaTheme.TintPsi : theme}
          isFull={isFull}
        >
          {isAddingToBag ? (
            <Loading className={`${ROOT_CLASSNAME}__loading`} />
          ) : (
            <span className="ProductCta__text">{getCtaLabel()}</span>
          )}
        </Button>
      </div>
    </>
  );
};

export default ProductCta;
