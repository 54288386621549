import { IPdpStoreState } from 'common/redux/pdp/reducer';
import {
  EFaceBookEvents,
  EGaEvents,
  EProductRenderedBy,
} from 'common/tracking/types';
import { mapProductsToAnalytics, pushToDataLayer } from 'common/tracking/utils';
import { ECurrencyCode } from 'common/typings/enums';
import { APP_TITLE } from 'common/utils/constants';
import { getPriceWithoutCurrency } from 'common/utils/shared/product';

export const trackProductAddToBag = (
  name: string,
  id: number | string,
  sku: string,
  price: string,
  quantity: number,
  category?: string,
  slug?: string,
  imageUrl?: string
) => {
  const _price = parseFloat(getPriceWithoutCurrency(price));
  return pushToDataLayer({
    event: EFaceBookEvents.AddToCart,
    addToCart: {
      currencyCode: ECurrencyCode.GBP,
      name,
      id,
      sku,
      price: _price.toFixed(2),
      totalPrice: (_price * quantity).toFixed(2),
      brand: APP_TITLE,
      quantity,
      category,
      slug,
      imageUrl,
    },
  });
};

export const trackUpdateProductInCart = (
  name: string,
  id: number,
  sku: string,
  price: string,
  quantity: number,
  slug: string,
  imageUrl?: string
) => {
  const _price = parseFloat(getPriceWithoutCurrency(price));
  return pushToDataLayer({
    event: EFaceBookEvents.UpdateProductInCart,
    updateProductInCart: {
      currencyCode: ECurrencyCode.GBP,
      name,
      id,
      sku,
      price: _price.toFixed(2),
      totalPrice: (_price * quantity).toFixed(2),
      brand: APP_TITLE,
      quantity,
      slug,
      imageUrl,
    },
  });
};

export const trackRemoveProductInCart = (
  name: string,
  id: number,
  sku: string,
  price: string,
  slug?: string,
  imageUrl?: string
) => {
  const _price = parseFloat(getPriceWithoutCurrency(price));
  return pushToDataLayer({
    event: EFaceBookEvents.RemoveProductInCart,
    removeProductInCart: {
      currencyCode: ECurrencyCode.GBP,
      name,
      id,
      sku,
      price: _price.toFixed(2),
      brand: APP_TITLE,
      slug,
      imageUrl,
    },
  });
};

export const trackProductImpression = (
  name: string,
  id: number,
  sku: string,
  price: string,
  category: string,
  slug?: string,
  imageUrl?: string
) =>
  pushToDataLayer({
    event: EFaceBookEvents.ProductPageImpression,
    productImpression: {
      currencyCode: ECurrencyCode.GBP,
      name,
      id,
      sku,
      price: parseFloat(getPriceWithoutCurrency(price)).toFixed(2),
      category,
      slug,
      imageUrl,
    },
  });

export const trackGoogleProductImpression = (
  products: IPdpStoreState[],
  renderedBy = EProductRenderedBy.FeaturedProducts
) => {
  return pushToDataLayer({
    event: EGaEvents.ProductImpressions,
    component: renderedBy,
    ecommerce: {
      currencyCode: ECurrencyCode.GBP,
      actionField: {
        list: renderedBy,
      },
      impressions: mapProductsToAnalytics(products),
    },
  });
};

export const trackGooglePDP = (
  products: IPdpStoreState[],
  renderedBy = EProductRenderedBy.Pdp
) => {
  return pushToDataLayer({
    event: EGaEvents.ProductDetails,
    component: renderedBy,
    ecommerce: {
      currencyCode: ECurrencyCode.GBP,
      detail: {
        actionField: {
          list: 'Search Results',
        },
        products: mapProductsToAnalytics(products),
      },
    },
  });
};

export const trackGoogleProductClick = (
  product: IPdpStoreState,
  position = 0,
  renderedBy = EProductRenderedBy.FeaturedProducts
) => {
  const { title, post_title, post_name, sku, id } =
    product || ({} as IPdpStoreState);
  const acf = product.acf || product.acf_fields;
  return pushToDataLayer({
    event: EGaEvents.ProductClick,
    component: renderedBy,
    ecommerce: {
      currencyCode: ECurrencyCode.GBP,
      click: {
        actionField: {
          list: renderedBy,
        },
        products: [
          {
            id: sku,
            name: title || post_title || post_name,
            brand: APP_TITLE,
            category: acf?.default_product_range?.name,
            variantID: id,
            position,
          },
        ],
      },
    },
  });
};
