import classNames from 'classnames';
import React from 'react';

import Button from '@jpp/molecules/Button';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';
import { ECtaTheme, EPageType, ESize } from 'common/typings/enums';

import { getPackSizeClasses } from '../../../../SellBlock';

interface IPackSizesProps {
  className?: string;
  quantities: IPdpAvailableQuantity[];
  selectedPackSize: IPdpAvailableQuantity;
  slug: string;
}

const ROOT_CLASSNAME = 'PackSizes';

const BundlePackSizes: React.FunctionComponent<IPackSizesProps> = ({
  className,
  quantities,
  selectedPackSize,
  slug,
}) => {
  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        getPackSizeClasses(quantities)
      )}
    >
      <ul className={`${ROOT_CLASSNAME}__list`}>
        {quantities.map((qty, index) => {
          const { label, value } = qty;
          const isSelected = selectedPackSize.label === label;

          return (
            <li key={label} className={`${ROOT_CLASSNAME}__item`}>
              <Button
                className={classNames(`${ROOT_CLASSNAME}__button`, {
                  [`${ROOT_CLASSNAME}__button--is-selected`]: isSelected,
                })}
                size={ESize.Sm}
                theme={ECtaTheme.TintPsiOutline}
                behaviour="router"
                link={`/${EPageType.Product}/${slug.replace(/[0-9]+/g, value)}`}
                isFull={true}
              >
                {label}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BundlePackSizes;
