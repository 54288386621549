import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import Cookies from 'js-cookie';
import React, { FunctionComponent, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import AnchorLink from '@jpp/atoms/AnchorLink';
import Heading from '@jpp/atoms/Heading';
import useGoogleReCaptcha, {
  EReCaptchaSize,
  IReCaptchaFormValues,
  RECAPTCHA_FIELDS,
} from '@jpp/hooks/useGoogleReCaptcha';
import Button from '@jpp/molecules/Button/Button';
import InputField from '@jpp/molecules/Form/InputField/InputField';
import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import { EAuthPage } from '@jpp/templates/Auth/Auth';
import { ECtaTheme, EPageType, EPriority } from 'common/typings/enums';
import { TElement, TFuncVoid } from 'common/typings/types';
import { GHBC_AUTH_TOKEN } from 'common/utils/constants/cookies';

import './LoginForm.scss';

export interface ILoginFormProps {
  className?: string;
  element?: TElement;
  withHeading?: boolean;
  shouldRedirect?: boolean;
  redirectPath?: string;
  onSuccess?: TFuncVoid;
  shouldGetCart?: boolean;
  hasSimpleLink?: boolean;
}

export interface IStoreLoginFormProps {
  isLoggedIn: boolean;
}

export interface IDispatchLoginFormProps {
  onLogin: (form: { data: any; customer: any }, shouldGetCart: boolean) => void;
  onGetUser: TFuncVoid;
}

export interface ILoginFormValues extends IReCaptchaFormValues {
  email: string;
  password: string;
  errorMessage?: string;
}

export type TLoginFormFields = Omit<ILoginFormValues, 'errorMessage'>;

export type TLoginForm = ILoginFormProps &
  IStoreLoginFormProps &
  IDispatchLoginFormProps &
  FormikProps<ILoginFormValues>;

export const LOGIN_FORM_FIELDS: Record<keyof TLoginFormFields, string> = {
  email: 'email',
  password: 'password',
  ...RECAPTCHA_FIELDS,
};

const ROOT_CLASSNAME = 'LoginForm';

const LoginForm: FunctionComponent<TLoginForm> = ({
  className,
  isSubmitting,
  element = 'div',
  errors,
  hasSimpleLink = false,
  isLoggedIn,
  withHeading = true,
  submitCount,
  dirty,
  setFieldValue,
  isValid,
}) => {
  const Element: TElement = element;
  const isFormSubmitDisabled = !isValid || isSubmitting;

  const { recaptchaRef, siteKey, onRecaptchaChange } = useGoogleReCaptcha(
    setFieldValue,
    LOGIN_FORM_FIELDS.token,
    submitCount,
    dirty
  );

  const hasErrors = !!errors?.errorMessage;

  useEffect(() => {
    if (hasErrors) {
      Cookies.remove(GHBC_AUTH_TOKEN);
    }
  }, [hasErrors]);

  if (isLoggedIn) {
    return null;
  }

  return (
    <>
      <Element
        className={classNames(className, ROOT_CLASSNAME, {
          [`${ROOT_CLASSNAME}--has-errors`]: hasErrors,
        })}
      >
        {withHeading && (
          <Heading
            priority={EPriority.Five}
            className={`${ROOT_CLASSNAME}__heading`}
          >
            Login
          </Heading>
        )}

        <Form className={`${ROOT_CLASSNAME}__form`}>
          {hasErrors && (
            <div className={`${ROOT_CLASSNAME}__form-errors`}>
              <p
                dangerouslySetInnerHTML={{ __html: errors.errorMessage || '' }}
              />
            </div>
          )}

          <InputField
            name={LOGIN_FORM_FIELDS.email}
            type="email"
            label="Email Address"
            hasBoldLabel={true}
            required={true}
          />

          <InputField
            name={LOGIN_FORM_FIELDS.password}
            type="password"
            label="Password"
            autoComplete="on"
            hasBoldLabel={true}
            required={true}
          />

          <ReCAPTCHA
            className={`${ROOT_CLASSNAME}__recaptcha`}
            ref={recaptchaRef}
            size={EReCaptchaSize.Normal}
            onChange={onRecaptchaChange}
            sitekey={siteKey}
          />

          <div className={`${ROOT_CLASSNAME}__cta-wrapper`}>
            <Button
              type="submit"
              theme={isFormSubmitDisabled ? ECtaTheme.TintPsi : ECtaTheme.Brand}
              behaviour="action"
              isFull={true}
              disabled={isFormSubmitDisabled}
              className={classNames(`${ROOT_CLASSNAME}__button`, {
                [`${ROOT_CLASSNAME}__button--is-disabled`]:
                  isFormSubmitDisabled,
              })}
            >
              Login
            </Button>

            {hasSimpleLink ? (
              <AnchorLink
                className={`${ROOT_CLASSNAME}__link`}
                href={`/${EPageType.Auth}/${EAuthPage.ForgotPassword}`}
              >
                Forgot Password?
              </AnchorLink>
            ) : (
              <Button
                type="button"
                isFull={true}
                link={`/${EPageType.Auth}/${EAuthPage.ForgotPassword}`}
                theme={ECtaTheme.BrandOutline}
                className={`${ROOT_CLASSNAME}__button ${ROOT_CLASSNAME}__button--link`}
              >
                Forgot Password?
              </Button>
            )}
          </div>
        </Form>
      </Element>

      {isSubmitting && <ContainerLoader isVisible={true} />}
    </>
  );
};

export default LoginForm;
