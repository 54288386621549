import classNames from 'classnames';
import React from 'react';

import Loading from '@jpp/atoms/Loading';
import useIsAppLoading from '@jpp/hooks/useIsAppLoading';

import './ContainerLoader.scss';

interface IPageLoaderProps {
  className?: string;
  useAppLoading?: boolean;
  isVisible?: boolean;
}

const ROOT_CLASSNAME = 'ContainerLoader';

const ContainerLoader: React.FunctionComponent<IPageLoaderProps> = ({
  className,
  isVisible = false,
  useAppLoading = false,
}) => {
  const isAppLoading = useIsAppLoading();
  const element = (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <Loading className={`${ROOT_CLASSNAME}__loading`} />
    </div>
  );

  if (useAppLoading) {
    return !isAppLoading ? null : element;
  }
  return !isVisible ? null : element;
};

export default ContainerLoader;
