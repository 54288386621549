import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import RefImage from '@jpp/atoms/Image';
import SellBlock from '@jpp/molecules/SellBlock';
import { ESellBlockRenderedBy } from '@jpp/molecules/SellBlock/types';
import { IPdpStoreState } from 'common/redux/pdp/reducer';

import ModalCore, { IModalChildProps } from '../ModalCore';
import './ProductModal.scss';

interface IProductModalProps {
  pdp: IPdpStoreState;
  renderedBy?: ESellBlockRenderedBy;
}

const ROOT_CLASSNAME = 'ProductModal';

type TProductModal = IModalChildProps & IProductModalProps;

export const ProductModal: React.FunctionComponent<TProductModal> = ({
  className,
  onClose,
  bodyOverflow = false,
  pdp,
  renderedBy = ESellBlockRenderedBy.ProductModal,
}) => {
  const acf = pdp.acf_fields || pdp.acf;
  const image = pdp.featured_image || pdp.image || acf?.image_gallery[0].image;
  const { abv, style } = acf?.beer_info || {};
  const subHeading = abv && style ? `${style} ${abv}% ABV` : undefined;

  return (
    <ModalCore
      isVisible={true}
      bodyOverflow={bodyOverflow}
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${pdp.pdp_type}`
      )}
      hasOverlayClose={true}
      onClose={onClose}
    >
      <div className={`${ROOT_CLASSNAME}__heading-wrapper`}>
        <Heading
          priority={2}
          className={`${ROOT_CLASSNAME}__heading`}
          children={pdp.post_title || pdp.title}
        />

        {subHeading && (
          <Heading
            priority={2}
            className={`${ROOT_CLASSNAME}__subheading`}
            children={subHeading}
          />
        )}
      </div>

      {image && (
        <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
          <RefImage
            className={`${ROOT_CLASSNAME}__image`}
            image={image}
            shouldLazyLoad={false}
          />
        </div>
      )}

      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        <SellBlock
          className={`${ROOT_CLASSNAME}__sell-block ${ROOT_CLASSNAME}__sell-block--${pdp.pdp_type}`}
          pdp={pdp}
          renderedBy={renderedBy}
        />
      </div>
    </ModalCore>
  );
};
