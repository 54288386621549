import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import { EIconType } from '@jpp/atoms/Icon/utils/types';
import { ECtaTheme, ESize, ETheme } from 'common/typings/enums';
import { IA11yTypes } from 'common/typings/types';

import './Button.scss';

interface IButtonProps extends IA11yTypes {
  theme?: Core.TTheme | ECtaTheme;
  size?: Core.TSize;
  behaviour?: Core.TLinkBehaviour;
  caps?: boolean;
  isFull?: boolean;
  className?: string;
  onClick?: Core.TOnClick;
  type?: Core.TLinkType;
  disabled?: boolean;
  link?: string;
  queryParams?: Record<string, any>;
  outline?: boolean;
  removeSaSQuery?: boolean;
  icon?: EIconType;
}

const ROOT_CLASSNAME = 'Button';

export const Button: FunctionComponent<IButtonProps> = ({
  behaviour = 'router',
  theme = ETheme.Brand,
  size = ESize.Md,
  caps = false,
  children,
  link,
  isFull = false,
  className,
  type = 'button',
  disabled = false,
  onClick,
  queryParams,
  icon,
  removeSaSQuery,
  ...props
}) => {
  const defaultProps: Partial<IButtonProps> = {
    className: classNames(
      ROOT_CLASSNAME,
      `${ROOT_CLASSNAME}--${size}`,
      {
        [`${ROOT_CLASSNAME}--${theme}`]: !!theme,
        [`${ROOT_CLASSNAME}--capitalise`]: caps,
        [`${ROOT_CLASSNAME}--is-disabled`]: disabled,
        [`${ROOT_CLASSNAME}--full`]: isFull,
      },
      className
    ),
    onClick,
    ...props,
  };

  const getContent = (
    <span className={`${ROOT_CLASSNAME}__content`}>
      {children && (
        <span
          className={classNames(`${ROOT_CLASSNAME}__text`, {
            [`${ROOT_CLASSNAME}__text--with-icon`]: !!icon,
          })}
        >
          {children}
        </span>
      )}
    </span>
  );

  switch (behaviour) {
    case 'anchor':
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer nofollow"
          {...defaultProps}
        >
          {getContent}
        </a>
      );

    case 'action':
      return (
        <button
          type={type}
          disabled={disabled}
          aria-disabled={disabled}
          {...defaultProps}
        >
          {getContent}
        </button>
      );

    case 'router':
    default:
      if (!link) {
        return null;
      }

      return (
        <AnchorLink
          {...defaultProps}
          href={link}
          queryParams={queryParams}
          removeSaSQuery={removeSaSQuery}
        >
          {getContent}
        </AnchorLink>
      );
  }
};

export default Button;
