import classNames from 'classnames';
import React from 'react';

import {
  IPdpAvailableQuantity,
  IPdpBundleItem,
} from 'common/redux/pdp/reducer';

import './BundleInfo.scss';

interface IBundleInfoProps {
  className?: string;
  bundle_items?: IPdpBundleItem[];
  bundle_has_equal_members?: boolean;
  default_quantity: IPdpAvailableQuantity;
}

const ROOT_CLASSNAME = 'BundleInfo';

const BundleInfo: React.FunctionComponent<IBundleInfoProps> = ({
  className,
  bundle_items,
  bundle_has_equal_members,
  default_quantity,
}) => {
  if (!bundle_items || bundle_items.length === 0) {
    return null;
  }

  const hasEqualMembers = !!bundle_has_equal_members;
  const bundleItemQty =
    hasEqualMembers &&
    default_quantity &&
    parseInt(default_quantity.value, 10) / bundle_items?.length!;

  return (
    <ul className={classNames(ROOT_CLASSNAME, className)}>
      {bundle_items.map(({ product, quantity }, index) => {
        const itemQty = bundleItemQty || quantity;

        if (!itemQty) {
          return null;
        }

        const productTitle = product.title || product.post_title;
        const productAcf = product.acf_fields || product.acf;
        const itemStyle = productAcf?.beer_info?.style;
        const label =
          productAcf?.available_quantities.find(
            (qty) => parseInt(qty.value, 10) <= 1
          ) || undefined;

        return (
          <li
            key={`${product.sku} ${index}`}
            className={`${ROOT_CLASSNAME}__item`}
          >
            <span className={`${ROOT_CLASSNAME}__qty`}>{itemQty}x</span>
            {productTitle && (
              <span className={`${ROOT_CLASSNAME}__text`}>
                {productTitle} {itemStyle ? `(${itemStyle})` : null}{' '}
                {label?.label ? ` | ${label?.label}` : null}
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default BundleInfo;
