import React, { Dispatch, SetStateAction, useCallback } from 'react';

const useToggle = (
  initialValue = false
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [toggle, setToggle] = React.useState<boolean>(initialValue);
  const setIsToggle = useCallback(
    () => setToggle((prevState) => !prevState),
    []
  );
  return [toggle, setIsToggle, setToggle];
};

export default useToggle;
