import classNames from 'classnames';
import React from 'react';

import { getTotalProductPrice } from 'common/utils/shared/product';

import './ProductPrice.scss';

interface IProductPriceProps {
  className?: string;
  regularPrice: string;
  price: string;
  packSize?: number;
  quantity: number;
  shouldMultiplyPrice?: boolean;
}

const ROOT_CLASSNAME = 'ProductPrice';

const ProductPrice: React.FunctionComponent<IProductPriceProps> = ({
  className,
  packSize,
  quantity,
  shouldMultiplyPrice,
  ...props
}) => {
  const price = parseFloat(props.price);
  const regularPrice = parseFloat(props.regularPrice);
  const isOnSale = regularPrice > price;
  const SemanticPriceElement = isOnSale ? 'ins' : 'span';
  const finalRegularPrice = getTotalProductPrice(
    props.regularPrice,
    packSize,
    quantity,
    shouldMultiplyPrice
  );

  const finalPrice = getTotalProductPrice(
    props.price,
    packSize,
    quantity,
    shouldMultiplyPrice
  );

  return (
    <span className={classNames(ROOT_CLASSNAME, className)}>
      {isOnSale && (
        <>
          <del className={`${ROOT_CLASSNAME}__rrp`}>({finalRegularPrice})</del>
          <span className={`${ROOT_CLASSNAME}__nbsp`}>&nbsp;</span>
        </>
      )}

      <SemanticPriceElement
        className={classNames(`${ROOT_CLASSNAME}__price`, {
          [`${ROOT_CLASSNAME}__price--sale`]: isOnSale,
        })}
      >
        {finalPrice}
      </SemanticPriceElement>
    </span>
  );
};

export default ProductPrice;
