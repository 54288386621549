import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IReduxState } from 'common/redux/createStore';
import { getIsAddingToCart } from 'common/redux/wooCommerce/cart/selectors';
import { EPageType, ESize } from 'common/typings/enums';
import { TFuncValueVoid } from 'common/typings/types';

import './QuantitySelector.scss';

interface IQuantitySelectorProps {
  className?: string;
  onChange: TFuncValueVoid<number>;
  currentAmount?: number;
  size?: ESize;
  disabled?: boolean;
  maxAmount: number;
  isOnBackorder?: boolean;
  renderedBy?: EPageType;
}

const ROOT_CLASSNAME = 'QuantitySelector';

export const QuantitySelector: React.FunctionComponent<
  IQuantitySelectorProps
> = ({
  className,
  onChange,
  currentAmount: amount,
  maxAmount,
  size = ESize.Md,
  disabled,
  renderedBy,
  isOnBackorder = false,
}) => {
  const isCartPage = renderedBy === EPageType.Cart;
  const isAddingToCart = useSelector((state: IReduxState) =>
    getIsAddingToCart(state)
  );
  const [currentAmount, setCurrentAmount] = useState(amount || 1);
  const [plusDisabled, setPlusDisabled] = useState(false);
  const [minusDisabled, setMinusDisabled] = useState(false);

  const amountDecrease = async () => {
    const decreasedAmount = currentAmount - 1;
    if (isCartPage) {
      setPlusDisabled(true);
    }
    handleOnChange(decreasedAmount < 1 ? 1 : decreasedAmount);
    setPlusDisabled(false);
  };

  const amountIncrease = () => {
    const increasedAmount = currentAmount + 1;
    if (isCartPage) {
      setMinusDisabled(true);
    }
    if (maxAmount && increasedAmount > maxAmount) {
      return;
    }
    handleOnChange(increasedAmount);
    setMinusDisabled(false);
  };

  const handleOnChange = (val: number) => {
    setCurrentAmount(val);
    onChange(val);
  };

  const isMinusDisabled = isOnBackorder
    ? currentAmount <= 1 || false
    : disabled || minusDisabled || currentAmount <= 1;
  const isPlusDisabled = isOnBackorder
    ? false
    : disabled || plusDisabled || currentAmount >= maxAmount;

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${size}`
      )}
    >
      <button
        className={`${ROOT_CLASSNAME}__button`}
        onClick={amountDecrease}
        disabled={isAddingToCart || isMinusDisabled}
      >
        <span className={`${ROOT_CLASSNAME}__symbol`}>&mdash;</span>
      </button>

      <div className={`${ROOT_CLASSNAME}__amount`}>{currentAmount}</div>

      <button
        className={`${ROOT_CLASSNAME}__button`}
        onClick={amountIncrease}
        disabled={isAddingToCart || isPlusDisabled}
      >
        <span className={`${ROOT_CLASSNAME}__symbol`}>+</span>
      </button>
    </div>
  );
};
