import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { ETheme } from 'common/typings/enums';

import './Loading.scss';

interface ILoadingProps {
  className?: string;
  theme?: ETheme;
}

const ROOT_CLASSNAME = 'Loading';

const Loading: FunctionComponent<ILoadingProps> = ({
  className,
  theme = ETheme.TintAlpha,
}) => {
  const dots = Array.from({ length: 6 });

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${theme}`
      )}
    >
      <div className={`${ROOT_CLASSNAME}__spinner`}>
        {dots.map((_, index) => (
          <div key={index} className={`${ROOT_CLASSNAME}__dot`} />
        ))}
      </div>
    </div>
  );
};

export default Loading;
