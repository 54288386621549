import { EProductType } from 'common/typings/enums/product';

export enum EProductCtaText {
  AddToCart = 'Add to cart',
  AddedToCart = 'Added to cart',
  AddToCartFailed = 'Add to cart failed',
  OutOfStock = 'Out of stock',
  InsufficientStock = 'Not enough stock',
  SoldOut = 'Sold out',
  QuickBuy = 'Quick Buy',
  ChooseQuantity = 'Choose quantity',
  Blank = '',
  SubscribeAndSave = 'Subscribe and Save',
}

export const getProductCtaText: Record<EProductType, EProductCtaText> = {
  [EProductType.Simple]: EProductCtaText.AddToCart,
  [EProductType.Grouped]: EProductCtaText.AddToCart,
  [EProductType.MixAndMatch]: EProductCtaText.AddToCart,
  [EProductType.External]: EProductCtaText.AddToCart,
  [EProductType.Variable]: EProductCtaText.ChooseQuantity,
  [EProductType.SubscribeAndSave]: EProductCtaText.AddToCart,
  [EProductType.Subscription]: EProductCtaText.AddToCart,
  [EProductType.Special]: EProductCtaText.AddToCart,
  [EProductType.SpecialBottle]: EProductCtaText.AddToCart,
  [EProductType.Merchandise]: EProductCtaText.AddToCart,
  [EProductType.MiniKeg]: EProductCtaText.AddToCart,
  [EProductType.Virtual]: EProductCtaText.AddToCart,
  [EProductType.Bundle]: EProductCtaText.AddToCart,
  [EProductType.BundleItem]: EProductCtaText.Blank,
  [EProductType.GiftCard]: EProductCtaText.AddedToCart,
};
