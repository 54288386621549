import { IAuthStoreState } from 'common/redux/auth/reducer';
import { IReduxState } from 'common/redux/createStore';

export const getAuth = (state: IReduxState): IAuthStoreState => {
  return state.auth;
};

export const getAuthId = (state: IReduxState): number | undefined => {
  const auth = getAuth(state);
  return auth.id;
};

export const getAuthIsLoggedIn = (state: IReduxState): boolean => {
  const auth = getAuth(state);
  return auth.isLoggedIn;
};
