import classNames from 'classnames';

import { ESellBlockRenderedBy } from '@jpp/molecules/SellBlock/types';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';

export const SELL_BLOCK_ROOT_CLASSNAME = 'SellBlock';

export const getRootSellBlockClasses = (
  renderedBy: ESellBlockRenderedBy,
  availableQuantities?: IPdpAvailableQuantity[],
  className?: string,
  pdpType?: string
) =>
  classNames(
    SELL_BLOCK_ROOT_CLASSNAME,
    className,
    `${SELL_BLOCK_ROOT_CLASSNAME}--${renderedBy}`,
    {
      [`${SELL_BLOCK_ROOT_CLASSNAME}--pack-sizes-count-${availableQuantities?.length}`]:
        !!availableQuantities,
      [`${SELL_BLOCK_ROOT_CLASSNAME}--${pdpType}`]: !!pdpType,
    }
  );
