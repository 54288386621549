import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IWooCommerceParams } from 'common/typings/types/woocommerce';
import axios from 'common/utils/axios/internal';

import { serialiseQueryObject } from '../../../../server/utils';
import { setAppError, setAppLoading } from '../../core/actions';
import { IReduxDispatch, IReduxState } from '../../createStore';
import {
  CLEAR_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_FAILED,
  GET_PRODUCT_CATEGORY_SUCCESS,
} from '../constants';

export const getProductCategory: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string, query: Partial<IWooCommerceParams> = {}) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_PRODUCT_CATEGORY });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const serialiseQuery = serialiseQueryObject({ ...query, slug });
    const url = `/product/categories${
      serialiseQuery.length > 0 ? `?${serialiseQuery}` : ''
    }`;

    return apiCall(url, dispatch);
  };
};

export const getProductCategoryById: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (id: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_PRODUCT_CATEGORY });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    return apiCall(`/product/categories/${id}`, dispatch);
  };
};

const apiCall = (url: string, dispatch: Dispatch): Promise<AnyAction> => {
  return axios
    .get(url)
    .then((response: AxiosResponse) => {
      dispatch(setAppLoading(false));
      return dispatch(getProductCategorySuccess(response.data));
    })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(getProductCategoryFailed(error));
    });
};

export const getProductCategorySuccess = (payload: Core.IProductCategory) => ({
  type: GET_PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const getProductCategoryFailed = (
  payload: Core.IErrorResponse | AxiosError
) => ({
  type: GET_PRODUCT_CATEGORY_FAILED,
  payload,
});

export const clearProductCategory = () => ({
  type: CLEAR_PRODUCT_CATEGORY,
});
