import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { EPaymentGateway } from 'common/typings/enums';
import { IWooCommerceParams } from 'common/typings/types/woocommerce';
import axios from 'common/utils/axios/internal';

import { serialiseQueryObject } from '../../../../server/utils';
import { setAppError, setAppLoading } from '../../core/actions';
import { IReduxDispatch, IReduxState } from '../../createStore';
import {
  CLEAR_PAYMENT_GATEWAYS,
  GET_PAYMENT_GATEWAYS,
  GET_PAYMENT_GATEWAYS_FAILED,
  GET_PAYMENT_GATEWAYS_SUCCESS,
} from '../constants';

export const getPaymentGateways: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (
  paymentGateway: EPaymentGateway = EPaymentGateway.All,
  query: Partial<IWooCommerceParams> = {}
) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_PAYMENT_GATEWAYS(paymentGateway) });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const serialiseQuery = serialiseQueryObject(query);
    const url = `/payment-gateways${
      serialiseQuery.length > 0 ? `?${serialiseQuery}` : ''
    }`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(
          getPaymentGatewaySuccess(paymentGateway, response.data)
        );
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getPaymentGatewayFailed(paymentGateway, error));
      });
  };
};

export const getPaymentGatewaySuccess = (
  paymentGateway: EPaymentGateway,
  payload: Core.IPaymentGateway[]
) => ({
  type: GET_PAYMENT_GATEWAYS_SUCCESS(paymentGateway),
  paymentGateway,
  payload,
});

export const getPaymentGatewayFailed = (
  paymentGateway: EPaymentGateway,
  payload: Core.IErrorResponse | AxiosError
) => ({
  type: GET_PAYMENT_GATEWAYS_FAILED(paymentGateway),
  paymentGateway,
  payload,
});

export const clearPaymentGateway = (paymentGateway: EPaymentGateway) => ({
  type: CLEAR_PAYMENT_GATEWAYS(paymentGateway),
  paymentGateway,
});
