import React from 'react';

import SellBlockHeading from '@jpp/molecules/SellBlock/components/SellBlockHeading';
import SellBlockPrice from '@jpp/molecules/SellBlock/components/SellBlockPrice';
import SellBlockQuantitySelector from '@jpp/molecules/SellBlock/components/SellBlockQuantitySelector';
import {
  ESellBlockRenderedBy,
  ISellBlockProps,
} from '@jpp/molecules/SellBlock/types';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';
import { EProductStock } from 'common/typings/enums/product';

import {
  getRootSellBlockClasses,
  SELL_BLOCK_ROOT_CLASSNAME,
} from '../../utils';
import SellBlockCtas from '../SellBlockCtas';
import BundleInfo from './components/BundleInfo';
import BundlePackSizes from './components/BundlePackSizes';

const BundleSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy,
}) => {
  const {
    default_product,
    default_quantity,
    available_quantities,
    bundle_has_equal_members,
    bundle_items,
  } = pdp.acf_fields! || pdp.acf!;

  const availableQuantities = available_quantities || [default_quantity];
  const slug = default_product.slug || pdp.slug;
  const stockAmount = pdp.stock_quantity || default_product.stock_quantity || 0;
  const [selectedPackSize] = React.useState<IPdpAvailableQuantity>({
    label: default_quantity.label,
    value: '1',
  });
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(1);

  React.useEffect(() => {
    setSelectedQuantity(1);
  }, []);

  const onQuantityChanged = (value: number) => {
    setSelectedQuantity(value);
  };

  const packSizeValue = parseInt(selectedPackSize.value, 10);
  const maxAmount =
    packSizeValue > 1
      ? parseInt(`${stockAmount / packSizeValue}`, 10)
      : stockAmount;

  return (
    <div
      className={getRootSellBlockClasses(
        renderedBy,
        availableQuantities,
        className,
        pdp.pdp_type
      )}
    >
      {renderedBy === ESellBlockRenderedBy.PDPHero && (
        <BundleInfo
          bundle_items={bundle_items}
          bundle_has_equal_members={bundle_has_equal_members}
          default_quantity={default_quantity}
        />
      )}

      {availableQuantities && (
        <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__size-wrapper`}>
          <SellBlockHeading availableQuantities={availableQuantities} />

          <BundlePackSizes
            quantities={availableQuantities}
            selectedPackSize={selectedPackSize}
            slug={slug}
          />
        </div>
      )}

      <SellBlockPrice
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />

      <SellBlockQuantitySelector
        onQuantityChanged={onQuantityChanged}
        maxAmount={maxAmount}
        selectedQuantity={selectedQuantity}
        isOnBackorder={pdp.stock_status === EProductStock.OnBackorder}
      />

      <SellBlockCtas
        pdp={pdp}
        renderedBy={renderedBy}
        selectedPackSize={selectedPackSize}
        selectedQuantity={selectedQuantity}
      />
    </div>
  );
};

export default BundleSellBlock;
