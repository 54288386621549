import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import { ETheme } from 'common/typings/enums';

import './Section.scss';

interface ISection {
  className?: string;
  tagElement?: Core.TTag;
  withPadding?: boolean;
  contrast?: boolean;
  theme?: Core.TTheme;
  style?: CSSProperties;
}

export const Section: React.FunctionComponent<ISection> = ({
  children,
  className,
  tagElement = 'article',
  contrast = false,
  withPadding = true,
  theme = ETheme.TintAlpha,
  ...props
}) => {
  const TagElement = `${tagElement}` as unknown as React.FunctionComponent<any>;

  return (
    <TagElement
      {...props}
      className={classNames(className, 'Section', `theme--${theme}`, {
        'Section--with-padding': withPadding,
      })}
    >
      {children}
    </TagElement>
  );
};

export default Section;
