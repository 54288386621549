import { FormikBag, withFormik } from 'formik';
import Router from 'next/router';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  RECAPTCHA_DEFAULT_VALUE,
  RECAPTCHA_YUP,
} from '@jpp/hooks/useGoogleReCaptcha';
import { REGISTER_FORM_FIELDS } from '@jpp/molecules/Auth/RegisterForm/RegisterForm';
import { EAuthPage } from '@jpp/templates/Auth/Auth';
import { getAuthIsLoggedIn } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { EPageType } from 'common/typings/enums';
import axios from 'common/utils/axios/internal';
import { asyncDelay } from 'common/utils/shared';
import {
  invalidMessageMap,
  requiredMessageMap,
} from 'common/utils/shared/validation';

import ResetPasswordForm, {
  IResetPasswordFormProps,
  IResetPasswordFormValues,
  IStoreResetPasswordFormProps,
  RESET_CODE_MAX_LENGTH,
  TResetPasswordFormFields,
} from './ResetPasswordForm';

const mapPropsToValues = (): IResetPasswordFormValues => ({
  email: '',
  code: '',
  password: '',
  confirmPassword: '',
  errorMessage: '',
  ...RECAPTCHA_DEFAULT_VALUE,
});

const validationSchema: Yup.ObjectSchema<
  Yup.Shape<TResetPasswordFormFields | undefined, TResetPasswordFormFields>
> = Yup.object().shape({
  email: Yup.string()
    .email(invalidMessageMap.email)
    .required(requiredMessageMap.email),
  code: Yup.string()
    .max(RESET_CODE_MAX_LENGTH)
    .required(requiredMessageMap.code),
  password: Yup.string().min(6).max(32).required(requiredMessageMap.password),
  confirmPassword: Yup.string()
    .when(REGISTER_FORM_FIELDS.password, {
      is: (val) => val && val.length > 0,
      then: Yup.string()
        .oneOf(
          [Yup.ref(REGISTER_FORM_FIELDS.password)],
          invalidMessageMap.confirmPassword
        )
        .required(),
    })
    .required(),
  ...RECAPTCHA_YUP,
});

const handleSubmit = async (
  { email, token, code, confirmPassword, password }: IResetPasswordFormValues,
  {
    setFieldError,
    setStatus,
  }: FormikBag<IResetPasswordFormProps, IResetPasswordFormValues>
) => {
  await asyncDelay(2e3);
  try {
    const { data } = await axios.post<string>('/auth/validate-code', {
      email,
      code,
      token,
    });
    setStatus(data);
    await asyncDelay(3e3);
    const { data: message } = await axios.post<string>('/auth/reset-password', {
      email,
      code,
      password,
      confirmPassword,
      token,
    });
    setStatus(`${message}. We are redirecting you to the login page`);
    await asyncDelay(2e3);
    Router.push(`/${EPageType.Auth}/${EAuthPage.Login}`);
  } catch (error) {
    setFieldError(
      'errorMessage',
      'Something went wrong. The code may have expired.'
    );
  }
};

const mapStateToProps = (state: IReduxState): IStoreResetPasswordFormProps => ({
  isLoggedIn: getAuthIsLoggedIn(state),
});

export default connect<
  IStoreResetPasswordFormProps,
  never,
  IResetPasswordFormProps
>(mapStateToProps)(
  withFormik<IResetPasswordFormProps, IResetPasswordFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
  })(ResetPasswordForm)
);
