import { EIconType } from './types';

const IconUrls: Record<EIconType, any> = {
  [EIconType.Arrow]: require('../../../../assets/svg/arrow.svg'),
  [EIconType.ArrowLong]: require('../../../../assets/svg/arrow-long.svg'),
  [EIconType.Bag]: require('../../../../assets/svg/bag.svg'),
  [EIconType.Beer]: require('../../../../assets/svg/beer.svg'),
  [EIconType.Burnt]: require('../../../../assets/svg/burnt.svg'),
  [EIconType.Brewery]: require('../../../../assets/svg/brewery.svg'),
  [EIconType.Broccoli]: require('../../../../assets/svg/broccoli.svg'),
  [EIconType.Box]: require('../../../../assets/svg/box.svg'),
  [EIconType.BoxOpen]: require('../../../../assets/svg/box-open.svg'),
  [EIconType.Drinkaware]: require('../../../../assets/svg/drinkaware.svg'),
  [EIconType.Eye]: require('../../../../assets/svg/eye-regular.svg'),
  [EIconType.EyeSlash]: require('../../../../assets/svg/eye-slash-regular.svg'),
  [EIconType.Medicinal]: require('../../../../assets/svg/medicinal.svg'),
  [EIconType.Fruity]: require('../../../../assets/svg/fruity.svg'),
  [EIconType.Floral]: require('../../../../assets/svg/floral.svg'),
  [EIconType.Citrus]: require('../../../../assets/svg/citrus.svg'),
  [EIconType.Close]: require('../../../../assets/svg/close.svg'),
  [EIconType.Employee]: require('../../../../assets/svg/employee.svg'),
  [EIconType.Sour]: require('../../../../assets/svg/sour.svg'),
  [EIconType.Fresh]: require('../../../../assets/svg/fresh.svg'),
  [EIconType.Grain]: require('../../../../assets/svg/grain.svg'),
  [EIconType.Grassy]: require('../../../../assets/svg/grassy.svg'),
  [EIconType.Group]: require('../../../../assets/svg/group.svg'),
  [EIconType.Hoppy]: require('../../../../assets/svg/hoppy.svg'),
  [EIconType.Logo]: require('../../../../assets/svg/logo.svg'),
  [EIconType.LogoSocial]: require('../../../../assets/svg/logo-social.svg'),
  [EIconType.LogoSymbol]: require('../../../../assets/svg/logo-symbol.svg'),
  [EIconType.LogoText]: require('../../../../assets/svg/logo-text.svg'),
  [EIconType.Malty]: require('../../../../assets/svg/malty.svg'),
  [EIconType.Dairy]: require('../../../../assets/svg/dairy.svg'),
  [EIconType.Strong]: require('../../../../assets/svg/strong.svg'),
  [EIconType.Nutty]: require('../../../../assets/svg/nutty.svg'),
  [EIconType.Refreshing]: require('../../../../assets/svg/refreshing.svg'),
  [EIconType.Salty]: require('../../../../assets/svg/salty.svg'),
  [EIconType.Peppery]: require('../../../../assets/svg/peppery.svg'),
  [EIconType.Smokey]: require('../../../../assets/svg/smokey.svg'),
  [EIconType.Spicy]: require('../../../../assets/svg/spicy.svg'),
  [EIconType.Earthy]: require('../../../../assets/svg/earthy.svg'),
  [EIconType.Vegetal]: require('../../../../assets/svg/vegetal.svg'),
  [EIconType.Woody]: require('../../../../assets/svg/woody.svg'),
  [EIconType.Yeasty]: require('../../../../assets/svg/yeasty.svg'),
  [EIconType.Facebook]: require('../../../../assets/svg/facebook-square.svg'),
  [EIconType.Instagram]: require('../../../../assets/svg/instagram.svg'),
  [EIconType.Twitter]: require('../../../../assets/svg/twitter.svg'),
  [EIconType.Untappd]: require('../../../../assets/svg/untappd.svg'),
  [EIconType.GooglePay]: require('../../../../assets/svg/google-pay.svg'),
};

export default IconUrls;
