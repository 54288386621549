import React from 'react';

export type TIconProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  className?: string;
  inline?: boolean;
  alt?: string;
  type?: 'icon';
};

export enum EIconType {
  Logo = 'Logo',
  LogoSocial = 'LogoSocial',
  LogoSymbol = 'LogoSymbol',
  LogoText = 'LogoText',
  Arrow = 'Arrow',
  ArrowLong = 'ArrowLong',
  Beer = 'Beer',
  Burnt = 'Burnt',
  Brewery = 'Brewery',
  Broccoli = 'Broccoli',
  Bag = 'Bag',
  Box = 'Box',
  BoxOpen = 'BoxOpen',
  Drinkaware = 'Drinkaware',
  Eye = 'Eye',
  EyeSlash = 'EyeSlash',
  Medicinal = 'Medicinal',
  Fruity = 'Fruity',
  Floral = 'Floral',
  Close = 'Close',
  Employee = 'Employee',
  Sour = 'Sour',
  Fresh = 'Fresh',
  GooglePay = 'GooglePay',
  Grain = 'Grain',
  Grassy = 'Grassy',
  Group = 'Group',
  Hoppy = 'Hoppy',
  Malty = 'Malty',
  Dairy = 'Dairy',
  Strong = 'Strong',
  Citrus = 'Citrus',
  Nutty = 'Nutty',
  Refreshing = 'Refreshing',
  Salty = 'Salty',
  Peppery = 'Peppery',
  Smokey = 'Smokey',
  Spicy = 'Spicy',
  Earthy = 'Earthy',
  Vegetal = 'Vegetal',
  Woody = 'Woody',
  Yeasty = 'Yeasty',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  Untappd = 'Untappd',
}
