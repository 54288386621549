import classNames from 'classnames';
import React from 'react';

import ResetPasswordForm from '@jpp/molecules/Auth/ResetPasswordForm';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';

import { AUTH_ROOT_CLASSNAME, IAuthChildProps } from '../../Auth';
import './ResetPassword.scss';

interface IResetPasswordProps extends IAuthChildProps {
  code: string;
}

const ROOT_CLASSNAME = 'ResetPassword';

const ResetPassword: React.FunctionComponent<IResetPasswordProps> = ({
  code,
  className,
}) => (
  <div className={classNames(ROOT_CLASSNAME, className)}>
    <Container className={`${AUTH_ROOT_CLASSNAME}__container`} fluid={true}>
      <Row>
        <Flex colLg={8} center={true}>
          <ResetPasswordForm
            className={`${ROOT_CLASSNAME}__form`}
            resetCode={code}
          />
        </Flex>
      </Row>
    </Container>
  </div>
);

export default ResetPassword;
