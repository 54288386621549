import React from 'react';

import QuantitySelector from '@jpp/atoms/QuantitySelector';
import { EPageType } from 'common/typings/enums';

import { SELL_BLOCK_ROOT_CLASSNAME } from '../../utils';

interface ISellBlockQuantitySelector {
  onQuantityChanged: (value: number) => void;
  maxAmount: number;
  selectedQuantity: number;
  isOnBackorder?: boolean;
}

const SellBlockQuantitySelector: React.FunctionComponent<
  ISellBlockQuantitySelector
> = ({
  onQuantityChanged,
  maxAmount,
  selectedQuantity,
  isOnBackorder = false,
}) => {
  return (
    <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__quantity-selector-wrapper`}>
      <QuantitySelector
        currentAmount={selectedQuantity}
        onChange={onQuantityChanged}
        renderedBy={EPageType.Product}
        maxAmount={maxAmount}
        isOnBackorder={isOnBackorder}
      />
    </div>
  );
};

export default SellBlockQuantitySelector;
