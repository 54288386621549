import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import axios from 'common/utils/axios/internal';

import { setAppError, setAppLoading } from '../core/actions';
import { IReduxDispatch, IReduxState } from '../createStore';
import {
  CLEAR_POST,
  GET_POST,
  GET_POST_FAILED,
  GET_POST_SUCCESS,
} from './constants';
import { IPostStoreState } from './reducer';

export const getPost: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_POST });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    return axios
      .get(`/post/${slug}`)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));

        // We check for the error as wordpress doesn't return a 404.
        if (response.data.length === 0) {
          const error = {
            message: 'Page not found',
            hasError: true,
            code: 404 as Core.TErrorCode,
          };
          dispatch(setAppError(true));
          return dispatch(getPostFailed(error));
        }

        return dispatch(getPostSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getPostFailed(error));
      });
  };
};

export const getPostSuccess = (payload: IPostStoreState) => ({
  type: GET_POST_SUCCESS,
  payload,
});

export const getPostFailed = (error: Core.IErrorResponse | AxiosError) => ({
  type: GET_POST_FAILED,
  payload: {
    error,
  },
});

export const clearPost = () => ({
  type: CLEAR_POST,
});
