import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IWooCommerceParams } from 'common/typings/types/woocommerce';
import axios from 'common/utils/axios/internal';

import { serialiseQueryObject } from '../../../../server/utils';
import { setAppError, setAppLoading } from '../../core/actions';
import { IReduxDispatch, IReduxState } from '../../createStore';
import {
  CLEAR_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_FAILED,
  GET_PRODUCT_CATEGORIES_SUCCESS,
} from '../constants';

export const getProductCategories: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (query: Partial<IWooCommerceParams> = {}) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_PRODUCT_CATEGORIES });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const serialiseQuery = serialiseQueryObject(query);
    const url = `/products/categories${
      serialiseQuery.length > 0 ? `?${serialiseQuery}` : ''
    }`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(getProductCategoriesSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getProductCategoriesFailed(error));
      });
  };
};

export const getProductCategoriesSuccess = (
  payload: Core.IProductCategory[]
) => ({
  type: GET_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

export const getProductCategoriesFailed = (
  payload: Core.IErrorResponse | AxiosError
) => ({
  type: GET_PRODUCT_CATEGORIES_FAILED,
  payload,
});

export const clearProductCategories = () => ({
  type: CLEAR_PRODUCT_CATEGORIES,
});
