import * as Sentry from '@sentry/node';
import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import Heading from '@jpp/atoms/Heading';
import Select from '@jpp/atoms/Select';
import { EReCaptchaSize } from '@jpp/hooks/useGoogleReCaptcha';
import Alert from '@jpp/molecules/Alert';
import { EAlertType } from '@jpp/molecules/Alert/Alert';
import Button from '@jpp/molecules/Button';
import ContainerLoader from '@jpp/organisms/Loader/ContainerLoader';
import ModalCore from '@jpp/organisms/Modal';
import { getAuthId } from 'common/redux/auth/selectors';
import {
  ECtaTheme,
  EPriority,
  ESize,
  ESubscriptionStatus,
} from 'common/typings/enums';
import { TFuncVoid, TOption } from 'common/typings/types';
import axios from 'common/utils/axios/internal';
import { TCustomerSubscription } from 'common/utils/transformers/subscription.transformer';

import ConfigProvider from '../../../../services/configProvider';
import './EditSubscriptionModal.scss';

const ROOT_CLASSNAME = 'EditSubscriptionModal';

interface IEditSubscriptionModalProps {
  className?: string;
  isVisible?: boolean;
  bodyOverflow?: boolean;
  subscription?: TCustomerSubscription;
  onClose: TFuncVoid;
}

const getStatusNiceName = (status: ESubscriptionStatus) => {
  switch (status) {
    case ESubscriptionStatus.Active:
      return 'Activate subscription';
    case ESubscriptionStatus.OnHold:
      return 'Freeze subscription';
    case ESubscriptionStatus.Cancelled:
      return 'Cancel subscription';
    default:
      return status.replace(/-/g, ' ');
  }
};

const EditSubscriptionModal: React.FunctionComponent<
  IEditSubscriptionModalProps
> = ({ className, bodyOverflow, isVisible = false, onClose, subscription }) => {
  const customerId = useSelector(getAuthId);
  const isActive = subscription?.status === ESubscriptionStatus.Active;
  const isOnHold = subscription?.status === ESubscriptionStatus.OnHold;
  const shouldShow = isActive || isOnHold;
  const statusOptions: TOption[] = [
    ESubscriptionStatus.OnHold,
    ESubscriptionStatus.Active,
    ESubscriptionStatus.Cancelled,
  ]
    .filter((status) => status !== subscription?.status)
    .map((status) => ({
      [status]: getStatusNiceName(status),
    }));
  const initialStatus = Object.keys(
    statusOptions[0] as object
  )[0] as ESubscriptionStatus;
  const [selectedStatus, setSelectedStatus] = useState<
    ESubscriptionStatus | undefined
  >(initialStatus);
  const [reToken, setReToken] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const siteKey = ConfigProvider.getValue('RECAPTCHA_SITE_KEY_V2');
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  if (!subscription || !shouldShow) {
    return null;
  }

  const isDisabled = subscription.status === selectedStatus;

  const handleOnIntervalChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value as ESubscriptionStatus);
    setReToken(undefined);
    setError(undefined);
  };

  const handleChangeStatus = async () => {
    try {
      setIsLoading(true);
      setError(undefined);
      await axios.put(`/subscription/${subscription.id}`, {
        customerId,
        status: selectedStatus,
        token: reToken,
      });
      onClose();
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
      setError('An error occurred. Please try again.');
    }
  };

  const onRecaptchaChange = (val: string) => setReToken(val);

  return (
    <ModalCore
      isVisible={isVisible}
      bodyOverflow={bodyOverflow}
      className={classNames(ROOT_CLASSNAME, className)}
      onClose={onClose}
    >
      {isLoading && <ContainerLoader isVisible={true} />}

      <Heading
        priority={EPriority.Three}
        className={`${ROOT_CLASSNAME}__heading`}
        children={`Edit Subscription #${subscription.id}`}
      />

      {error && (
        <Alert
          isVisible={!!error}
          alertType={EAlertType.Error}
          className={`${ROOT_CLASSNAME}__alert`}
        >
          {error}
        </Alert>
      )}

      <p className={`${ROOT_CLASSNAME}__copy`}>
        You can edit the status of your subscription here.
      </p>

      {isOnHold && (
        <p className={`${ROOT_CLASSNAME}__copy`}>
          Your subscription is <strong>ON HOLD</strong> you can re-active it or
          cancel it here.
        </p>
      )}

      {isActive && (
        <p className={`${ROOT_CLASSNAME}__copy`}>
          If you would like to pause your subscription simply select{' '}
          <strong>ON HOLD</strong> and you will be able to renew it at any time.
        </p>
      )}

      <Select
        className={`${ROOT_CLASSNAME}__select`}
        name="sub-status"
        options={statusOptions}
        onChange={handleOnIntervalChange}
      />

      {selectedStatus === ESubscriptionStatus.Cancelled && (
        <p
          className={`${ROOT_CLASSNAME}__copy ${ROOT_CLASSNAME}__copy--warning mb-1`}
        >
          * If you cancel your subscription you will need to start a new
          subscription.
        </p>
      )}

      {!isDisabled && (
        <>
          <ReCAPTCHA
            className={`${ROOT_CLASSNAME}__recaptcha`}
            ref={recaptchaRef}
            size={EReCaptchaSize.Normal}
            onChange={onRecaptchaChange}
            sitekey={siteKey}
          />
          <Button
            behaviour="action"
            onClick={handleChangeStatus}
            disabled={isDisabled || !reToken}
            theme={isDisabled || !reToken ? ECtaTheme.TintPsi : ECtaTheme.Brand}
            size={ESize.Md}
            children="Update Subscription"
          />
        </>
      )}
    </ModalCore>
  );
};

export default EditSubscriptionModal;
