import { EProductType } from 'common/typings/enums/product';

import ConfigProvider from '../../../../services/configProvider';
import { ICartLineItem, ICartStoreState } from '../reducer';

export interface ICartAPIState
  extends Omit<
    ICartStoreState,
    'totalItems' | 'cartKey' | 'cartTotals' | 'needsPayment' | 'needsShipping'
  > {
  cart_key: string;
  cart_hash: string;
  needs_shipping: boolean;
  needs_payment: boolean;
  item_count: number;
  items: ICartLineItemAPIState[];
  totals: any;
}

interface ICartLineItemAPIState extends ICartLineItem {
  permalink: string;
}

const PROTOCOL = ConfigProvider.getValue('PROTOCOL');
const SHOP_BASE_URL = ConfigProvider.getValue('SHOP_BASE_URL');

export const cartLineItemTransformer = (
  item: ICartLineItemAPIState
): ICartLineItem => {
  const { permalink, ...rest } = item;

  return {
    ...rest,
    isPrimaryBundleProduct: Boolean(rest.product_type === EProductType.Bundle),
    isBundleItem: Boolean(rest.bundled_by),
    product_link: permalink
      .replace(`${PROTOCOL}://${SHOP_BASE_URL}/product/`, '')
      .slice(0, -1),
  };
};

export const cartTransformer = (cart: ICartAPIState): ICartStoreState => {
  const {
    cart_hash,
    cart_key,
    item_count,
    totals,
    items,
    needs_shipping,
    needs_payment,
    coupons,
    ...rest
  } = cart;

  return {
    ...rest,
    coupons,
    needsShipping: needs_shipping,
    needsPayment: needs_payment,
    cartKey: cart_key,
    items: Array.isArray(items)
      ? items.map((item) => cartLineItemTransformer(item))
      : [],
    totalItems: item_count,
    cartTotals: totals,
  };
};
