import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import './Row.scss';

interface RowInterface {
  row?: boolean;
  column?: boolean;
  reverse?: boolean;
  className?: string;
  tagElement?: Core.TTag;
}

export const Row: FunctionComponent<RowInterface> = ({
  children,
  row = true,
  column = false,
  reverse = false,
  className,
  tagElement = 'div',
  ...props
}) => {
  const TagElement = `${tagElement}` as unknown as React.FunctionComponent<any>;
  return (
    <TagElement
      className={classNames([
        className,
        {
          row: !!row,
          'row--reverse': row && reverse && !column,
          'row--column': column && !reverse,
          'row--column-reverse': column && reverse && !row,
        },
      ])}
      {...props}
    >
      {children}
    </TagElement>
  );
};

export default Row;
