export enum EProductListing {
  All = 'all',
  Shop = 'shop',
  Category = 'category',
  Categories = 'categories',
  UpSell = 'upsell_ids',
  Related = 'related_ids',
}

export enum EProductType {
  Simple = 'simple',
  Special = 'special',
  SpecialBottle = 'special-bottle',
  Subscription = 'subscription',
  SubscribeAndSave = 'subscribe',
  Grouped = 'grouped',
  Bundle = 'bundle',
  External = 'external',
  Variable = 'variable',
  MiniKeg = 'mini-keg',
  MixAndMatch = 'mix-and-match',
  Virtual = 'virtual',
  Merchandise = 'merchandise',
  GiftCard = 'gift-card',
  BundleItem = 'bundle-item',
}

export enum EProductContext {
  View = 'view',
  Edit = 'edit',
}

export enum EProductOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum EProductOrderby {
  Date = 'date',
  Id = 'id',
  Include = 'include',
  Title = 'title',
  Slug = 'slug',
}

export enum EProductStock {
  InStock = 'instock',
  OutOfStock = 'outofstock',
  OnBackorder = 'onbackorder',
}
