import React from 'react';

import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';

import { getRootSellBlockClasses } from '../../utils';

const VirtualSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy,
}) => {
  console.warn('VirtualSellBlock not used');
  return (
    <div
      className={getRootSellBlockClasses(
        renderedBy,
        undefined,
        className,
        pdp.pdp_type
      )}
    />
  );
};

export default VirtualSellBlock;
