import { EPaymentGateway } from 'common/typings/enums';
import { EProductListing } from 'common/typings/enums/product';

export const CLEAR_PAYMENT_GATEWAYS = (paymentGateway: EPaymentGateway) =>
  `[${paymentGateway}] CLEAR_PAYMENT_GATEWAYS`;
export const GET_PAYMENT_GATEWAYS = (paymentGateway: EPaymentGateway) =>
  `[${paymentGateway}] GET_PAYMENT_GATEWAYS`;
export const GET_PAYMENT_GATEWAYS_SUCCESS = (paymentGateway: EPaymentGateway) =>
  `[${paymentGateway}] GET_PAYMENT_GATEWAYS_SUCCESS`;
export const GET_PAYMENT_GATEWAYS_FAILED = (paymentGateway: EPaymentGateway) =>
  `[${paymentGateway}] GET_PAYMENT_GATEWAYS_FAILED`;

export const CLEAR_PRODUCTS = (prefix: EProductListing) =>
  `[${prefix}] CLEAR_PRODUCTS`;
export const GET_PRODUCTS = (prefix: EProductListing) =>
  `[${prefix}] GET_PRODUCTS`;
export const GET_PRODUCTS_SUCCESS = (prefix: EProductListing) =>
  `[${prefix}] GET_PRODUCTS_SUCCESS`;
export const GET_PRODUCTS_FAILED = (prefix: EProductListing) =>
  `[${prefix}] GET_PRODUCTS_FAILED`;

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

export const CLEAR_PRODUCT_CATEGORIES = 'CLEAR_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAILED = 'GET_PRODUCT_CATEGORIES_FAILED';

export const CLEAR_PRODUCT_CATEGORY = 'CLEAR_PRODUCT_CATEGORY';
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS';
export const GET_PRODUCT_CATEGORY_FAILED = 'GET_PRODUCT_CATEGORY_FAILED';
