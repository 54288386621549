import { EMenuType } from 'common/typings/enums';

export const GET_SITE_META = 'GET_SITE_META';
export const GET_SITE_META_SUCCESS = 'GET_SITE_META_SUCCESS';
export const GET_SITE_META_FAILED = 'GET_SITE_META_FAILED';

export const GET_MENU = 'GET_MENU';
export const GET_MENU_SUCCESS = (menuType: EMenuType) =>
  `[${menuType}] GET_MENU_SUCCESS`;
export const GET_MENU_FAILED = (menuType: EMenuType) =>
  `[${menuType}] GET_MENU_FAILED`;

export const SET_INITIAL_FETCH = 'SET_INITIAL_FETCH';
export const SET_MENU_STATE_OPEN = 'SET_MENU_STATE_OPEN';
export const SET_MENU_STATE_CLOSE = 'SET_MENU_STATE_CLOSE';
export const SET_APP_ERROR_TRUE = 'SET_APP_ERROR_TRUE';
export const SET_APP_ERROR_FALSE = 'SET_APP_ERROR_FALSE';
export const SET_APP_LOADING_TRUE = 'SET_APP_LOADING_TRUE';
export const SET_APP_LOADING_FALSE = 'SET_APP_LOADING_FALSE';
