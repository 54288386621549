import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IPostsStoreState } from 'common/redux/posts/reducer';
import axios from 'common/utils/axios/internal';
import { BLOG_ITEMS_PER_PAGE } from 'common/utils/constants';

import { setAppError, setAppLoading } from '../core/actions';
import { IReduxDispatch, IReduxState } from '../createStore';
import { CLEAR_POSTS, GET_POSTS_FAILED, GET_POSTS_SUCCESS } from './constants';

export const getPosts: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (page?: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/posts/${page || 1}/${BLOG_ITEMS_PER_PAGE}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));

        // We check for the error as wordpress doesn't return a 404.
        if (response.data.length === 0) {
          const error = {
            message: 'Posts not found',
            hasError: true,
            code: 404 as Core.TErrorCode,
          };
          dispatch(setAppError(true));
          return dispatch(getPostsFailed(error));
        }

        return dispatch(getPostsSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getPostsFailed(error));
      });
  };
};

export const getCategoryPosts: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    return axios
      .get(`/category/${slug}`)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));

        // We check for the error as wordpress doesn't return a 404.
        if (response.data.length === 0) {
          const error = {
            message: 'No words found for that category',
            hasError: true,
            code: 404 as Core.TErrorCode,
          };
          dispatch(setAppError(true));
          return dispatch(getPostsFailed(error));
        }

        return dispatch(getPostsSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getPostsFailed(error));
      });
  };
};

export const getTagPosts: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    return axios
      .get(`/tag/${slug}`)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));

        // We check for the error as wordpress doesn't return a 404.
        if (response.data.length === 0) {
          const error = {
            message: 'No words found for that tag',
            hasError: true,
            code: 404 as Core.TErrorCode,
          };
          dispatch(setAppError(true));
          return dispatch(getPostsFailed(error));
        }

        return dispatch(getPostsSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getPostsFailed(error));
      });
  };
};

export const getPostsSuccess = (payload: IPostsStoreState) => ({
  type: GET_POSTS_SUCCESS,
  payload,
});

export const getPostsFailed = (error: Core.IErrorResponse | AxiosError) => ({
  type: GET_POSTS_FAILED,
  payload: {
    error,
  },
});

export const clearPosts = () => ({
  type: CLEAR_POSTS,
});
