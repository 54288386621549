import {
  CLEAR_PAYMENT_INTENT,
  SET_PAYMENT_INTENT,
} from 'common/redux/payment/constants';

export const setPaymentIntent = (payload: string) => ({
  type: SET_PAYMENT_INTENT,
  payload,
});

export const clearPaymentIntent = () => ({
  type: CLEAR_PAYMENT_INTENT,
});
