import axios from 'axios';
import { Request } from 'express';
import { IncomingMessage } from 'http';

import { GHBC_AUTH_TOKEN } from '../../src/utils/constants/cookies';

export const serialiseQueryObject = <T>(query: T): string => {
  const queryKeys = Object.keys(query);
  return queryKeys.map((key) => key + '=' + query[key]).join('&');
};

export const getCookieServerSide = (
  request: Request | IncomingMessage,
  key: string
): string | undefined =>
  request && 'cookies' in request ? request?.cookies?.[key] : undefined;

export const getAuthToken = (request: Request) => {
  return getCookieServerSide(request, GHBC_AUTH_TOKEN) || request?.body?.token;
};

export const getAuthHeader = (request: Request) => ({
  Authorization:
    request.headers.authorization || `Bearer ${getAuthToken(request)}`,
});

export const validationReCaptchaToken = async (
  token?: string
): Promise<{ isValid: boolean; message: string }> => {
  try {
    const secret = process.env.RECAPTCHA_SECRET_V2;
    const { data: recaptcha } = await axios.get(
      `https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`
    );

    if (process.env.DEV_MODE === 'true') {
      console.log({ reCaptcha: recaptcha, token });
    }

    return {
      isValid: recaptcha.success,
      message: recaptcha.success ? 'Success' : 'You are not a human!',
    };
  } catch (e) {
    console.log(e);
    return {
      isValid: false,
      message: 'Something went wrong',
    };
  }
};

export const loggedInUser = async (request: Request) => {
  if (!request.headers.authorization) {
    throw new Error();
  }

  const headers = {
    'content-type': 'application/json',
    Authorization: request.headers.authorization,
  };
  const { data } = await axios.post(
    `${process.env.SITE_URL}/api/auth/user/current`,
    {},
    {
      headers,
    }
  );

  return data;
};

export {};
