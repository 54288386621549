import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import LoginForm from '@jpp/molecules/Auth/LoginForm';
import Button from '@jpp/molecules/Button';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import {
  AUTH_ROOT_CLASSNAME,
  EAuthPage,
  IAuthChildProps,
} from '@jpp/templates/Auth/Auth';
import { ECtaTheme, EPageType, EPriority } from 'common/typings/enums';

import './Login.scss';

const ROOT_CLASSNAME = 'Login';

const Login: React.FunctionComponent<IAuthChildProps> = ({ className }) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <Container className={`${AUTH_ROOT_CLASSNAME}__container`} fluid={true}>
        <Row className="justify-content-center">
          <Flex colLg={6} colXl={5} className={`${ROOT_CLASSNAME}__wrapper`}>
            <Heading
              className={`${ROOT_CLASSNAME}__heading`}
              priority={EPriority.One}
              isBold={true}
              isInnerHTML={false}
            >
              Sign up Today
            </Heading>

            <Heading
              className={`${ROOT_CLASSNAME}__sub-heading`}
              priority={EPriority.Two}
              isInnerHTML={false}
            />

            <Heading
              className={`${ROOT_CLASSNAME}__pre-heading`}
              priority={EPriority.Three}
            >
              With an account you can...
            </Heading>

            {/*<p className={`${ROOT_CLASSNAME}__copy`}>*/}
            {/*  With an account you can:*/}
            {/*</p>*/}
            <ul className={`${ROOT_CLASSNAME}__list`}>
              <li className={`${ROOT_CLASSNAME}__item`}>
                Save your account preferences
              </li>
              <li className={`${ROOT_CLASSNAME}__item`}>Manage your orders</li>
              <li className={`${ROOT_CLASSNAME}__item`}>
                Manage your subscriptions
              </li>
              <li className={`${ROOT_CLASSNAME}__item`}>
                Enjoy a faster checkout
              </li>
            </ul>
            <p className={`${ROOT_CLASSNAME}__copy`}>and so much more!</p>

            <Button
              className={`${ROOT_CLASSNAME}__button mt-2`}
              theme={ECtaTheme.BrandOutline}
              link={`/${EPageType.Auth}/${EAuthPage.Register}`}
            >
              Create account
            </Button>
          </Flex>

          <Flex colLg={5}>
            <LoginForm
              className={`${ROOT_CLASSNAME}__form`}
              element="div"
              shouldRedirect={true}
              redirectPath={`/${EPageType.Shop}`}
            />
          </Flex>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
