import classNames from 'classnames';
import { ErrorMessage, Field, FieldInputProps } from 'formik';
import React, { FunctionComponent } from 'react';

import FormLabel from '@jpp/atoms/FormLabel';
import Icons from '@jpp/atoms/Icon';
import { TElement, TFormInputType } from 'common/typings/types';

import './InputField.scss';

interface IFieldProps extends Partial<FieldInputProps<any>> {
  className?: string;
  element?: TElement;
  hasBoldLabel?: boolean;
  type?: TFormInputType;
  name: string;
  label: string;
  helpText?: string;
  pattern?: RegExp;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  autoComplete?: 'on' | 'off';
}

const ROOT_CLASSNAME = 'InputField';

export const InputField: FunctionComponent<IFieldProps> = ({
  className,
  element = 'div',
  hasBoldLabel = false,
  type: _type = 'text',
  name,
  label,
  helpText,
  ...props
}) => {
  const [type, setType] = React.useState<string>(_type);
  const Element = `${element}` as TElement;
  const isPasswordField = _type === 'password';

  return (
    <Element className={classNames(className, ROOT_CLASSNAME)}>
      <FormLabel
        text={label}
        name={name}
        isBold={hasBoldLabel}
        helpText={helpText}
      />

      <div className={`${ROOT_CLASSNAME}__error`}>
        <ErrorMessage name={name} />
      </div>

      <Field
        className={classNames(`${ROOT_CLASSNAME}__field`, {
          [`${ROOT_CLASSNAME}__field--has-show-hide`]: isPasswordField,
          [`${ROOT_CLASSNAME}__field--readonly`]: props.readOnly,
          [`${ROOT_CLASSNAME}__field--disabled`]: props.disabled,
        })}
        type={isPasswordField ? type : _type}
        name={name}
        {...props}
      />

      {isPasswordField && (
        <span
          role="button"
          className={`${ROOT_CLASSNAME}__show-hide`}
          onClick={() => setType(type === 'text' ? 'password' : 'text')}
        >
          {type === 'text' ? (
            <Icons.Eye
              inline={true}
              className={`${ROOT_CLASSNAME}__icon ${ROOT_CLASSNAME}__icon--eye`}
            />
          ) : (
            <Icons.EyeSlash
              inline={true}
              className={`${ROOT_CLASSNAME}__icon ${ROOT_CLASSNAME}__icon--eye-slash`}
            />
          )}
        </span>
      )}
    </Element>
  );
};

export default InputField;
