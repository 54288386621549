import classNames from 'classnames';
import React, { CSSProperties, useRef, useState } from 'react';

import { isClient } from 'common/utils/shared';

import './InViewportWatcher.scss';
import { supportsIntersectionObserver } from './utils';

interface IInViewportWatcherProps {
  className?: string;
  rootMargin?: string;
  threshold?: number;
  shouldOnlyTriggerOnce?: boolean;
  initiallyActive?: boolean;
  onIntersection?: (event: IIntersectionEvent) => void;
  style?: Partial<CSSProperties>;
}

interface IInViewportWatcherContext {
  didIntersect: boolean;
  setImmediate: boolean;
}

export interface IIntersectionEvent {
  isSupported: boolean;
}

const InViewportWatcherContext = React.createContext<IInViewportWatcherContext>(
  {
    didIntersect: false,
    setImmediate: false,
  }
);

const ROOT_CLASSNAME = 'InViewportWatcher';

const InViewportWatcher: React.FunctionComponent<IInViewportWatcherProps> = ({
  className,
  children,
  rootMargin = '0px',
  shouldOnlyTriggerOnce = true,
  threshold = 0,
  initiallyActive = false,
  onIntersection,
  style,
}) => {
  let intersectionObserver: IntersectionObserver;
  const element = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(initiallyActive);

  const context: IInViewportWatcherContext = {
    didIntersect: isActive,
    setImmediate: false,
  };

  const triggerIntersectionEvent = (isSupported: boolean) => {
    if (onIntersection) {
      onIntersection({
        isSupported,
      });
    }

    if (element.current && !isActive) {
      setIsActive(true);
    }
  };

  const handleOnIntersection = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      if (entry.target === element.current && entry.isIntersecting) {
        if (shouldOnlyTriggerOnce) {
          observer.unobserve(entry.target);
        }
        triggerIntersectionEvent(true);
      }
    });
  };

  const setObserver = () => {
    const doesSupportIntersectionObserver = supportsIntersectionObserver();
    const el: HTMLElement | null = element.current;
    const options: IntersectionObserverInit = {
      rootMargin,
      threshold,
    };

    if (!(doesSupportIntersectionObserver && el)) {
      return triggerIntersectionEvent(false);
    }

    intersectionObserver = new IntersectionObserver(
      handleOnIntersection,
      options
    );

    intersectionObserver.observe(el);
    isInViewport(el);
  };

  const isInViewport = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();

    if (!isClient()) {
      return;
    }

    const inView =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight);

    setIsActive(inView);
  };

  const clearObserver = () => {
    if (intersectionObserver) {
      intersectionObserver.disconnect();
    }
  };

  React.useEffect(() => {
    setObserver();

    return () => {
      clearObserver();
    };
  }, []);

  return (
    <div
      ref={element}
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-active`]: isActive,
      })}
      style={style}
    >
      <InViewportWatcherContext.Provider value={context}>
        {children}
      </InViewportWatcherContext.Provider>
    </div>
  );
};

export default InViewportWatcher;
