import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import ProductPrice from '@jpp/atoms/ProductPrice';
import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';

import { SELL_BLOCK_ROOT_CLASSNAME } from '../../utils';

interface ISellBlockPrice {
  selectedQuantity: number;
  selectedPackSize: IPdpAvailableQuantity;
  headingSuffix?: string;
  shouldMultiplyPrice?: boolean;
}

type TSellBlockPrice = ISellBlockPrice & ISellBlockProps;

const SellBlockPrice: React.FunctionComponent<TSellBlockPrice> = ({
  selectedPackSize,
  pdp,
  selectedQuantity,
  shouldMultiplyPrice,
  headingSuffix,
}) => {
  const title = headingSuffix ? `Price ${headingSuffix}` : 'Price';
  const val = parseFloat(selectedPackSize.value);
  const packSize = val < 1 ? 1 : val;

  return (
    <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__price-wrapper`}>
      <Heading
        className={classNames(`${SELL_BLOCK_ROOT_CLASSNAME}__subheading`)}
        headingStyle="5"
        priority={3}
      >
        {title}
      </Heading>
      <ProductPrice
        className={`${SELL_BLOCK_ROOT_CLASSNAME}__price`}
        regularPrice={pdp.regular_price}
        price={pdp.price}
        packSize={packSize}
        quantity={selectedQuantity}
        shouldMultiplyPrice={shouldMultiplyPrice}
      />
    </div>
  );
};

export default SellBlockPrice;
