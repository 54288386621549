import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { configTransformer } from '.';
import ConfigProvider from '../../services/configProvider';

const internalAxiosInstance: AxiosInstance = axios.create({
  baseURL: `${ConfigProvider.getValue('SITE_URL')}${ConfigProvider.getValue(
    'INTERNAL_URL'
  )}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

internalAxiosInstance.interceptors.request.use(
  (config: AxiosResponse) => {
    return configTransformer(config);
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default internalAxiosInstance;
