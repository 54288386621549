import classNames from 'classnames';
import React from 'react';

import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';
import { EProductType } from 'common/typings/enums/product';

import BundleSellBlock from './components/BundleSellBlock';
import GiftCardSellBlock from './components/GiftCardSellBlock';
import MerchandiseSellBlock from './components/MerchandiseSellBlock';
import MiniKegSellBlock from './components/MiniKegSellBlock';
import StandardSellBlock from './components/StandardSellBlock/StandardSellBlock';
import SubscribeAndSaveSellBlock from './components/SubscribeAndSaveSellBlock';
import SubscriptionSellBlock from './components/SubscriptionSellBlock';
import VirtualSellBlock from './components/VirtualSellBlock';
import './SellBlock.scss';
import { ESellBlockRenderedBy, ISellBlockProps } from './types';
import { SELL_BLOCK_ROOT_CLASSNAME } from './utils';

export const getPackSizeClasses = (
  availableQuantities: IPdpAvailableQuantity[]
) =>
  classNames(
    `${SELL_BLOCK_ROOT_CLASSNAME}__pack-size-wrapper`,
    `${SELL_BLOCK_ROOT_CLASSNAME}__pack-size-wrapper--count-${availableQuantities?.length}`
  );

const SellBlockMap: React.FunctionComponent<ISellBlockProps> = (props) => {
  const acf = props.pdp?.acf_fields || props.pdp?.acf;

  if (!acf && props.renderedBy !== ESellBlockRenderedBy.ProductModal) {
    return null;
  }

  switch (props?.pdp?.pdp_type) {
    case EProductType.Bundle:
      return <BundleSellBlock {...props} />;
    case EProductType.MiniKeg:
      return <MiniKegSellBlock {...props} />;
    case EProductType.Simple:
    case EProductType.Special:
    case EProductType.SpecialBottle:
      return <StandardSellBlock {...props} />;
    case EProductType.SubscribeAndSave:
      return <SubscribeAndSaveSellBlock {...props} />;
    case EProductType.Subscription:
      return <SubscriptionSellBlock {...props} />;
    case EProductType.GiftCard:
      return <GiftCardSellBlock {...props} />;
    case EProductType.Virtual:
      return <VirtualSellBlock {...props} />;
    case EProductType.Merchandise:
      return <MerchandiseSellBlock {...props} />;
    default:
      return null;
  }
};

export default SellBlockMap;
