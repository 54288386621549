import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { EPriority } from 'common/typings/enums';

import './LabelHeading.scss';

interface ILabelHeadingProps {
  className?: string;
  heading: string;
  priority?: EPriority;
  headingElement?: 'span';
}

const ROOT_CLASSNAME = 'LabelHeading';

const LabelHeading: React.FunctionComponent<ILabelHeadingProps> = ({
  className,
  headingElement,
  priority = EPriority.One,
  heading,
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__text-wrapper`}>
        <Heading
          priority={priority}
          headingElement={headingElement}
          className={`${ROOT_CLASSNAME}__heading`}
        >
          {heading}
        </Heading>
      </div>
    </div>
  );
};

export default LabelHeading;
