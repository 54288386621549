import { IReduxState } from 'common/redux/createStore';
import { getWooCommerce } from 'common/redux/wooCommerce/selectors';
import { getPriceWithoutCurrency } from 'common/utils/shared/product';

import {
  ICartCoupon,
  ICartCustomer,
  ICartLineItem,
  ICartShippingMethod,
  ICartStoreState,
  ICartTotals,
} from './reducer';

export const getCart = (state: IReduxState): ICartStoreState => {
  const wooCommerce = getWooCommerce(state);
  return wooCommerce.cart;
};

export const getIsCartLoaded = (state: IReduxState): boolean => {
  const cart = getCart(state);
  return cart.isCartLoaded;
};

export const getCartTotalItems = (state: IReduxState): number => {
  const cart = getCart(state);
  return cart.totalItems;
};

export const getCartLineItems = (state: IReduxState): ICartLineItem[] => {
  const cart = getCart(state);
  return cart.items;
};

export const getCartCustomer = (state: IReduxState): ICartCustomer => {
  const cart = getCart(state);
  return cart.customer;
};

export const getCartTotals = (state: IReduxState): ICartTotals => {
  const cart = getCart(state);
  return cart.cartTotals;
};

export const getCartTotal = (
  state: IReduxState,
  asNumber = false
): string | number => {
  const cart = getCart(state);
  const total = cart?.cartTotals?.total || '0';

  if (asNumber) {
    return parseFloat(getPriceWithoutCurrency(total));
  }

  return total;
};

export const getCartSubTotal = (state: IReduxState): string => {
  const cart = getCart(state);
  return cart?.cartTotals?.subtotal || '0';
};

export const getCartSubTotalTax = (state: IReduxState): string => {
  const cart = getCart(state);
  return cart?.cartTotals?.subtotal_tax || '0';
};

export const getCartShippingMethods = (
  state: IReduxState
): ICartShippingMethod[] => {
  const cart = getCart(state);
  return cart.shippingMethods;
};

export const getCartKey = (state: IReduxState): string => {
  const cart = getCart(state);
  return cart.cartKey;
};

export const getCartShippingFailed = (state: IReduxState): boolean => {
  const cart = getCart(state);
  return cart.getCartShippingFailed;
};

export const getCartCoupons = (state: IReduxState): ICartCoupon[] => {
  const { coupons } = getCart(state);
  return (
    (Array.isArray(coupons) ? coupons : Object.values(coupons as any)) || []
  );
};

export const getIsAddingToCart = (state: IReduxState): boolean => {
  const cart = getCart(state);
  return cart.isAddingToBag;
};

export const getIsAddToCartFailed = (state: IReduxState): boolean => {
  const cart = getCart(state);
  return cart.addToCartFailed;
};

export const getIsAddedToCart = (state: IReduxState): boolean => {
  const cart = getCart(state);
  return cart.isAddedToBag;
};
