import classNames from 'classnames';
import React from 'react';

import Icons from '@jpp/atoms/Icon';
import { EIconType } from '@jpp/atoms/Icon/utils/types';

import './IconAction.scss';

interface IIconActionProps {
  className?: string;
  icon: EIconType;
  inline?: boolean;
  onClick: () => void;
}

const ROOT_CLASSNAME = 'IconAction';

const IconAction: React.FunctionComponent<IIconActionProps> = ({
  className,
  icon: iconName,
  inline = false,
  onClick,
}) => {
  const Icon = Icons[iconName];
  return (
    <button
      type="button"
      className={classNames(ROOT_CLASSNAME, className)}
      onClick={onClick}
    >
      <Icon className={`${ROOT_CLASSNAME}__icon`} inline={inline} />
    </button>
  );
};

export default IconAction;
