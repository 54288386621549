import { IPageStoreState } from '../../store/page/reducer';

export interface IPageAPIState extends IPageStoreState {
  permalink: string;
  author_id: string;
  author_nicename: string;
  category_ids: string[];
  category_names: string[];
  tag_ids: string[];
  tag_names: string[];
  code?: string;
}

const pageTransform = (page: IPageAPIState & any): IPageStoreState => {
  const {
    permalink,
    author_id,
    author_nicename,
    category_ids,
    category_names,
    tag_ids,
    tag_names,
    yoast,
    terms,
    yoast_meta,
    author,
    excerpt,
    content,
    date,
    media,
    description,
    id,
    slug,
    title,
    status,
    featured_image,
    fimg_url,
    ...rest
  } = page;

  return {
    media: media ?? null,
    featured_image: featured_image || { url: fimg_url } || '',
    author,
    excerpt: excerpt?.rendered ?? excerpt,
    content: content?.rendered ?? content,
    date,
    description: description || '',
    status: status || '',
    id,
    slug,
    terms: terms || '',
    title: title?.rendered || title,
    yoast: yoast_meta || yoast,
    acf: rest.acf_fields || rest.acf,
  };
};

export default pageTransform;
