import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { EProductListing, EProductStock } from 'common/typings/enums/product';
import { IWooCommerceParams } from 'common/typings/types/woocommerce';
import axios from 'common/utils/axios/internal';
import { ITEMS_PER_REQUEST } from 'common/utils/constants';

import { serialiseQueryObject } from '../../../../server/utils';
import { setAppError, setAppLoading } from '../../core/actions';
import { IReduxDispatch, IReduxState } from '../../createStore';
import {
  CLEAR_PRODUCTS,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCESS,
} from '../constants';
import { IProductsState } from './reducer';

const defaultQuery: Partial<IWooCommerceParams> = {
  per_page: ITEMS_PER_REQUEST,
  page: 1,
  stock_status: EProductStock.InStock,
};

export const getProducts: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (
  listingType: EProductListing = EProductListing.All,
  query: Partial<IWooCommerceParams> = defaultQuery
) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_PRODUCTS(listingType) });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const serialiseQuery = serialiseQueryObject(query);
    const url = `/products${
      serialiseQuery.length > 0 ? `?${serialiseQuery}` : ''
    }`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(getProductsSuccess(listingType, response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getProductsFailed(listingType, error));
      });
  };
};

export const getProductsSuccess = (
  listingType: EProductListing,
  payload: IProductsState
) => ({
  type: GET_PRODUCTS_SUCCESS(listingType),
  listingType,
  payload,
});

export const getProductsFailed = (
  listingType: EProductListing,
  payload: Core.IErrorResponse | AxiosError
) => ({
  type: GET_PRODUCTS_FAILED(listingType),
  listingType,
  payload,
});

export const clearProducts = (listingType: EProductListing) => ({
  type: CLEAR_PRODUCTS(listingType),
  listingType,
});
