export const CREATE_ORDER_PENDING = 'CREATE_ORDER_PENDING';
export const CREATE_ORDER_PENDING_SUCCESS = 'CREATE_ORDER_PENDING_SUCCESS';
export const CREATE_ORDER_PENDING_FAILED = 'CREATE_ORDER_PENDING_FAILED';

export const SUBMIT_ORDER_PENDING = 'SUBMIT_ORDER_PENDING';
export const SUBMIT_ORDER_PENDING_SUCCESS = 'SUBMIT_ORDER_PENDING_SUCCESS';
export const SUBMIT_ORDER_PENDING_FAILED = 'SUBMIT_ORDER_PENDING_FAILED';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED';

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILED = 'CANCEL_ORDER_FAILED';

export const PROCESS_ORDER = 'PROCESS_ORDER';
export const PROCESS_ORDER_SUCCESS = 'PROCESS_ORDER_SUCCESS';
export const PROCESS_ORDER_FAILED = 'PROCESS_ORDER_FAILED';

export const PROCESS_SUBSCRIPTION_ORDER = 'PROCESS_SUBSCRIPTION_ORDER';
export const PROCESS_SUBSCRIPTION_ORDER_SUCCESS =
  'PROCESS_SUBSCRIPTION_ORDER_SUCCESS';
export const PROCESS_SUBSCRIPTION_ORDER_FAILED =
  'PROCESS_SUBSCRIPTION_ORDER_FAILED';
