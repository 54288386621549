import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { EMenuType } from 'common/typings/enums';
import defaultAxios from 'common/utils/axios';
import axios from 'common/utils/axios/internal';

import ConfigProvider from '../../services/configProvider';
import { IReduxDispatch, IReduxState } from '../createStore';
import {
  GET_MENU,
  GET_MENU_FAILED,
  GET_MENU_SUCCESS,
  GET_SITE_META,
  GET_SITE_META_FAILED,
  GET_SITE_META_SUCCESS,
  SET_INITIAL_FETCH,
} from './constants';

export const getSiteMeta: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = () => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_SITE_META });

    const url = `${ConfigProvider.getValue(
      'SITE_URL'
    )}${ConfigProvider.getValue('INTERNAL_URL')}/options/acf`;

    return defaultAxios
      .get(url)
      .then((response: AxiosResponse) => {
        return dispatch(getSiteMetaSuccess(response.data));
      })
      .catch((e: AxiosError) => {
        Sentry.captureException(e);
        return dispatch(getSiteMetaFailed(e));
      });
  };
};

export const getMenu: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (menuType: EMenuType) => {
  const menuTypeSlugMap = new Map<EMenuType, string>();
  menuTypeSlugMap.set(EMenuType.PrimaryMenu, 'primary-menu');
  menuTypeSlugMap.set(EMenuType.MobileMenu, 'mobile-menu');
  menuTypeSlugMap.set(EMenuType.TermsMenu, 'terms-menu');
  menuTypeSlugMap.set(EMenuType.FooterMenuOne, 'footer-menu-one');
  menuTypeSlugMap.set(EMenuType.FooterMenuTwo, 'footer-menu-two');

  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_MENU });
    return axios
      .get('/menus/' + menuTypeSlugMap.get(menuType))
      .then((response: AxiosResponse) => {
        return dispatch(getMenuSuccess(menuType, response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        return dispatch(getMenuFailed(menuType, error));
      });
  };
};

export const getSiteMetaSuccess = (data: Core.IOptions) => ({
  type: GET_SITE_META_SUCCESS,
  payload: {
    options: {
      ...data,
    },
  },
});

export const getMenuSuccess = (
  menuType: EMenuType,
  payload: Core.IMenuItem[]
) => ({
  type: GET_MENU_SUCCESS(menuType),
  menuType,
  payload,
});

export const getMenuFailed = (menuType: EMenuType, payload: AxiosError) => ({
  type: GET_MENU_FAILED(menuType),
  menuType,
  payload,
});

export const getSiteMetaFailed = (error: AxiosError) => ({
  type: GET_SITE_META_FAILED,
  payload: {
    error,
  },
});

export const setInitialFetch = (value: boolean) => ({
  type: SET_INITIAL_FETCH,
  payload: value,
});

export const setAppError = (value: boolean) => {
  const val = `${value}`.toUpperCase();

  return {
    type: `SET_APP_ERROR_${val}`,
    payload: value,
  };
};

export const setMenuState = (value: boolean) => {
  const val = value ? 'OPEN' : 'CLOSE';

  return {
    type: `SET_MENU_STATE_${val}`,
    payload: value,
  };
};

export const setAppLoading = (value: boolean) => {
  const val = `${value}`.toUpperCase();

  return {
    type: `SET_APP_LOADING_${val}`,
    payload: value,
  };
};
