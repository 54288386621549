import classNames from 'classnames';
import React from 'react';

import AnchorLink from '@jpp/atoms/AnchorLink';
import RegisterForm from '@jpp/molecules/Auth/RegisterForm';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';
import { EPageType } from 'common/typings/enums';

import { AUTH_ROOT_CLASSNAME, EAuthPage, IAuthChildProps } from '../../Auth';
import './Register.scss';

const ROOT_CLASSNAME = 'Register';

const Register: React.FunctionComponent<IAuthChildProps> = ({ className }) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <Container className={`${AUTH_ROOT_CLASSNAME}__container`} fluid={true}>
        <Row>
          <Flex colLg={10} center={true}>
            <RegisterForm
              className={`${ROOT_CLASSNAME}__form`}
              element="article"
              shouldRedirect={true}
              redirectPath={`/${EPageType.Shop}`}
            />

            <AnchorLink
              className={`${ROOT_CLASSNAME}__link`}
              href={`/${EPageType.Auth}/${EAuthPage.Login}`}
            >
              Already have an account? Login here
            </AnchorLink>
          </Flex>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
