import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { EPriority } from 'common/typings/enums';

import './Heading.scss';

interface IHeadingProps {
  priority?: Core.TPriority;
  className: string;
  isBold?: boolean;
  headingElement?: 'span';
  headingStyle?: Core.TPriority;
  isInnerHTML: boolean;
}

const ROOT_CLASSNAME = 'Heading';

const Heading: FunctionComponent<Partial<IHeadingProps>> = ({
  priority = EPriority.One,
  children,
  headingElement,
  className,
  isBold = false,
  headingStyle: _headingStyle,
  isInnerHTML = true,
}) => {
  const Element = (
    !!headingElement ? headingElement : (`h${priority}` as unknown)
  ) as React.FunctionComponent<any>;
  const headingStyle = _headingStyle || priority;
  const defaultProps = {
    className: classNames(
      ROOT_CLASSNAME,
      `${ROOT_CLASSNAME}--${headingStyle}`,
      className,
      {
        [`${ROOT_CLASSNAME}--${headingStyle}-bold`]: isBold,
        // [`Heading-${headingStyle}--inherit`]: !!className
      }
    ),
  };

  if (isInnerHTML) {
    return (
      <Element
        {...defaultProps}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  }

  return <Element {...defaultProps}>{children}</Element>;
};

export default Heading;
