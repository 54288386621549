import { IAuthStoreState } from 'common/redux/auth/reducer';

export const formatAuthState = (customer): IAuthStoreState => {
  const subData = customer.meta._wcs_subscription_ids_cache?.[0]?.split(':');
  const num = Number(subData?.[1]);
  return {
    ...customer,
    hasSubscriptions: Boolean(Number.isInteger(num) && num > 0),
  };
};
