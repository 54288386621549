export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';

export const GET_FEATURED_EVENTS = 'GET_FEATURED_EVENTS';
export const GET_FEATURED_EVENTS_SUCCESS = 'GET_FEATURED_EVENTS_SUCCESS';
export const GET_FEATURED_EVENTS_FAILED = 'GET_FEATURED_EVENTS_FAILED';

export const GET_CATEGORY_EVENTS = 'GET_CATEGORY_EVENTS';
export const GET_CATEGORY_EVENTS_SUCCESS = 'GET_CATEGORY_EVENTS_SUCCESS';
export const GET_CATEGORY_EVENTS_FAILED = 'GET_CATEGORY_EVENTS_FAILED';

export const CLEAR_EVENTS_STATE = 'CLEAR_EVENTS_STATE';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const CLEAR_FEATURED_EVENTS = 'CLEAR_FEATURED_EVENTS';
export const CLEAR_CATEGORY_EVENTS = 'CLEAR_CATEGORY_EVENTS';
