import { FunctionComponent } from 'react';

import { EIconType, TIconProps } from './types';

const IconComponents: Record<EIconType, FunctionComponent<TIconProps>> = {
  [EIconType.Arrow]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/arrow.svg'),
  [EIconType.ArrowLong]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/arrow-long.svg'),
  [EIconType.Bag]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/bag.svg'),
  [EIconType.Beer]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/beer.svg'),
  [EIconType.Burnt]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/burnt.svg'),
  [EIconType.Brewery]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/brewery.svg'),
  [EIconType.Broccoli]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/broccoli.svg'),
  [EIconType.Box]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/box.svg'),
  [EIconType.BoxOpen]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/box-open.svg'),
  [EIconType.Drinkaware]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/drinkaware.svg'),
  [EIconType.Eye]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/eye-regular.svg'),
  [EIconType.EyeSlash]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/eye-slash-regular.svg'),
  [EIconType.Medicinal]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/medicinal.svg'),
  [EIconType.Fruity]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/fruity.svg'),
  [EIconType.Floral]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/floral.svg'),
  [EIconType.Citrus]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/citrus.svg'),
  [EIconType.Close]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/close.svg'),
  [EIconType.Employee]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/employee.svg'),
  [EIconType.Sour]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/sour.svg'),
  [EIconType.Fresh]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/fresh.svg'),
  [EIconType.Grain]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/grain.svg'),
  [EIconType.Grassy]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/grassy.svg'),
  [EIconType.Group]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/group.svg'),
  [EIconType.Hoppy]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/hoppy.svg'),
  [EIconType.Logo]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/logo.svg'),
  [EIconType.LogoSocial]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/logo-social.svg'),
  [EIconType.LogoSymbol]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/logo-symbol.svg'),
  [EIconType.LogoText]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/logo-text.svg'),
  [EIconType.Malty]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/malty.svg'),
  [EIconType.Dairy]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/dairy.svg'),
  [EIconType.Strong]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/strong.svg'),
  [EIconType.Nutty]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/nutty.svg'),
  [EIconType.Refreshing]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/refreshing.svg'),
  [EIconType.Salty]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/salty.svg'),
  [EIconType.Peppery]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/peppery.svg'),
  [EIconType.Smokey]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/smokey.svg'),
  [EIconType.Spicy]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/spicy.svg'),
  [EIconType.Earthy]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/earthy.svg'),
  [EIconType.Vegetal]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/vegetal.svg'),
  [EIconType.Woody]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/woody.svg'),
  [EIconType.Yeasty]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/yeasty.svg'),
  [EIconType.Facebook]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/facebook-square.svg'),
  [EIconType.Instagram]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/instagram.svg'),
  [EIconType.Twitter]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/twitter.svg'),
  [EIconType.Untappd]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/untappd.svg'),
  [EIconType.GooglePay]: require('-!svg-react-loader?props[]=class:svg!../../../../assets/svg/google-pay.svg'),
};

export default IconComponents;
