import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import { EIconType } from '@jpp/atoms/Icon/utils/types';
import Portal from '@jpp/atoms/Portal';
import IconAction from '@jpp/molecules/IconAction';
import { TFuncVoid } from 'common/typings/types';
import { modalVariants, setBodyOverflow } from 'common/utils/shared';

import './ModalCore.scss';

export const MODAL_ROOT_ID = 'js-modal-root';

export interface IModalCoreProps {
  className?: string;
  parentClassName?: string;
  isVisible: boolean;
  bodyOverflow?: boolean;
  onClose: TFuncVoid;
  hasOverlay?: boolean;
  hasCloseIcon?: boolean;
  hasOverlayClose?: boolean;
}

export interface IModalChildProps {
  className?: string;
  isVisible?: boolean;
  onClose: TFuncVoid;
  bodyOverflow?: boolean;
}

export const ModalRoot: React.FunctionComponent = () => (
  <div id={MODAL_ROOT_ID} />
);

const ROOT_CLASSNAME = 'ModalCore';

export const MODAL_OVERLAY_HIDDEN_CLASSNAME = `${ROOT_CLASSNAME}__body-overflow-hidden`;

const ModalCore: React.FunctionComponent<IModalCoreProps> = ({
  isVisible,
  className,
  parentClassName,
  children,
  hasOverlay = true,
  bodyOverflow = true,
  hasOverlayClose = false,
  onClose,
  hasCloseIcon = true,
}) => {
  const selector = `#${MODAL_ROOT_ID}`;

  if (!bodyOverflow) {
    setBodyOverflow(isVisible, MODAL_OVERLAY_HIDDEN_CLASSNAME);
  }

  React.useEffect(() => {
    return () => {
      setBodyOverflow(false, MODAL_OVERLAY_HIDDEN_CLASSNAME);
    };
  }, []);

  return (
    <Portal selector={selector}>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={modalVariants}
      >
        <div
          className={classNames(ROOT_CLASSNAME, parentClassName, {
            [`${ROOT_CLASSNAME}--is-visible`]: isVisible,
          })}
        >
          {hasOverlay && (
            <button
              type="button"
              className={classNames(`${ROOT_CLASSNAME}__overlay`, {
                [`${ROOT_CLASSNAME}__overlay--no-pointer-events`]:
                  !hasOverlayClose,
              })}
              onClick={hasOverlayClose ? onClose : undefined}
            />
          )}

          <div
            className={classNames(`${ROOT_CLASSNAME}__body`, className, {
              [`${ROOT_CLASSNAME}__body--is-visible`]: isVisible,
            })}
          >
            {hasCloseIcon && (
              <IconAction
                className={`${ROOT_CLASSNAME}__close`}
                icon={EIconType.Close}
                onClick={onClose}
              />
            )}

            {children}
          </div>
        </div>
      </motion.div>
    </Portal>
  );
};

export default ModalCore;
