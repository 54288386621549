import { FormikHelpers } from 'formik';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';

import ConfigProvider from '../services/configProvider';

export interface IReCaptchaFormValues {
  token: string;
}

export const RECAPTCHA_FIELDS = {
  token: 'token',
};

export const RECAPTCHA_DEFAULT_VALUE = {
  token: '',
};

export const RECAPTCHA_YUP = {
  token: Yup.string().required(),
};

export enum EReCaptchaSize {
  Invisible = 'invisible',
  Normal = 'normal',
  Compact = 'compact',
}

const useGoogleReCaptcha = <T extends object>(
  setFieldValue: FormikHelpers<T>['setFieldValue'],
  field: string,
  submitCount: number,
  dirty = false
) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const siteKey = ConfigProvider.getValue('RECAPTCHA_SITE_KEY_V2');
  const size = recaptchaRef?.current?.props?.size;

  const getCaptchaToken = async () => {
    if (size === EReCaptchaSize.Invisible && dirty) {
      const token = await recaptchaRef?.current?.executeAsync();
      setFieldValue(field, token);
    }
  };

  const onRecaptchaChange = (token: string) => {
    if (size !== EReCaptchaSize.Invisible) {
      setFieldValue(field, token);
    }
  };

  React.useEffect(() => {
    getCaptchaToken();
  }, [dirty]);

  React.useEffect(() => {
    if (submitCount > 0) {
      recaptchaRef?.current?.reset();
    }
  }, [submitCount]);

  return {
    recaptchaRef,
    siteKey,
    onRecaptchaChange,
  };
};

export default useGoogleReCaptcha;
