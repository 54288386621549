import classNames from 'classnames';
import React from 'react';

import InViewportWatcher from '@jpp/shared/InViewPortWatcher';
import { TFuncVoid } from 'common/typings/types';
import { hasObjectGotKeys } from 'common/utils/shared';

import './Image.scss';

interface IImageProps {
  className?: string;
  image?: Core.IMedia;
  onLoad?: TFuncVoid;
  onError?: TFuncVoid;
  shouldLazyLoad?: boolean;
}

const ROOT_CLASSNAME = 'Image';

const Image: React.FC<IImageProps> = ({
  className,
  image,
  onLoad,
  onError,
  shouldLazyLoad = true,
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  if (!image || !hasObjectGotKeys(image)) {
    return null;
  }

  const { title, name, height, width, sizes, alt, url, src } = image || {};

  const imageSrc = sizes?.large || sizes?.medium_large || url || src;

  const imageProps = {
    src: imageSrc,
    className: classNames(ROOT_CLASSNAME, className),
    loading: 'lazy' as any,
  };

  const isWidthGreaterThanHeight = width > height;
  const imageComponent = (
    <img
      {...imageProps}
      className={classNames({
        [`${ROOT_CLASSNAME}__image`]: shouldLazyLoad,
        [`${className}`]: className && !shouldLazyLoad,
        [`${ROOT_CLASSNAME}__image--max-width`]: !isWidthGreaterThanHeight,
        [`${ROOT_CLASSNAME}__image--max-height`]: isWidthGreaterThanHeight,
      })}
      alt={alt || name || title}
      onError={onError}
      onLoad={onLoad}
      width={width}
      height={height}
    />
  );

  if (!shouldLazyLoad) {
    return imageComponent;
  }

  const handleOnContentVisible = () => {
    setIsVisible(true);
  };

  const ratio =
    (isWidthGreaterThanHeight ? height / width : width / height) * 100;

  return (
    <InViewportWatcher
      onIntersection={handleOnContentVisible}
      className={classNames(ROOT_CLASSNAME, className, {
        [`${ROOT_CLASSNAME}--is-visible`]: isVisible,
      })}
      rootMargin="50px"
      shouldOnlyTriggerOnce={true}
      threshold={0}
      style={{ paddingBottom: `${ratio}%` }}
    >
      {isVisible && imageComponent}
    </InViewportWatcher>
  );
};

export default Image;
