export enum EQueryParam {
  Slug = 'slug',
}

export const getQueryParamFirstValue = <P = string, R extends string = string>(
  param: P | P[] | undefined
): R => (Array.isArray(param) ? param[0] : param) as unknown as R;

export const getQueryParamValue = <T extends string = string>(
  param: T | T[] | undefined,
  delimiter = '/'
): T | undefined =>
  (Array.isArray(param) ? param.join(delimiter) : param) as T | undefined;

export const cleanSlug = (slug: string) => slug?.replace(/\/\//g, '') ?? '';
