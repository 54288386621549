// export const requiredMessageMap = {
//   email: '* Please add a valid email address',
//   code: '* Please use the code sent to your email',
//   phone: '* A contact number is required',
//   phoneDelivery: '* A contact number is required for delivery',
//   firstName: '* Please add your name',
//   lastName: '* Please add your surname',
//   addressLine1: '* Please add your address line 1',
//   addressCity: '* Please add your city',
//   addressPostCode: '* Please add your postcode',
//   country: '* The country field is required',
//   password: '* Please enter a password',
//   confirmPassword: '* Please confirm your password'
// };

export const requiredMessageMap = {
  email: '* Required',
  code: '* Required',
  phone: '* Required',
  phoneDelivery: '* Required',
  firstName: '* Required',
  lastName: '* Required',
  addressLine1: '* Required',
  addressCity: '* Required',
  addressPostCode: '* Required',
  country: '* Required',
  password: '* Required',
  confirmPassword: '* Required',
};

export const invalidMessageMap = {
  email: '* Your email address is invalid',
  phone: '* Your contact number is invalid',
  postCode: '* Post code is invalid',
  confirmPassword: '* Passwords do not match',
};
