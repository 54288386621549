import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { EPageType } from 'common/typings/enums';
import axios from 'common/utils/axios/internal';
import pageTransform from 'common/utils/transformers/page.transformer';
import { pdpTransformer } from 'common/utils/transformers/pdp.transformer';
import { plpTransformer } from 'common/utils/transformers/plp.transformer';

import { setAppError, setAppLoading } from '../core/actions';
import { IReduxDispatch, IReduxState } from '../createStore';
import {
  CLEAR_PAGE,
  GET_PAGE,
  GET_PAGE_FAILED,
  GET_PAGE_SUCCESS,
} from './constants';
import { IPageStoreState } from './reducer';

export const getPage: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: GET_PAGE });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/page/${slug}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => passGetPageSuccess(response, dispatch))
      .catch((error: AxiosError) => passGetPageFailed(error, dispatch));
  };
};

export const getShopPage: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: GET_PAGE });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/shop-page/${slug}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) =>
        passGetPageSuccess(response, dispatch, EPageType.Shop)
      )
      .catch((error: AxiosError) => passGetPageFailed(error, dispatch));
  };
};

export const getPlpPage: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: GET_PAGE });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/product-listing-page/${slug}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) =>
        passGetPageSuccess(response, dispatch, EPageType.Products)
      )
      .catch((error: AxiosError) => passGetPageFailed(error, dispatch));
  };
};

export const getPdpPage: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: GET_PAGE });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/product-details-page/${slug}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) =>
        passGetPageSuccess(response, dispatch, EPageType.Product)
      )
      .catch((error: AxiosError) => passGetPageFailed(error, dispatch));
  };
};

export const getBarPage: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: GET_PAGE });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/bar/${slug}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) =>
        passGetPageSuccess(response, dispatch, EPageType.Bar)
      )
      .catch((error: AxiosError) => passGetPageFailed(error, dispatch));
  };
};

export const getEventPage: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (slug: string): any => {
  return (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: GET_PAGE });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/event/${slug}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) =>
        passGetPageSuccess(response, dispatch, EPageType.Event)
      )
      .catch((error: AxiosError) => passGetPageFailed(error, dispatch));
  };
};

const passGetPageSuccess = (
  response: AxiosResponse,
  dispatch: Dispatch,
  pageType: EPageType = EPageType.Page,
  useTransformer = false
) => {
  dispatch(setAppLoading(false));

  const transformerMap = {
    [EPageType.Page]: pageTransform,
    [EPageType.Shop]: pageTransform,
    [EPageType.Product]: pdpTransformer,
    [EPageType.Products]: plpTransformer,
  };

  // We check for the error as wordpress doesn't return a 404.
  if (response.data.length === 0) {
    const error = {
      message: 'Page not found',
      hasError: true,
      code: 404 as Core.TErrorCode,
    };
    dispatch(setAppError(true));
    return dispatch(getPageFailed(error));
  }

  const data = Array.isArray(response.data) ? response.data[0] : response.data;

  if (useTransformer) {
    return dispatch(getPageSuccess(transformerMap[pageType](data)));
  }

  return dispatch(getPageSuccess(data));
};

const passGetPageFailed = (error: AxiosError, dispatch: Dispatch) => {
  Sentry.captureException(error);
  dispatch(setAppLoading(false));
  dispatch(setAppError(true));
  return dispatch(getPageFailed(error));
};

export const getPageSuccess = (payload: IPageStoreState) => ({
  type: GET_PAGE_SUCCESS,
  payload,
});

export const getPageFailed = (error: Core.IErrorResponse | AxiosError) => ({
  type: GET_PAGE_FAILED,
  payload: {
    error,
  },
});

export const clearPage = () => ({
  type: CLEAR_PAGE,
});
