import classNames from 'classnames';
import React from 'react';

import { EIconType } from '@jpp/atoms/Icon/utils/types';
import useToggle from '@jpp/hooks/useToggle';
import IconAction from '@jpp/molecules/IconAction';
import { TFuncVoid } from 'common/typings/types';

import './Alert.scss';

export enum EAlertType {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Default = 'default',
}

interface IAlertProps {
  className?: string;
  isVisible: boolean;
  hasClose?: boolean;
  alertType: EAlertType;
  onClose?: TFuncVoid;
}

const ROOT_CLASSNAME = 'Alert';

const Alert: React.FunctionComponent<IAlertProps> = ({
  className,
  isVisible: shouldShow,
  hasClose = true,
  alertType,
  onClose,
  children,
}) => {
  const [isVisible, toggleIsVisible] = useToggle(shouldShow);

  if (!isVisible) {
    return null;
  }

  const handleOnClose = () => {
    onClose?.();
    toggleIsVisible();
  };

  return (
    <div
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${alertType}`,
        {
          [`${ROOT_CLASSNAME}--has-close`]: hasClose,
        }
      )}
    >
      <div className={`${ROOT_CLASSNAME}__content`}>{children}</div>

      {hasClose && (
        <IconAction
          className={`${ROOT_CLASSNAME}__close`}
          icon={EIconType.Close}
          inline={true}
          onClick={handleOnClose}
        />
      )}
    </div>
  );
};

export default Alert;
