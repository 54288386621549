import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useToggle from '@jpp/hooks/useToggle';
import Button from '@jpp/molecules/Button';
import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';
import { AuthModal } from '@jpp/organisms/Modal';
import { getAuthIsLoggedIn } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';
import { ECtaTheme } from 'common/typings/enums';

import {
  getRootSellBlockClasses,
  SELL_BLOCK_ROOT_CLASSNAME,
} from '../../utils';
import SellBlockCtas from '../SellBlockCtas';
import SellBlockPrice from '../SellBlockPrice';
import './SubscriptionSellBlock.scss';

const SubscriptionSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy,
}) => {
  const [isModalVisible, toggleIsModalVisible, setIsModalVisible] =
    useToggle(false);
  const { isLoggedIn } = useSelector<IReduxState, { isLoggedIn: boolean }>(
    (state) => ({
      isLoggedIn: getAuthIsLoggedIn(state),
    })
  );
  const availableQuantities: IPdpAvailableQuantity[] = [
    { label: 'Subscription', value: '1' },
  ];

  const [selectedPackSize] = React.useState<IPdpAvailableQuantity>(
    availableQuantities[0]
  );
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(1);

  useEffect(() => {
    setSelectedQuantity(1);
  }, []);

  return (
    <>
      <div
        className={getRootSellBlockClasses(
          renderedBy,
          undefined,
          className,
          pdp.pdp_type
        )}
      >
        <SellBlockPrice
          pdp={pdp}
          renderedBy={renderedBy}
          selectedPackSize={selectedPackSize}
          selectedQuantity={selectedQuantity}
        />

        {!isLoggedIn ? (
          <>
            <p
              className={`${SELL_BLOCK_ROOT_CLASSNAME}__subscription-disclaimer mb-1`}
            >
              You must have an account to set up a subscription. <br />{' '}
              <span
                className={`${SELL_BLOCK_ROOT_CLASSNAME}__subscription-disclaimer-button`}
                role="button"
                onClick={() => setIsModalVisible(true)}
              >
                Please sign up or login here
              </span>{' '}
              to proceed.
            </p>

            <Button
              className={`${SELL_BLOCK_ROOT_CLASSNAME}__button ${SELL_BLOCK_ROOT_CLASSNAME}__button--subscription mb-1`}
              theme={ECtaTheme.Brand}
              behaviour="action"
              isFull={true}
              onClick={() => setIsModalVisible(true)}
            >
              Login
            </Button>
          </>
        ) : (
          <SellBlockCtas
            pdp={pdp}
            shouldDisableAddToBag={!isLoggedIn}
            renderedBy={renderedBy}
            selectedPackSize={selectedPackSize}
            selectedQuantity={1}
          />
        )}
      </div>

      {isModalVisible && !isLoggedIn && (
        <AuthModal
          isVisible={isModalVisible}
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__auth-modal`}
          onClose={toggleIsModalVisible}
          bodyOverflow={false}
        />
      )}
    </>
  );
};

export default SubscriptionSellBlock;
