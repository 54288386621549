import React from 'react';

import Heading from '@jpp/atoms/Heading';
import Button from '@jpp/molecules/Button';
import { ECtaTheme, EPageType } from 'common/typings/enums';

import { EAuthPage, IAuthChildProps } from '../../Auth';
import './Logout.scss';

const ROOT_CLASSNAME = 'Logout';

const Logout: React.FunctionComponent<IAuthChildProps> = () => {
  return (
    <div className={ROOT_CLASSNAME}>
      <div className={`${ROOT_CLASSNAME}__content`}>
        <Heading className={`${ROOT_CLASSNAME}__heading`} isBold={true}>
          See Ya!
        </Heading>

        <p className={`${ROOT_CLASSNAME}__copy`}>You are now logged out.</p>

        <div className={`${ROOT_CLASSNAME}__cta-wrapper`}>
          <Button
            className={`${ROOT_CLASSNAME}__btn`}
            link={`/${EPageType.Shop}`}
            theme={ECtaTheme.Brand}
            isFull={true}
          >
            Continue shopping
          </Button>

          <Button
            className={`${ROOT_CLASSNAME}__btn`}
            link={`/${EPageType.Auth}/${EAuthPage.Login}`}
            theme={ECtaTheme.BrandOutline}
            isFull={true}
          >
            Log back in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
