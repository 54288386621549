export enum EOribiEvents {
  TrackPurchase = 'trackPurchase',
}

export enum EFaceBookEvents {
  InitiateCheckout = 'initiateCheckout',
  ConfirmedPurchased = 'confirmedPurchased',
  AddToCart = 'addToCart',
  UpdateProductInCart = 'updateProductInCart',
  RemoveProductInCart = 'removeProductInCart',
  ProductPageImpression = 'productPageImpression',
}

export enum EGtmEvents {
  CustomPageView = 'customPageView',
}

export enum EGaEvents {
  ProductImpressions = 'eec.impressionView',
  ProductDetails = 'eec.detail',
  ProductClick = 'eec.impressionClick',
  CheckoutStart = 'eec.checkout',
  UpdateCart = 'eec.update',
  RemoveFromCart = 'eec.remove',
  AddToCart = 'eec.add',
  Purchase = 'eec.purchase',
}

export enum EGoogleCheckoutStep {
  Shipping = 1,
  Billing,
  Payment,
}

export enum EProductRenderedBy {
  Pdp = 'Product Details Page',
  Plp = 'Product Listing Page',
  ProductSpotlight = 'Product Spotlight',
  FeaturedProducts = 'Featured Products',
  FeaturedProduct = 'Featured Product',
}

export enum ETrackingEvents {
  Customer = 'customer',
  AbandonedCart = 'abandonedCart',
}
