import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import { EProductCtaText } from '@jpp/atoms/ProductCta/constants';
import Button from '@jpp/molecules/Button';
import { ESellBlockRenderedBy } from '@jpp/molecules/SellBlock/types';
import { ProductModal } from '@jpp/organisms/Modal';
import {
  IPdpAvailableQuantity,
  IPdpProduct,
  IPdpStoreState,
} from 'common/redux/pdp/reducer';
import { ECtaTheme, EPageType, ESize } from 'common/typings/enums';
import { EProductType } from 'common/typings/enums/product';
import { TFuncValueVoid } from 'common/typings/types';

import { SELL_BLOCK_ROOT_CLASSNAME } from '../../utils';
import './PackSizes.scss';

interface IPackSizesProps {
  className?: string;
  subscribeAndSaveProducts?: IPdpProduct[];
  kegProduct?: IPdpProduct;
  miniKegProduct?: IPdpProduct;
  quantities: IPdpAvailableQuantity[];
  selectedPackSize: IPdpAvailableQuantity;
  onPackSizeSelected: TFuncValueVoid<IPdpAvailableQuantity>;
  renderedBy?: ESellBlockRenderedBy;
  pdp: IPdpStoreState;
}

const ROOT_CLASSNAME = 'PackSizes';

const PackSizes: React.FunctionComponent<IPackSizesProps> = ({
  className,
  quantities,
  pdp,
  selectedPackSize,
  subscribeAndSaveProducts,
  miniKegProduct,
  kegProduct,
  onPackSizeSelected,
  renderedBy,
}) => {
  const { subscribe_and_save_product_12, subscribe_and_save_product_24 } =
    pdp.acf_fields! || pdp.acf!;
  const router = useRouter();
  const isPdpPage = router.pathname === '/product/[slug]';
  const { subscribe_and_save } = router.query;
  const defaultModalState = isPdpPage ? subscribe_and_save === 'true' : false;
  const [isModalVisible, setIsModalVisible] =
    React.useState<boolean>(defaultModalState);
  const showSubscribeAndSave = Boolean(
    subscribe_and_save_product_12 || subscribe_and_save_product_24
  );
  const isRenderedByProductWidget =
    renderedBy === ESellBlockRenderedBy.ProductWidget;
  const isRenderedByProductModal =
    renderedBy === ESellBlockRenderedBy.ProductModal;

  const handleOnChange = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLElement>
      | React.SyntheticEvent<HTMLButtonElement | HTMLElement>,
    prodQty: IPdpAvailableQuantity
  ) => {
    onPackSizeSelected({ value: prodQty?.value, label: prodQty?.label });
  };

  const handleOnClick = () => {
    setIsModalVisible(true);
  };

  const handleOnClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <ul className={`${ROOT_CLASSNAME}__list`}>
        {quantities.map((qty, index) => {
          const { label } = qty || {};
          const isSelected = selectedPackSize.label === label;

          return (
            <li key={index} className={`${ROOT_CLASSNAME}__item`}>
              <Button
                className={classNames(`${ROOT_CLASSNAME}__button`, {
                  [`${ROOT_CLASSNAME}__button--is-selected`]: isSelected,
                })}
                size={ESize.Sm}
                theme={ECtaTheme.TintPsiOutline}
                behaviour="action"
                isFull={true}
                onClick={(event) => handleOnChange(event, qty)}
              >
                {label}
              </Button>
            </li>
          );
        })}

        {kegProduct && (
          <li key="keg" className={`${ROOT_CLASSNAME}__item`}>
            <Button
              className={`${ROOT_CLASSNAME}__button`}
              size={ESize.Sm}
              theme={ECtaTheme.TintPsiOutline}
              behaviour="router"
              isFull={true}
              link={`/${EPageType.Product}/${kegProduct.post_name}`}
            >
              Keg (50L)
            </Button>
          </li>
        )}

        {miniKegProduct && (
          <li key="mini-keg" className={`${ROOT_CLASSNAME}__item`}>
            <Button
              className={`${ROOT_CLASSNAME}__button`}
              size={ESize.Sm}
              theme={ECtaTheme.TintPsiOutline}
              behaviour="router"
              isFull={true}
              link={`/${EPageType.Product}/${miniKegProduct.post_name}`}
            >
              Mini Keg (5L)
            </Button>
          </li>
        )}

        {showSubscribeAndSave && (
          <>
            {isModalVisible && (
              <ProductModal
                className={`${SELL_BLOCK_ROOT_CLASSNAME}__modal`}
                onClose={handleOnClose}
                bodyOverflow={false}
                pdp={{
                  ...pdp,
                  pdp_type: EProductType.SubscribeAndSave,
                }}
                renderedBy={ESellBlockRenderedBy.PackSize}
              />
            )}

            <li
              key="subscribe-and-save"
              className={`${ROOT_CLASSNAME}__item ${ROOT_CLASSNAME}__item--full`}
            >
              <Button
                behaviour={
                  isRenderedByProductWidget || isRenderedByProductModal
                    ? 'router'
                    : 'action'
                }
                theme={ECtaTheme.TintPsiOutline}
                isFull={true}
                size={ESize.Sm}
                className={`${ROOT_CLASSNAME}__button ${SELL_BLOCK_ROOT_CLASSNAME}__button--subscribe-and-save`}
                onClick={
                  isRenderedByProductWidget || isRenderedByProductModal
                    ? undefined
                    : handleOnClick
                }
                link={
                  isRenderedByProductWidget || isRenderedByProductModal
                    ? `/${EPageType.Product}/${pdp.slug}`
                    : undefined
                }
                queryParams={{ subscribe_and_save: true }}
                removeSaSQuery={false}
              >
                {EProductCtaText.SubscribeAndSave}
              </Button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default PackSizes;
