import { ReactText } from 'react';

import { IPdpStoreState } from 'common/redux/pdp/reducer';
import { IProduct } from 'common/redux/wooCommerce/products/reducer';
import { ECurrencyCode } from 'common/typings/enums';
import { EProductStock, EProductType } from 'common/typings/enums/product';
import { DEFAULT_CURRENCY } from 'common/utils/constants';

export const MAX_SUB_NAV_PRODUCT_ITEMS = 3;

const getFirstPriceCharacter = (productPrice: string) =>
  productPrice?.charAt(0);

const isFirstPriceCharacterASymbol = (price: ReactText) => {
  if (typeof price === 'string') {
    let first: ReactText = getFirstPriceCharacter(price);
    first = parseFloat(first);
    return isNaN(first);
  }
  return false;
};

export const getPriceWithoutCurrency = (productPrice: string) => {
  const hasCurrencySymbol = isFirstPriceCharacterASymbol(productPrice);
  const price =
    (hasCurrencySymbol ? productPrice.substring(1) : productPrice) || '';
  return `${price}`?.replace(/,/g, '');
};

export const getCurrencyFromProductPrice = (productPrice: string): string => {
  const firstChar = getFirstPriceCharacter(productPrice);
  const floatedChar = parseFloat(firstChar);

  if (isNaN(floatedChar)) {
    return firstChar;
  }

  return DEFAULT_CURRENCY;
};

export const getTotalProductPrice = (
  productPrice: string,
  packSize = 1,
  quantity = 1,
  shouldMultiplyPrice = true
): ReactText => {
  const total = shouldMultiplyPrice
    ? parseFloat(productPrice) * packSize * quantity
    : parseFloat(productPrice);
  return getPriceWithCurrency(total);
};

export const getPriceWithCurrency = (price: number) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: ECurrencyCode.GBP,
  }).format(price);
};

export const findProductWithCategorySlug = (
  products: IProduct[],
  slug: string
): IProduct[] => {
  const filteredProducts = [] as IProduct[];
  products.map((product) => {
    return product.categories.map((category) => {
      return slug.includes(category.slug)
        ? filteredProducts.push(product)
        : undefined;
    });
  });
  return filteredProducts;
};

export const ADD_TO_BAG_DELAY = 1.5e3;

export const areProductsInStock = (products: IPdpStoreState[]): boolean => {
  const inStock: string[] = [];
  products.forEach((product) => {
    if (product.stock_status === EProductStock.InStock) {
      inStock.push(product.sku);
    }
  });
  return inStock.length !== 0;
};

export const getSubTotal = (
  _subtotal: string,
  _subtotal_tax: string,
  discount_total: string,
  discount_tax: string
) => {
  const subtotal = parseFloat(getPriceWithoutCurrency(_subtotal));
  const subtotal_tax = parseFloat(getPriceWithoutCurrency(_subtotal_tax));
  const discount = getDiscountAmount(discount_total, discount_tax);
  const sub = subtotal + subtotal_tax;
  const total = sub - discount;

  return getPriceWithCurrency(total);
};

export const isBundleProduct = (productType: EProductType): boolean => {
  return productType === EProductType.Bundle;
};

export const getBundleProductQuantity = (woosbIds: string): number => {
  const items = woosbIds.split(',');
  const quantities: number[] = [];

  items.forEach((item) => {
    return quantities.push(parseFloat(item.split('/')[1]));
  });

  return quantities.reduce((a, b) => a + b, 0);
};

export const getDiscountAmount = (
  discountTotal?: string,
  discountTax?: string
): number => {
  const discount_total =
    discountTotal && parseFloat(getPriceWithoutCurrency(discountTotal));
  const discount_tax =
    discountTax && parseFloat(getPriceWithoutCurrency(discountTax));
  return discount_total && discount_tax ? discount_total + discount_tax : 0;
};
