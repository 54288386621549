import { IReduxState } from '../createStore';
import { IProductsState, IProductsStoreState } from './products/reducer';
import { IWooCommerceStoreState } from './reducer';

export const getWooCommerce = (state: IReduxState): IWooCommerceStoreState => {
  return state.wooCommerce;
};

export const getProducts = (state: IReduxState): IProductsStoreState => {
  const wooCommerce = getWooCommerce(state);
  return wooCommerce.products;
};

export const getAllProducts = (state: IReduxState): IProductsState => {
  const products = getProducts(state);
  return products.all;
};

export const getProductCategories = (
  state: IReduxState
): Core.IProductCategory[] => {
  const wooCommerce = getWooCommerce(state);
  return wooCommerce.categories;
};

export const getCategoryProducts = (state: IReduxState): IProductsState => {
  const products = getProducts(state);
  return products.category;
};

export const getPaymentGateways = (
  state: IReduxState
): Core.IPaymentGateway[] => {
  const wooCommerce = getWooCommerce(state);
  return wooCommerce.paymentGateways;
};
