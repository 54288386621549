import { IPdpStoreState } from 'common/redux/pdp/reducer';

export enum ESellBlockRenderedBy {
  PDPHero = 'pdp-hero',
  ProductWidget = 'product-widget',
  ProductModal = 'product-modal',
  PackSize = 'pack-size',
}

export interface ISellBlockProps {
  className?: string;
  pdp: IPdpStoreState;
  renderedBy: ESellBlockRenderedBy;
  shouldDisableAddToBag?: boolean;
  shouldHideMoreInfo?: boolean;
}
