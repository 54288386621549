import { FormikBag, withFormik } from 'formik';
import Router from 'next/router';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  RECAPTCHA_DEFAULT_VALUE,
  RECAPTCHA_YUP,
} from '@jpp/hooks/useGoogleReCaptcha';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuthIsLoggedIn } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { clearAuth, login, register } from 'common/redux/rootActions';
import { EPageType } from 'common/typings/enums';
import axios from 'common/utils/axios/internal';
import { PHONE_REGEX } from 'common/utils/shared';
import {
  invalidMessageMap,
  requiredMessageMap,
} from 'common/utils/shared/validation';

import RegisterForm, {
  IDispatchRegisterFormProps,
  IRegisterFormProps,
  IRegisterFormValues,
  IStoreRegisterFormProps,
  REGISTER_FORM_FIELDS,
  TRegisterFormFields,
} from './RegisterForm';

type TProps = IRegisterFormProps & IDispatchRegisterFormProps;

const mapPropsToValues = (_: IRegisterFormProps): IRegisterFormValues => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  ...RECAPTCHA_DEFAULT_VALUE,
});

const validationSchema: Yup.ObjectSchema<
  Yup.Shape<TRegisterFormFields | undefined, TRegisterFormFields>
> = Yup.object().shape({
  firstName: Yup.string().required(requiredMessageMap.firstName),
  lastName: Yup.string().required(requiredMessageMap.lastName),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, invalidMessageMap.phone)
    .required(requiredMessageMap.phone),
  email: Yup.string()
    .email(invalidMessageMap.email)
    .required(requiredMessageMap.email),
  password: Yup.string().min(6).max(32).required(requiredMessageMap.password),
  confirmPassword: Yup.string()
    .when(REGISTER_FORM_FIELDS.password, {
      is: (val) => val && val.length > 0,
      then: Yup.string()
        .oneOf(
          [Yup.ref(REGISTER_FORM_FIELDS.password)],
          invalidMessageMap.confirmPassword
        )
        .required(requiredMessageMap.confirmPassword),
    })
    .required(requiredMessageMap.confirmPassword),
  ...RECAPTCHA_YUP,
});

const handleSubmit = async (
  values: IRegisterFormValues,
  {
    props: {
      onRegister,
      onLogin,
      onSuccess,
      onClearAuth,
      shouldRedirect,
      redirectPath,
    },
    setFieldError,
    setStatus,
  }: FormikBag<TProps, IRegisterFormValues>
) => {
  await onClearAuth();
  try {
    await onRegister(values);
    setStatus(
      'Registered successfully. Now logging you into your new account.'
    );

    const { data } = await axios.post<IAuthStoreState>('/auth/login', {
      username: values.email,
      password: values.password,
      token: values.token,
      afterRegister: true,
    });
    const { data: customer } = await axios.post('/payment/customer', {
      customer_email: values.email,
    });

    await onLogin({ data, customer }, true);

    if (onSuccess) {
      onSuccess();
      return;
    }

    if (shouldRedirect) {
      Router.push(redirectPath || EPageType.Index);
    }
  } catch (error) {
    const { response = {} } = error;
    const {
      data: { message },
    } = response;
    setFieldError('errorMessage', message);
  }
};

const mapStateToProps = (state: IReduxState): IStoreRegisterFormProps => ({
  isLoggedIn: getAuthIsLoggedIn(state),
});

const mapDispatchToProps: IDispatchRegisterFormProps = {
  onRegister: register,
  onLogin: login,
  onClearAuth: clearAuth,
};

export default connect<
  IStoreRegisterFormProps,
  IDispatchRegisterFormProps,
  IRegisterFormProps
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik<TProps, IRegisterFormValues>({
    mapPropsToValues,
    validationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    handleSubmit,
  })(RegisterForm)
);
