export const IS_ADDING_TO_BAG = 'IS_ADDING_TO_BAG';
export const IS_ADDED_TO_BAG = 'IS_ADDED_TO_BAG';
export const IS_CART_LOADED = 'IS_CART_LOADED';

export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILED = 'GET_CART_FAILED';

export const MERGE_CART = 'MERGE_CART';
export const MERGE_CART_SUCCESS = 'MERGE_CART_SUCCESS';
export const MERGE_CART_FAILED = 'MERGE_CART_FAILED';

export const GET_CART_SHIPPING = 'GET_CART_SHIPPING';
export const GET_CART_SHIPPING_SUCCESS = 'GET_CART_SHIPPING_SUCCESS';
export const GET_CART_SHIPPING_PENDING = 'GET_CART_SHIPPING_PENDING';
export const GET_CART_SHIPPING_FAILED = 'GET_CART_SHIPPING_FAILED';

export const SET_CART_SHIPPING = 'SET_CART_SHIPPING';
export const SET_CART_SHIPPING_SUCCESS = 'SET_CART_SHIPPING_SUCCESS';
export const SET_CART_SHIPPING_FAILED = 'SET_CART_SHIPPING_FAILED';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';
export const RESET_ADD_TO_CART_FAILED = 'RESET_ADD_TO_CART_FAILED';

export const UPDATE_ITEM_IN_CART = 'UPDATE_ITEM_IN_CART';
export const UPDATE_ITEM_IN_CART_SUCCESS = 'UPDATE_ITEM_IN_CART_SUCCESS';
export const UPDATE_ITEM_IN_CART_FAILED = 'UPDATE_ITEM_IN_CART_FAILED';

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILED = 'REMOVE_FROM_CART_FAILED';

export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAILED = 'CLEAR_CART_FAILED';

export const RESET_CART_STORE = 'RESET_CART_STORE';

export const COUNT_CART_ITEMS = 'COUNT_CART_ITEMS';
export const COUNT_CART_ITEMS_SUCCESS = 'COUNT_CART_ITEMS_SUCCESS';
export const COUNT_CART_ITEMS_FAILED = 'COUNT_CART_ITEMS_FAILED';

export const CALCULATE_CART_TOTALS = 'CALCULATE_CART_TOTALS';
export const CALCULATE_CART_TOTALS_SUCCESS = 'CALCULATE_CART_TOTALS_SUCCESS';
export const CALCULATE_CART_TOTALS_FAILED = 'CALCULATE_CART_TOTALS_FAILED';

export const CALCULATE_CART_SHIPPING = 'CALCULATE_CART_SHIPPING';
export const CALCULATE_CART_SHIPPING_SUCCESS =
  'CALCULATE_CART_SHIPPING_SUCCESS';
export const CALCULATE_CART_SHIPPING_FAILED = 'CALCULATE_CART_SHIPPING_FAILED';

export const GET_CART_TOTALS = 'GET_CART_TOTALS';
export const GET_CART_TOTALS_SUCCESS = 'GET_CART_TOTALS_SUCCESS';
export const GET_CART_TOTALS_FAILED = 'GET_CART_TOTALS_FAILED';

export const GET_CART_CUSTOMER = 'GET_CART_CUSTOMER';
export const GET_CART_CUSTOMER_SUCCESS = 'GET_CART_CUSTOMER_SUCCESS';
export const GET_CART_CUSTOMER_FAILED = 'GET_CART_FAILED';
