import React from 'react';

import { EPageType, ETaxonomy } from '../../typings/enums';

/**
 * Update any Object passed through
 */
export const updateObject = <T>(oldObject: T, updatedValues: Partial<T>): T => {
  if (Object.keys(updatedValues).length > 0) {
    return {
      ...oldObject,
      ...updatedValues,
    };
  }
  return {} as T;
};

export const hasObjectGotKeys = (data?: Object): boolean =>
  !!data && Object.keys(data).length > 0;

export const cleanObject = <T extends any>(input: Record<string, any>): T => {
  const cleaned = {};

  Object.keys(input).forEach((key) => {
    const value = input[key];

    if (!value || value.length === 0) {
      return;
    }

    cleaned[key] = value;
  });

  return cleaned as T;
};

/**
 * Has object got keys
 */
export const objectHasKeys = <T>(object: T): boolean => {
  return Object.keys(object).length === 0;
};

/**
 * Has array got length
 */
export const arrayHasLength = <T>(array: T): boolean => {
  return Array.isArray(array) && array.length > 0;
};

/**
 * Slugify a string in order
 */
export const slugify = (text: string | undefined): string | undefined => {
  if (!text) {
    return;
  }

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

/**
 * Sanitize encoded characters in string
 * @param text
 */
export const removeEncodedChar = (text: string): string | undefined => {
  if (text) {
    return text.replace(/&#(?:x([\da-f]+)|(\d+));/gi, '-');
  }

  return;
};

/**
 * Sanitize route pathname
 * @param text
 */
export const sanitizePathName = (text: string): string | undefined => {
  if (text) {
    return text.replace(/\//g, '-');
  }
  return;
};

/**
 * Format UK number
 * @param numberToFormat
 */
export const formatNumber = (numberToFormat: string): string =>
  numberToFormat.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1 $2 $3');

/**
 * Sanitize encoded characters in string
 * @param text
 */
export const sanitizeEncodedChar = (text: string): string => {
  if (text && typeof text === 'string') {
    return text
      .replace(/-/g, ' ')
      .replace(
        /&#(?:x([\da-f]+)|(\d+));/gi,
        (_, hex: string, dec: number): string =>
          String.fromCharCode(dec || +('0x' + hex))
      );
  }
  return `${text}`;
};

/**
 * Find out what the breakpoint is for responsive javascript functions
 * @type {{is(*): (*|undefined)}}
 */
/* istanbul ignore next */
export const breakpoint = {
  /* istanbul ignore next */
  is(s: string) {
    const size: string = s.trim();
    const sizes: { [s: string]: string } = {
      xsmall: '599px',
      small: '600px',
      medium: '900px',
      large: '1200px',
      xlarge: '1800px',
    };

    // console.log(window.matchMedia);

    // Eslint doesn't like you accessing hasOwnProperty directly on object.
    // https://eslint.org/docs/rules/no-prototype-builtins
    /* istanbul ignore else */
    if (Object.prototype.hasOwnProperty.call(sizes, size)) {
      return window.matchMedia(`only screen and (min-width: ${sizes[size]})`)
        .matches;
    }

    throw new ReferenceError(
      `The size ${size} is not a valid breakpoint: ${JSON.stringify(sizes)}`
    );
  },
};

/**
 * Clean urls that are internal
 * @param link
 * @returns {string}
 */
export const sanitizeUrl = (link: string): string => {
  if (link) {
    return (
      link
        .toLowerCase()
        .replace(/(^\w+:|^)\/\//, '')
        // .replace('/', '')
        // .replace(/\//g, '')
        // .replace('http', '')
        // .replace('https', '')
        // .replace(':', '')
        .replace(process.env.API_URL || 'gipsyhillbrew.test', '')
    );
  }

  return link;
};

export const isNonEmptyString = (value) =>
  typeof value === 'string' && value.trim() !== '';

export const mapPageIdToPage = (id: number): string => {
  switch (id) {
    case 330:
      return 'services';
    default:
      return '';
  }
};

export const mapTaxonomyToPageType: Record<ETaxonomy, EPageType> = {
  [ETaxonomy.Category]: EPageType.Category,
  [ETaxonomy.PostTag]: EPageType.PostTag,
};

export const getPageType = (
  pageType: EPageType,
  _slug: string
): { pageType: EPageType; slug: string } => {
  const slugArray = _slug.split('/');
  const slug = slugArray?.length > 0 ? slugArray[1] : _slug;
  const values = Object.values(EPageType);
  const findPageType = values.find((value) =>
    slug?.includes(value) ? value : undefined
  );

  return {
    pageType: findPageType || pageType,
    slug: findPageType ? slugArray[2] : _slug,
  };
};

export const getDynamicPage = (
  _pageType: EPageType = EPageType.Default,
  _slug = ''
): string => {
  if (_slug === EPageType.Home) {
    return '/';
  }

  if (isReservedPage(_slug)) {
    return `/${_slug}`;
  }

  const { pageType, slug } = getPageType(_pageType, _slug);

  switch (pageType) {
    case EPageType.Archive:
    case EPageType.Products:
    case EPageType.Product:
    case EPageType.Event:
    case EPageType.Bar:
    case EPageType.MyAccount:
    case EPageType.Faq:
      return `/${pageType}/[slug]`;

    case EPageType.Post:
      return `/${EPageType.Blog}/[slug]`;

    case EPageType.Category:
      return `/${EPageType.Blog}/category/[slug]`;

    case EPageType.PostTag:
      return `/${EPageType.Blog}/tag/[slug]`;

    case EPageType.Auth:
      return `/${pageType}/${slug}`;

    case EPageType.Checkout:
    case EPageType.Cart:
    case EPageType.TermsAndConditions:
    case EPageType.ReturnPolicy:
    case EPageType.CookiePolicy:
    case EPageType.PrivacyPolicy:
    case EPageType.Shop:
    case EPageType.Blog:
    case EPageType.Faqs:
      return `/${pageType}`;

    case EPageType.Home:
      return '/';

    case EPageType.Default:
    default:
      return '/[slug]';
  }
};

export const getDynamicAs = (
  _pageType: EPageType = EPageType.Default,
  _slug = ''
): string => {
  if (_slug === EPageType.Home) {
    return '/';
  }

  if (isReservedPage(_slug, true)) {
    return _slug;
  }

  const { pageType, slug: asSlug } = getPageType(_pageType, _slug);
  const slug = asSlug && asSlug?.startsWith('/') ? asSlug.substring(1) : asSlug;

  switch (pageType) {
    case EPageType.Auth:
    case EPageType.Products:
    case EPageType.Product:
    case EPageType.Event:
    case EPageType.Bar:
    case EPageType.MyAccount:
      return `/${pageType}/${slug}`;

    case EPageType.Faq:
      return `/${EPageType.Faq}/${slug}`;

    case EPageType.Archive:
    case EPageType.Post:
      return `/${EPageType.Blog}/${slug}`;

    case EPageType.Home:
      return '/';

    default:
      return `/${slug}`;
  }
};

export const isReservedPage = (slug?: string, hasLeadingSlash = false) => {
  if (!slug) {
    return;
  }

  const reservedPages: EPageType[] = [
    EPageType.Contact,
    EPageType.Cart,
    EPageType.Blog,
    EPageType.Faqs,
    EPageType.Experience,
    EPageType.Events,
    EPageType.Shop,
    EPageType.MyAccount,
    EPageType.Checkout,
    EPageType.TermsAndConditions,
    EPageType.ReturnPolicy,
    EPageType.CookiePolicy,
    EPageType.PrivacyPolicy,
  ];

  if (hasLeadingSlash) {
    return reservedPages.some((page) => slug === `/${page}`);
  }

  return reservedPages.some((page) => slug === page);
};

export const getTaxonomySlug = (slug: ETaxonomy): string => {
  const taxonomySlugMap: Record<ETaxonomy, string> = {
    [ETaxonomy.Category]: 'category',
    [ETaxonomy.PostTag]: 'tag',
  };
  return taxonomySlugMap[slug];
};

export const isClient = (): boolean => typeof window !== 'undefined';

export const setBodyOverflow = (addClass: boolean, className: string): void => {
  if (isClient()) {
    addClass
      ? document.body.classList.add(className)
      : document.body.classList.remove(className);
  }
  return;
};

export const getWindowInnerWidth = (): number | undefined => {
  if (isClient()) {
    return window.innerWidth;
  }

  return;
};

export const getWindowInnerHeight = (): number | undefined => {
  if (isClient()) {
    return window.innerHeight;
  }

  return;
};

export const VARIANT_EASE = [0.48, 0.15, 0.25, 0.96];

export const pageVariants = {
  initial: { opacity: 0, x: -10 },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      duration: 0.3,
      ease: VARIANT_EASE,
    },
  },
};

export const productVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0,
      ease: VARIANT_EASE,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      delay: 1,
      ease: VARIANT_EASE,
    },
  },
};

export const modalVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      duration: 0,
      ease: VARIANT_EASE,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: VARIANT_EASE,
    },
  },
};

export const megaMenuVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: VARIANT_EASE,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: VARIANT_EASE,
    },
  },
};

export const UK_POST_CODE_REGEX =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

const getDisplayName = (component: React.ComponentType<any>) =>
  (component && (component.displayName || component.name)) || 'Component';
export default getDisplayName;

export const asyncDelay = async (time = 3e3) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), time));

export const sanitizeString = (str: string) =>
  str?.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '')?.trim();

export const capitalizeFirstCharacter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const deDupeArrayOfObjects = <T>(arr: T[], key: keyof T): T[] => {
  const uniqueSet = new Set<T[keyof T]>();
  return arr.filter((val) => {
    const valKey = val[key];
    if (uniqueSet.has(valKey)) {
      return false;
    }
    uniqueSet.add(valKey);
    return true;
  });
};

/**
 * Filter out falsy values from array.
 * This method creates a new array
 */
export const cleanArray = <T>(arr: (T | undefined | null | false)[]): T[] =>
  (arr || []).filter(Boolean) as T[];
