import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { IPdpAvailableQuantity } from 'common/redux/pdp/reducer';

import { SELL_BLOCK_ROOT_CLASSNAME } from '../../utils';

interface ISellBlockHeading {
  availableQuantities: IPdpAvailableQuantity[];
}

const SellBlockHeading: React.FunctionComponent<ISellBlockHeading> = ({
  availableQuantities,
}) => (
  <Heading
    className={classNames(`${SELL_BLOCK_ROOT_CLASSNAME}__pick-a-size`)}
    headingStyle="5"
    priority={3}
  >
    {availableQuantities?.length > 1 ? 'Pick a size' : 'One size'}
  </Heading>
);

export default SellBlockHeading;
