import * as Sentry from '@sentry/node';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import Router from 'next/router';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Stripe } from 'stripe';

import { IRegisterFormValues } from '@jpp/molecules/Auth/RegisterForm/RegisterForm';
import {
  CLEAR_AUTH,
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS,
  REGISTER,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
} from 'common/redux/auth/constants';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { formatAuthState } from 'common/redux/auth/transformer';
import { IReduxDispatch, IReduxState } from 'common/redux/createStore';
import { getCart, setAppError, setAppLoading } from 'common/redux/rootActions';
import { RESET_CART_STORE } from 'common/redux/wooCommerce/cart/constants';
import { EPageType } from 'common/typings/enums';
import {
  GHBC_AUTH_TOKEN,
  GHBC_BASKET_ID,
} from 'common/utils/constants/cookies';
import { daysToSeconds, getExpiryDate } from 'common/utils/shared/time';

import coCartAxios from '../../utils/axios/cart';
import axios from '../../utils/axios/internal';

export const getCurrentUser: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> =
  (authToken?: string) =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_USER });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    try {
      const token = authToken || Cookies.get(GHBC_AUTH_TOKEN);

      if (!token || token === 'undefined') {
        throw new Error('No auth token');
      }

      const { data: user } = await axios.post('/auth/user/current', { token });
      const { data: customer } = await axios.post<Stripe.Customer>(
        '/payment/customer',
        { customer_email: user.email }
      );
      dispatch(setAppLoading(false));

      return dispatch(
        loginSuccess(
          GET_USER_SUCCESS,
          formatAuthState({ ...user, stripe_data: customer })
        )
      );
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(loginFailed(GET_USER_FAILED, e));
    }
  };

export const register: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> =
  ({
    errorMessage,
    shouldGetCart = true,
    ...registrationData
  }: IRegisterFormValues & { shouldGetCart?: boolean }) =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: REGISTER });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    try {
      await axios.post('/auth/user', registrationData);
      dispatch(setAppLoading(false));
      return await dispatch(registerSuccess());
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(registerFailed());
    }
  };

export const login: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> =
  ({ data, customer }, shouldGetCart = false) =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: LOGIN });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    try {
      if (!data?.token || data?.token === 'undefined') {
        throw new Error('Failed to login. Try again');
      }

      dispatch(loginSuccess(LOGIN_SUCCESS, { ...data, stripe_data: customer }));

      Cookies.set(GHBC_AUTH_TOKEN, data.token, {
        path: '/',
        expires: getExpiryDate(daysToSeconds(7)),
      });

      if (shouldGetCart) {
        dispatch({ type: RESET_CART_STORE });
        dispatch(getCart() as any);
      }
      return dispatch(setAppLoading(false));
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(loginFailed(LOGIN_FAILED, e));
    }
  };

export const logout: ActionCreator<any> =
  (shouldRedirect = false, path = `/${EPageType.Auth}/logout`) =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: LOGOUT });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    try {
      Cookies.remove(GHBC_AUTH_TOKEN, { path: '/' });
      Cookies.remove(GHBC_BASKET_ID, { path: '/' });
      await coCartAxios.post('/logout');
      if (shouldRedirect) {
        Router.push(path);
      }
      dispatch(logoutSuccess());
      dispatch(getCart() as any);
      return dispatch(setAppLoading(false));
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(logoutFailed());
    }
  };

export const registerSuccess = () => ({ type: REGISTER_SUCCESS });

export const registerFailed = () => ({ type: REGISTER_FAILED });

export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

export const logoutFailed = () => ({ type: LOGOUT_FAILED });

export const loginSuccess = (type: string, payload: IAuthStoreState) => ({
  type,
  payload,
});

export const loginFailed = (
  type: string,
  payload: Core.IErrorResponse | AxiosError
) => ({ type, payload });

export const clearAuth = () => ({ type: CLEAR_AUTH });
