import React, { ReactNode, useState } from 'react';

import ProductCta from '@jpp/atoms/ProductCta';
import { EProductCtaText } from '@jpp/atoms/ProductCta/constants';
import { IProductCtaProps } from '@jpp/atoms/ProductCta/ProductCta';
import Button from '@jpp/molecules/Button';
import {
  ESellBlockRenderedBy,
  ISellBlockProps,
} from '@jpp/molecules/SellBlock/types';
import { ProductModal } from '@jpp/organisms/Modal';
import { IPdpAvailableQuantity, IPdpProduct } from 'common/redux/pdp/reducer';
import { trackGoogleProductClick } from 'common/tracking/product';
import { EProductRenderedBy } from 'common/tracking/types';
import { ECtaTheme, EPageType } from 'common/typings/enums';
import { GIFT_CARD_PREFIX } from 'common/utils/constants';

import { SELL_BLOCK_ROOT_CLASSNAME } from '../../utils';
import { IGiftCardOption } from '../GiftCardSellBlock/GiftCardSellBlock';

interface ISellBlockCtas {
  selectedGiftCard?: IGiftCardOption;
  selectedPackSize?: IPdpAvailableQuantity;
  selectedQuantity: number;
  customData?: IProductCtaProps['customData'];
  children?: ReactNode;
}

type TSellBlockCtas = ISellBlockProps & ISellBlockCtas;

const SellBlockCtas: React.FunctionComponent<TSellBlockCtas> = ({
  pdp,
  renderedBy,
  selectedGiftCard,
  selectedPackSize,
  selectedQuantity,
  shouldDisableAddToBag = false,
  shouldHideMoreInfo = false,
  customData,
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const {
    default_product,
    subscribe_and_save_product_12,
    subscribe_and_save_product_24,
  } = pdp.acf_fields! || pdp.acf!;
  const showSubscribeAndSave = false;
  const slug = default_product.slug || pdp.slug;
  const productId =
    selectedGiftCard?.id || default_product.ID || default_product.id;
  const packSize = selectedPackSize && parseFloat(selectedPackSize.value);
  const isPdpHero = renderedBy === ESellBlockRenderedBy.PDPHero;

  const subscribeAndSaveProducts = [
    subscribe_and_save_product_12,
    subscribe_and_save_product_24,
  ].filter(Boolean) as IPdpProduct[];

  const handleOnClick = () => {
    setIsModalVisible(true);
  };

  const handleOnClose = () => {
    setIsModalVisible(false);
  };

  const handleTrackingClick = () => {
    trackGoogleProductClick(
      pdp,
      0,
      renderedBy as unknown as EProductRenderedBy
    );
  };

  return (
    <>
      {isModalVisible && Boolean(subscribeAndSaveProducts.length) && (
        <ProductModal
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__modal`}
          onClose={handleOnClose}
          bodyOverflow={true}
          pdp={pdp}
        />
      )}

      <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__ctas`}>
        {Boolean(subscribeAndSaveProducts.length) && showSubscribeAndSave && (
          <Button
            behaviour="action"
            theme={ECtaTheme.BrandOutline}
            isFull={true}
            className={`${SELL_BLOCK_ROOT_CLASSNAME}__button ${SELL_BLOCK_ROOT_CLASSNAME}__button--subscribe-and-save`}
            onClick={handleOnClick}
          >
            {EProductCtaText.SubscribeAndSave}
          </Button>
        )}

        <ProductCta
          product={pdp}
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__button ${SELL_BLOCK_ROOT_CLASSNAME}__button--add-to-cart`}
          theme={ECtaTheme.Brand}
          isFull={true}
          isDisabled={shouldDisableAddToBag}
          productId={productId}
          packSize={packSize}
          quantity={selectedQuantity}
          customData={customData}
          showBackInStockButton={true}
        />

        {!shouldHideMoreInfo && !isPdpHero && (
          <Button
            className={`${SELL_BLOCK_ROOT_CLASSNAME}__button`}
            theme={ECtaTheme.BrandOutline}
            link={`/${EPageType.Product}/${
              pdp.sku.includes(GIFT_CARD_PREFIX) ? pdp.post_name : slug
            }`}
            behaviour="router"
            isFull={true}
            onClick={handleTrackingClick}
          >
            More info
          </Button>
        )}

        {children}
      </div>
    </>
  );
};

export default SellBlockCtas;
