import { IPlpPage } from 'common/redux/plp/reducer';

export const plpTransformer = (plp: IPlpPage & any): IPlpPage => {
  const { ID, id, acf, slug, title, yoast, yoast_meta, products, acf_fields } =
    plp;

  return {
    ID: ID || id,
    id,
    acf: acf_fields || acf,
    slug,
    title,
    products,
    yoast: yoast || yoast_meta,
  };
};
