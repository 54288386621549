import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  cartTransformer,
  ICartAPIState,
} from 'common/redux/wooCommerce/cart/utils/transformers';
import axios from 'common/utils/axios/cart';
import { GHBC_BASKET_ID } from 'common/utils/constants/cookies';
import { CO_CART_CART_KEY } from 'common/utils/constants/headers';

import { setAppError, setAppLoading } from '../../core/actions';
import { IReduxDispatch, IReduxState } from '../../createStore';
import {
  ADD_TO_CART,
  ADD_TO_CART_FAILED,
  ADD_TO_CART_SUCCESS,
  CALCULATE_CART_SHIPPING,
  CALCULATE_CART_TOTALS,
  CALCULATE_CART_TOTALS_FAILED,
  CALCULATE_CART_TOTALS_SUCCESS,
  CLEAR_CART,
  CLEAR_CART_FAILED,
  CLEAR_CART_SUCCESS,
  COUNT_CART_ITEMS,
  COUNT_CART_ITEMS_FAILED,
  COUNT_CART_ITEMS_SUCCESS,
  GET_CART,
  GET_CART_CUSTOMER,
  GET_CART_CUSTOMER_FAILED,
  GET_CART_CUSTOMER_SUCCESS,
  GET_CART_FAILED,
  GET_CART_SHIPPING,
  GET_CART_SHIPPING_FAILED,
  GET_CART_SHIPPING_SUCCESS,
  GET_CART_SUCCESS,
  GET_CART_TOTALS,
  GET_CART_TOTALS_FAILED,
  GET_CART_TOTALS_SUCCESS,
  IS_ADDED_TO_BAG,
  IS_ADDING_TO_BAG,
  IS_CART_LOADED,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_FAILED,
  REMOVE_FROM_CART_SUCCESS,
  RESET_ADD_TO_CART_FAILED,
  RESET_CART_STORE,
  SET_CART_SHIPPING,
  SET_CART_SHIPPING_FAILED,
  SET_CART_SHIPPING_SUCCESS,
  UPDATE_ITEM_IN_CART,
  UPDATE_ITEM_IN_CART_FAILED,
  UPDATE_ITEM_IN_CART_SUCCESS,
} from './constants';

const setCartCookie = async (headers: Record<string, string>) => {
  const cartKey = headers[CO_CART_CART_KEY];
  const basketId = Cookies.get(GHBC_BASKET_ID);
  if (cartKey && !basketId) {
    await Cookies.set(GHBC_BASKET_ID, cartKey);
  }
};

export const getCart: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> =
  () =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_CART });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    try {
      const { data, headers } = await axios.get('/get-cart?thumb=true');
      dispatch(setAppLoading(false));
      if (typeof data === 'string') {
        return dispatch({ type: RESET_CART_STORE });
      }
      await setCartCookie(headers);
      dispatch(setIsCartLoaded(true));
      return dispatch(getCartSuccess(data));
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(getCartFailed(e));
    }
  };

export const getCartCustomer: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (cartId?: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_CART_CUSTOMER });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const urlCore = '/customer';
    const url = cartId ? `${urlCore}&id=${cartId}` : urlCore;

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(getCartCustomerSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getCartCustomerFailed(error));
      });
  };
};

export const setCartShipping: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (shippingKey: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: SET_CART_SHIPPING });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    const url = '/shipping-methods';

    return axios
      .post(url, {
        key: shippingKey,
      })
      .then((response: AxiosResponse<ICartAPIState>) => {
        dispatch(setAppLoading(false));
        return dispatch(setCartShippingSuccess(response.data) as any);
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(setCartShippingFailed(false));
      });
  };
};

export const getCartShipping: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (cartKey: string) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_CART_SHIPPING });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const urlBase = '/shipping-methods';
    const url = !!cartKey ? `${urlBase}?key=${cartKey}` : urlBase;

    return axios
      .get(url)
      .then((response: AxiosResponse<ICartAPIState>) => {
        dispatch(setAppLoading(false));
        return dispatch(getCartShippingSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(getCartShippingFailed(error));
      });
  };
};

export const clearCart: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (cartId?: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: CLEAR_CART });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    try {
      await axios.post('/clear');
      dispatch(setAppLoading(false));
      await dispatch(getCart() as any);
      return dispatch(clearCartSuccess());
    } catch (error) {
      Sentry.captureException(error);
      await dispatch(setAppLoading(false));
      await dispatch(setAppError(true));
      return dispatch(clearCartFailed(error));
    }
  };
};

export const countCartItems: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = () => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: COUNT_CART_ITEMS });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = '/count-items';

    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(countCartItemsSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(countCartItemsFailed(error));
      });
  };
};

export const calculateCartTotals: ActionCreator<
  ThunkAction<Promise<AnyAction>, IReduxState, IReduxDispatch, AnyAction>
> =
  () =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: CALCULATE_CART_TOTALS });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    const url = '/calculate';

    try {
      await axios.post(url);
      dispatch(setAppLoading(false));
      return dispatch(calculateCartTotalsSuccess());
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(calculateCartTotalsFailed());
    }
  };

export const calculateCartShipping: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (postcode: string, country: string, shouldGetShipping = true) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: CALCULATE_CART_SHIPPING });

    const url = '/calculate/shipping';

    return axios
      .post(url, {
        country: country.trim(),
        postcode: postcode.trim(),
        return_methods: true,
      })
      .then(() => {
        // dispatch(getCartTotals() as any);
        if (shouldGetShipping) {
          dispatch(getCartShipping() as any);
        }
        return dispatch(getCart() as any);
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        return dispatch(getCartShippingFailed(error));
      });
  };
};

export const getCartTotals: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> =
  (withCurrencyCode = false) =>
  async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: GET_CART_TOTALS });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/totals?html=${withCurrencyCode}`;

    try {
      const { data } = await axios.get(url, {
        data: { html: withCurrencyCode },
      });
      dispatch(setAppLoading(false));
      return dispatch(getCartTotalsSuccess(data));
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      return dispatch(getCartTotalsFailed(e));
    }
  };

export const addToCart: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (productData: Core.IAddToCart) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: ADD_TO_CART });
    dispatch(setAppLoading(true));
    dispatch(setIsAddingToCart(true));
    dispatch(setAppError(false));

    const url = '/add-item';
    const { productId, quantity, cart_item_data, variation, variation_id } =
      productData;

    try {
      const { headers } = await axios.post(url, {
        product_id: String(productId),
        quantity,
        variation_id,
        variation,
        cart_item_data,
      });
      await setCartCookie(headers);
      dispatch(setAppLoading(false));
      dispatch(setIsAddingToCart(false));
      dispatch(setIsAddedToCart(true));
      dispatch({ type: ADD_TO_CART_SUCCESS });
      setTimeout(() => {
        return dispatch(setIsAddedToCart(false));
      }, 3e3);
      return dispatch(getCart() as any);
    } catch (error) {
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      dispatch(setIsAddingToCart(false));
      setTimeout(() => {
        dispatch(resetAddToCartFailed());
      }, 5e3);
      Sentry.captureException(error);
      return dispatch(addToCartFailed(error) as any);
    }
  };
};

export const removeFromCart: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = ({ cartItemKey }: Core.IRemoveFromCart) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: REMOVE_FROM_CART });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    const url = `/item?cart_item_key=${cartItemKey}`;

    try {
      const { headers } = await axios.delete(url, {
        data: {
          cart_item_key: cartItemKey,
        },
      });
      await setCartCookie(headers);
      dispatch(setAppLoading(false));
      dispatch({ type: REMOVE_FROM_CART_SUCCESS });
      return await dispatch(getCart() as any);
    } catch (e) {
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      dispatch({ type: REMOVE_FROM_CART_FAILED });
      Sentry.captureException(e);
      return dispatch(getCart() as any);
    }
  };
};

export const updateItemInCart: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = ({ cartItemKey, quantity }: Core.IUpdateItemInCart) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: UPDATE_ITEM_IN_CART });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));
    dispatch(setIsAddingToCart(true));

    const url = '/item';

    try {
      const { headers } = await axios.post(url, {
        cart_item_key: cartItemKey,
        quantity,
      });
      await setCartCookie(headers);
      dispatch(setAppLoading(false));
      dispatch({ type: GET_CART });
      dispatch({ type: UPDATE_ITEM_IN_CART_SUCCESS });
      dispatch(setIsAddingToCart(false));
      dispatch(setIsAddedToCart(true));
      setTimeout(() => {
        return dispatch(setIsAddedToCart(false));
      }, 3e3);
      return await dispatch(getCart() as any);
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setAppLoading(false));
      dispatch(setAppError(true));
      dispatch({ type: UPDATE_ITEM_IN_CART_FAILED });
      return dispatch(getCart() as any);
    }
  };
};

export const setIsCartLoaded = (payload: boolean) => ({
  type: IS_CART_LOADED,
  payload,
});

export const setIsAddingToCart = (payload: boolean) => ({
  type: IS_ADDING_TO_BAG,
  payload,
});

export const setIsAddedToCart = (payload: boolean) => ({
  type: IS_ADDED_TO_BAG,
  payload,
});

export const getCartSuccess = (payload: any) => ({
  type: GET_CART_SUCCESS,
  payload: cartTransformer(payload),
});

export const getCartFailed = (payload: any) => ({
  type: GET_CART_FAILED,
  payload,
});

export const getCartShippingSuccess = (payload: any) => ({
  type: GET_CART_SHIPPING_SUCCESS,
  payload,
});

export const getCartShippingFailed = (payload?: any) => ({
  type: GET_CART_SHIPPING_FAILED,
  payload,
});

export const setCartShippingSuccess = (payload: any) => ({
  type: SET_CART_SHIPPING_SUCCESS,
  payload,
});

export const setCartShippingFailed = (payload: any) => ({
  type: SET_CART_SHIPPING_FAILED,
  payload,
});

export const clearCartSuccess = () => ({
  type: CLEAR_CART_SUCCESS,
});

export const clearCartFailed = (payload: any) => ({
  type: CLEAR_CART_FAILED,
  payload,
});

export const countCartItemsSuccess = (payload: any) => ({
  type: COUNT_CART_ITEMS_SUCCESS,
  payload,
});

export const countCartItemsFailed = (payload: any) => ({
  type: COUNT_CART_ITEMS_FAILED,
  payload,
});

export const calculateCartTotalsSuccess = () => ({
  type: CALCULATE_CART_TOTALS_SUCCESS,
});

export const calculateCartTotalsFailed = () => ({
  type: CALCULATE_CART_TOTALS_FAILED,
});

export const getCartTotalsSuccess = (payload: any) => ({
  type: GET_CART_TOTALS_SUCCESS,
  payload,
});

export const getCartTotalsFailed = (payload: any) => ({
  type: GET_CART_TOTALS_FAILED,
  payload,
});

export const addToCartSuccess = (payload: any) => ({
  type: ADD_TO_CART_SUCCESS,
  payload,
});

export const addToCartFailed = (payload: Core.IErrorResponse | AxiosError) => ({
  type: ADD_TO_CART_FAILED,
  payload,
});

export const resetAddToCartFailed = () => ({
  type: RESET_ADD_TO_CART_FAILED,
});

export const getCartCustomerSuccess = (payload: any) => ({
  type: GET_CART_CUSTOMER_SUCCESS,
  payload,
});

export const getCartCustomerFailed = (payload: any) => ({
  type: GET_CART_CUSTOMER_FAILED,
  payload,
});
