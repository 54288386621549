import * as Sentry from '@sentry/node';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IAuthStoreState } from 'common/redux/auth/reducer';
import { setAppError, setAppLoading } from 'common/redux/core/actions';
import { IReduxDispatch, IReduxState } from 'common/redux/createStore';
import { ICartLineItem } from 'common/redux/wooCommerce/cart/reducer';
import { ISubscriptionOrder } from 'common/redux/wooCommerce/orders/reducer';
import { EOrderStatus } from 'common/typings/enums';
import axios from 'common/utils/axios/internal';
import {
  getCartItemSubscriptionData,
  isSubscriptionProduct,
} from 'common/utils/shared/cart';
import { ISubmitOrder } from 'common/utils/shared/orders';
import {
  IWooCommerceAPIBilling,
  IWooCommerceAPIShipping,
} from 'common/utils/transformers/order.transformer';

import {
  CANCEL_ORDER,
  CANCEL_ORDER_FAILED,
  CANCEL_ORDER_SUCCESS,
  CREATE_ORDER_PENDING,
  CREATE_ORDER_PENDING_FAILED,
  CREATE_ORDER_PENDING_SUCCESS,
  GET_ORDER_FAILED,
  GET_ORDER_SUCCESS,
  PROCESS_ORDER,
  PROCESS_ORDER_FAILED,
  PROCESS_ORDER_SUCCESS,
  PROCESS_SUBSCRIPTION_ORDER,
  PROCESS_SUBSCRIPTION_ORDER_FAILED,
  PROCESS_SUBSCRIPTION_ORDER_SUCCESS,
  SUBMIT_ORDER_PENDING,
  SUBMIT_ORDER_PENDING_FAILED,
  SUBMIT_ORDER_PENDING_SUCCESS,
} from './contants';

export const createOrder: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = () => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: CREATE_ORDER_PENDING });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    return axios
      .post('/create-order')
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(createOrderPendingSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(createOrderPendingFailed(error));
      });
  };
};

export const submitOrderPending: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (formData: ISubmitOrder) => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch({ type: SUBMIT_ORDER_PENDING });
    dispatch(setAppLoading(true));
    dispatch(setAppError(false));

    return axios
      .post('/order', {
        ...formData,
      })
      .then((response: AxiosResponse) => {
        dispatch(setAppLoading(false));
        return dispatch(submitOrderPendingSuccess(response.data));
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        dispatch(setAppLoading(false));
        dispatch(setAppError(true));
        return dispatch(submitOrderPendingFailed(error));
      });
  };
};

export const processOrder: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (orderId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: PROCESS_ORDER });
  try {
    const order = await axios.get(`/order/${orderId}`);
    if (!order) {
      dispatch({ type: GET_ORDER_FAILED });
      return dispatch(processOrderFailed());
    }
    await axios.put(
      `/order/${orderId}?order_status=${EOrderStatus.Processing}`
    );
    return dispatch(processOrderSuccess());
  } catch (e) {
    Sentry.captureException(e);
    return dispatch(processOrderFailed());
  }
};

export const processSubscription: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> =
  (
    subscriptionProducts: ICartLineItem[],
    cardId: string,
    authUser: IAuthStoreState,
    shipping: IWooCommerceAPIShipping,
    billing: IWooCommerceAPIBilling
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: PROCESS_SUBSCRIPTION_ORDER });
    try {
      if (subscriptionProducts.length === 0) {
        throw new Error('No Subscription products');
      }
      for (const product of subscriptionProducts) {
        try {
          if (!isSubscriptionProduct(product)) {
            return null;
          }

          const { firstRenewal, interval, period } =
            getCartItemSubscriptionData(product);

          const startDate = new Date().toISOString().split('T')[0];
          const formData: ISubscriptionOrder = {
            billing,
            billing_interval: parseInt(interval!, 10),
            billing_period: period!,
            customer_id: authUser.id,
            line_items: [
              {
                product_id: product.product_id,
                quantity: product.quantity,
              },
            ],
            next_payment_date: firstRenewal.date,
            payment_details: {
              post_meta: {
                _stripe_customer_id: `${authUser.stripe_data?.id}`,
                _stripe_source_id: cardId,
              },
            },
            payment_method: 'stripe',
            start_date: `${startDate} 00:00:00`,
            status: 'active',
            shipping: {
              ...shipping,
              email: billing?.email,
            },
          };
          await axios.post('/subscription', formData);
        } catch (e) {
          Sentry.captureException(e);
        }
      }

      const subscription = true;

      if (!subscription) {
        Sentry.captureException(new Error('No subscription'));
        return dispatch(processSubscriptionFailed());
      }
      return dispatch(processSubscriptionSuccess());
    } catch (e) {
      Sentry.captureException(e);
      return dispatch(processOrderFailed());
    }
  };

export const cancelOrder: ActionCreator<
  ThunkAction<Promise<any>, IReduxState, IReduxDispatch, AnyAction>
> = (orderId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: CANCEL_ORDER });
  try {
    const order = await axios.get(`/order/${orderId}`);
    if (!order) {
      dispatch({ type: GET_ORDER_FAILED });
      return dispatch(cancelOrderFailed());
    }
    await axios.put(`/order/${orderId}?order_status=${EOrderStatus.Cancelled}`);
    return dispatch(cancelOrderSuccess());
  } catch (e) {
    Sentry.captureException(e);
    return dispatch(cancelOrderFailed());
  }
};

export const submitOrderPendingSuccess = (payload: any) => ({
  type: SUBMIT_ORDER_PENDING_SUCCESS,
  payload,
});

export const submitOrderPendingFailed = (payload: any) => ({
  type: SUBMIT_ORDER_PENDING_FAILED,
  payload,
});

export const createOrderPendingSuccess = (payload: any) => ({
  type: CREATE_ORDER_PENDING_SUCCESS,
  payload,
});

export const createOrderPendingFailed = (payload: any) => ({
  type: CREATE_ORDER_PENDING_FAILED,
  payload,
});

export const getOrderSuccess = () => ({
  type: GET_ORDER_SUCCESS,
});

export const getOrderFailed = () => ({
  type: GET_ORDER_FAILED,
});

export const processOrderSuccess = () => ({
  type: PROCESS_ORDER_SUCCESS,
});

export const processOrderFailed = () => ({
  type: PROCESS_ORDER_FAILED,
});

export const processSubscriptionSuccess = () => ({
  type: PROCESS_SUBSCRIPTION_ORDER_SUCCESS,
});

export const processSubscriptionFailed = () => ({
  type: PROCESS_SUBSCRIPTION_ORDER_FAILED,
});

export const cancelOrderSuccess = () => ({
  type: CANCEL_ORDER_SUCCESS,
});

export const cancelOrderFailed = () => ({
  type: CANCEL_ORDER_FAILED,
});
