import { IPdpStoreState } from 'common/redux/pdp/reducer';
import { ICartLineItem } from 'common/redux/wooCommerce/cart/reducer';
import { APP_TITLE } from 'common/utils/constants';
import { isClient } from 'common/utils/shared';
import { IWooCommerceAPILineItem } from 'common/utils/transformers/order.transformer';

export interface IDataLayerPayload {
  event: string;
}

export const pushToDataLayer = <
  T extends IDataLayerPayload = IDataLayerPayload
>(
  payload: T
) => {
  if (!isClient() || !payload) {
    return;
  }

  if (
    !window.hasOwnProperty('dataLayer') ||
    !window.hasOwnProperty('google_tag_manager')
  ) {
    window.dataLayer = [];
  }

  setTimeout(() => {
    window.dataLayer.push(payload);
  }, 0);
};

export const mapProductsToAnalytics = (products: IPdpStoreState[]) => {
  return products.map(
    ({
      sku,
      title,
      post_title,
      post_name,
      price,
      regular_price,
      id,
      ...product
    }) => {
      const acf = product.acf || product.acf_fields;
      return {
        id: sku,
        name: title || post_title || post_name,
        price: parseFloat(price).toFixed(2),
        original_price: parseFloat(regular_price).toFixed(2),
        brand: APP_TITLE,
        category: acf?.default_product_range?.name,
        variantID: id,
      };
    }
  );
};

export const mapLineItemsToAnalytics = (
  products: IWooCommerceAPILineItem[]
) => {
  return products.map(({ sku, id, name, price, product_id, quantity }) => {
    return {
      id: sku,
      product_id,
      name,
      quantity,
      price: price.toFixed(2),
      brand: APP_TITLE,
      variant: sku,
      variantID: id || product_id,
    };
  });
};

export const mapCartLineItemsToAnalytics = (products: ICartLineItem[]) => {
  return products.map(
    ({
      sku,
      product_name,
      product_title,
      product_price,
      product_id,
      quantity,
    }) => {
      return {
        id: sku,
        product_id,
        name: product_title || product_name,
        quantity,
        price: product_price,
        brand: APP_TITLE,
        variant: sku,
        variantID: product_id,
      };
    }
  );
};
