import classNames from 'classnames';
import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Heading from '@jpp/atoms/Heading';
import Select from '@jpp/atoms/Select';
import useToggle from '@jpp/hooks/useToggle';
import Button from '@jpp/molecules/Button';
import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';
import { AuthModal } from '@jpp/organisms/Modal';
import { getAuthIsLoggedIn } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { IPdpAvailableQuantity, IPdpProduct } from 'common/redux/pdp/reducer';
import {
  EBillingInterval,
  EBillingPeriod,
  ECtaTheme,
} from 'common/typings/enums';
import { TOption } from 'common/typings/types';
import { capitalizeFirstCharacter } from 'common/utils/shared';

import {
  getRootSellBlockClasses,
  SELL_BLOCK_ROOT_CLASSNAME,
} from '../../utils';
import SellBlockCtas from '../SellBlockCtas';
import SellBlockPrice from '../SellBlockPrice';
import './SubscribeAndSaveSellBlock.scss';

const TwelvePack = '12 Pack';
const TwentyFourPack = '24 Pack';

enum EDeliverEvery {
  Week = 'week',
  TwoWeeks = '2 weeks',
  ThreeWeeks = '3 weeks',
  Month = '1 month',
}

const mapDeliverEveryToBillingPeriod: Record<EDeliverEvery, EBillingPeriod> = {
  [EDeliverEvery.Week]: EBillingPeriod.Week,
  [EDeliverEvery.TwoWeeks]: EBillingPeriod.Week,
  [EDeliverEvery.ThreeWeeks]: EBillingPeriod.Week,
  [EDeliverEvery.Month]: EBillingPeriod.Month,
};

const mapDeliverEveryToBillingInterval: Record<
  EDeliverEvery,
  EBillingInterval
> = {
  [EDeliverEvery.Week]: EBillingInterval.One,
  [EDeliverEvery.TwoWeeks]: EBillingInterval.Two,
  [EDeliverEvery.ThreeWeeks]: EBillingInterval.Three,
  [EDeliverEvery.Month]: EBillingInterval.One,
};

const SubscribeAndSaveSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  pdp,
  renderedBy,
}) => {
  const { subscribe_and_save_product_12, subscribe_and_save_product_24 } =
    pdp.acf_fields! || pdp.acf!;

  const [isModalVisible, toggleIsModalVisible, setIsModalVisible] =
    useToggle(false);
  const { isLoggedIn } = useSelector<IReduxState, { isLoggedIn: boolean }>(
    (state) => ({
      isLoggedIn: getAuthIsLoggedIn(state),
    })
  );

  const availableQuantities: IPdpAvailableQuantity[] = [];

  if (subscribe_and_save_product_12) {
    availableQuantities.push({ label: TwelvePack, value: '12' });
  }
  if (subscribe_and_save_product_24) {
    availableQuantities.push({ label: TwentyFourPack, value: '24' });
  }

  const [selectedPackSize, setSelectedPackSize] =
    React.useState<IPdpAvailableQuantity>(availableQuantities[0]);
  const [selectedInterval, setSelectedInterval] = React.useState<number>(1);
  const [selectedPeriod, setSelectedPeriod] = React.useState<EBillingPeriod>(
    EBillingPeriod.Week
  );

  useEffect(() => {
    setSelectedInterval(1);
  }, []);

  if (
    (!subscribe_and_save_product_12 && !subscribe_and_save_product_24) ||
    !availableQuantities.length
  ) {
    return null;
  }

  const handleOnPackSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value;
    const findSize = availableQuantities?.find(
      (size) => size.value === val && size.label.includes(val)
    );
    if (findSize) {
      setSelectedPackSize(findSize);
    }
  };

  const handleOnPeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value;
    const period = mapDeliverEveryToBillingPeriod[val];
    const findPeriod = Object.values(EBillingPeriod)?.find((p) => p === period);

    if (findPeriod) {
      setSelectedPeriod(findPeriod);

      if (findPeriod !== EBillingPeriod.Month) {
        const interval = mapDeliverEveryToBillingInterval[val];
        setSelectedInterval(parseInt(interval, 10));
      }
    }
  };

  const packSizeOptions: TOption[] = availableQuantities.map((size) => ({
    [size.value]: size.label,
  }));

  const billingPeriodOptions: TOption[] = Object.values(EDeliverEvery).map(
    (value) => ({
      [value]: capitalizeFirstCharacter(value),
    })
  );

  // const billingIntervalOptions: TOption[] = Object.entries(EBillingInterval).map(([, value]) => ({
  //   [value]: value
  // }));

  const priceHeadingSuffix =
    selectedPeriod === EBillingPeriod.Week
      ? `/ Every ${
          selectedInterval > 1 ? selectedInterval : ''
        } ${selectedPeriod}${selectedInterval > 1 ? 's' : ''}`
      : `/ ${selectedPeriod}`;

  const selectedPdp = (
    selectedPackSize.label === TwelvePack
      ? subscribe_and_save_product_12
      : subscribe_and_save_product_24
  ) as IPdpProduct;

  const customData = {
    billing_period: selectedPeriod,
    billing_interval: selectedInterval,
  };

  return (
    <>
      <div
        className={getRootSellBlockClasses(
          renderedBy,
          undefined,
          className,
          pdp.pdp_type
        )}
      >
        <Heading
          className={classNames(`${SELL_BLOCK_ROOT_CLASSNAME}__subheading`)}
          headingStyle="5"
          priority={3}
          children="Pack Size"
        />

        <Select
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__select ${SELL_BLOCK_ROOT_CLASSNAME}__select--subscribe`}
          name="pack-size"
          options={packSizeOptions}
          defaultValue={selectedPackSize.value}
          onChange={handleOnPackSizeChange}
        />

        <Heading
          className={classNames(`${SELL_BLOCK_ROOT_CLASSNAME}__subheading`)}
          headingStyle="5"
          priority={3}
          children="Deliver every"
        />

        <Select
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__select ${SELL_BLOCK_ROOT_CLASSNAME}__select--subscribe`}
          name="billing-period"
          options={billingPeriodOptions}
          defaultValue={selectedPeriod}
          onChange={handleOnPeriodChange}
        />

        <SellBlockPrice
          pdp={selectedPdp}
          renderedBy={renderedBy}
          selectedPackSize={selectedPackSize}
          selectedQuantity={1}
          headingSuffix={priceHeadingSuffix}
          shouldMultiplyPrice={false}
        />

        {!isLoggedIn ? (
          <>
            <p
              className={`${SELL_BLOCK_ROOT_CLASSNAME}__subscription-disclaimer mb-1`}
            >
              You must have an account to set up a subscription. <br />{' '}
              <span
                className={`${SELL_BLOCK_ROOT_CLASSNAME}__subscription-disclaimer-button`}
                role="button"
                onClick={() => setIsModalVisible(true)}
              >
                Please sign up or login here
              </span>{' '}
              to proceed.
            </p>

            <Button
              className={`${SELL_BLOCK_ROOT_CLASSNAME}__button mb-1`}
              theme={ECtaTheme.Brand}
              behaviour="action"
              isFull={true}
              onClick={() => setIsModalVisible(true)}
            >
              Login
            </Button>
          </>
        ) : (
          <>
            <SellBlockCtas
              pdp={selectedPdp}
              shouldDisableAddToBag={!isLoggedIn}
              renderedBy={renderedBy}
              selectedPackSize={{
                ...selectedPackSize,
                value: '1',
              }}
              selectedQuantity={1}
              shouldHideMoreInfo={true}
              customData={customData}
            />
          </>
        )}
      </div>

      {isModalVisible && !isLoggedIn && (
        <AuthModal
          isVisible={isModalVisible}
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__auth-modal`}
          onClose={toggleIsModalVisible}
          bodyOverflow={false}
        />
      )}
    </>
  );
};

export default SubscribeAndSaveSellBlock;
