import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import Heading from '@jpp/atoms/Heading';
import Button from '@jpp/molecules/Button';
import InputField from '@jpp/molecules/Form/InputField';
import { IPdpStoreState } from 'common/redux/pdp/reducer';
import { ECtaTheme, ESize } from 'common/typings/enums';
import axios from 'common/utils/axios/internal';

import ModalCore, { IModalChildProps } from '../ModalCore';
import './BackInStockModal.scss';

interface IBackInStockModalProps {
  pdp: IPdpStoreState;
}

const ROOT_CLASSNAME = 'BackInStockModal';

type TBackInStockModal = IModalChildProps & IBackInStockModalProps;

export const BackInStockModal: React.FunctionComponent<TBackInStockModal> = ({
  className,
  onClose,
  bodyOverflow = false,
  pdp,
}) => {
  const acf = pdp.acf_fields || pdp.acf;
  const [isSent, setIsSent] = useState(false);
  const id = acf?.default_product?.ID;
  const productId = id ? String(id) : undefined;
  const title = pdp.post_title || pdp.title;

  if (!productId) {
    return null;
  }

  return (
    <ModalCore
      isVisible={true}
      bodyOverflow={bodyOverflow}
      className={classNames(
        ROOT_CLASSNAME,
        className,
        `${ROOT_CLASSNAME}--${pdp.pdp_type}`
      )}
      hasOverlayClose={true}
      onClose={onClose}
    >
      {isSent ? (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Heading
            priority={2}
            headingStyle={3}
            className={`${ROOT_CLASSNAME}__heading`}
            isInnerHTML={false}
          >
            Thanks for signing up.
          </Heading>
          <Heading
            priority="3"
            className={`${ROOT_CLASSNAME}__heading`}
            isInnerHTML={false}
          >
            We will notify you when <span>{title}</span> is back in stock
          </Heading>
          <br />
          <Button
            type="button"
            theme={ECtaTheme.Brand}
            behaviour="action"
            size={ESize.Md}
            isFull={false}
            onClick={onClose}
            className={classNames(`${ROOT_CLASSNAME}__button`)}
          >
            Close
          </Button>
        </div>
      ) : (
        <>
          <div className={`${ROOT_CLASSNAME}__heading-wrapper`}>
            <Heading
              priority={2}
              headingStyle={3}
              className={`${ROOT_CLASSNAME}__heading`}
              isInnerHTML={false}
            >
              Be the first to know when <span>{title}</span> is back in stock
            </Heading>
          </div>

          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={async ({ email }, { resetForm }) => {
              try {
                if (!email) {
                  throw new Error('Missing email');
                }
                await axios.post('/klaviyo/backInStock', {
                  email,
                  productId,
                });
                setIsSent(true);
                return resetForm();
              } catch (e) {
                return;
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className={`${ROOT_CLASSNAME}__form`}>
                <InputField
                  name="email"
                  type="email"
                  label="Email Address"
                  className={`${ROOT_CLASSNAME}__input`}
                  placeholder="Email address"
                  required={true}
                />
                <Button
                  type="submit"
                  theme={ECtaTheme.Brand}
                  behaviour="action"
                  size={ESize.Md}
                  isFull={false}
                  disabled={isSubmitting}
                  className={classNames(`${ROOT_CLASSNAME}__button`, {
                    [`${ROOT_CLASSNAME}__button--is-disabled`]: isSubmitting,
                  })}
                >
                  Notify me
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </ModalCore>
  );
};
