import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Heading from '@jpp/atoms/Heading';
import ProductPrice from '@jpp/atoms/ProductPrice';
import Select from '@jpp/atoms/Select';
import { ISellBlockProps } from '@jpp/molecules/SellBlock/types';
import WYSIWYG from '@jpp/shared/WYSIWYG';
import { IReduxState } from 'common/redux/createStore';
import { IProduct } from 'common/redux/wooCommerce/products/reducer';
import { getAllStoreProducts } from 'common/redux/wooCommerce/products/selectors';
import { EPosition } from 'common/typings/enums';
import { TOption } from 'common/typings/types';

import {
  getRootSellBlockClasses,
  SELL_BLOCK_ROOT_CLASSNAME,
} from '../../utils';
import SellBlockCtas from '../SellBlockCtas';
import SellBlockQuantitySelector from '../SellBlockQuantitySelector';
import './GiftCardSellBlock.scss';

export interface IGiftCardOption {
  id: string;
  price: string;
  regularPrice: string;
}

const GiftCardSellBlock: React.FunctionComponent<ISellBlockProps> = ({
  className,
  renderedBy,
  pdp,
}) => {
  const { price, acf, acf_fields, regular_price } = pdp;
  const { default_product, short_description, gift_card_items } =
    acf_fields || acf || {};
  const stockAmount =
    pdp.stock_quantity || default_product?.stock_quantity || 0;

  const allProducts = useSelector<IReduxState, IProduct[]>((state) =>
    getAllStoreProducts(state)
  );
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [selectedGiftCard, setSelectedGiftCard] = useState<
    IGiftCardOption | undefined
  >(undefined);

  useEffect(() => {
    setSelectedQuantity(1);
  }, []);

  useEffect(() => {
    if (default_product) {
      setSelectedGiftCard({
        id: String(default_product.ID),
        price,
        regularPrice: regular_price,
      });
    }
  }, []);

  if (
    !gift_card_items ||
    gift_card_items.length === 0 ||
    !default_product ||
    !selectedGiftCard
  ) {
    return null;
  }

  const onQuantityChanged = (value: number) => {
    setSelectedQuantity(value);
  };

  const options: TOption[] = gift_card_items.map(({ product }) => {
    const prod = allProducts?.find((p) => String(p.id) === String(product.ID));
    if (!prod) {
      return;
    }
    return {
      [`${product.ID}`]: product.post_title,
    };
  });

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const findGiftCard = allProducts?.find(
      (product) => String(product.id) === id
    );

    if (findGiftCard) {
      const findPrice = findGiftCard?.price || findGiftCard?.name.split('-')[0];
      setSelectedGiftCard({
        id: String(findGiftCard.id),
        price: findPrice,
        regularPrice: findGiftCard?.regular_price || findPrice,
      });
    }
  };

  return (
    <div
      className={getRootSellBlockClasses(
        renderedBy,
        undefined,
        className,
        pdp.pdp_type
      )}
    >
      {short_description && (
        <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__description-wrapper`}>
          <WYSIWYG content={short_description} textAlign={EPosition.Center} />
        </div>
      )}

      {gift_card_items.length > 1 && (
        <Select
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__select`}
          name="gift-card"
          options={options}
          defaultValue={selectedGiftCard.id}
          onChange={handleOnChange}
        />
      )}

      <div className={`${SELL_BLOCK_ROOT_CLASSNAME}__price-wrapper`}>
        <Heading
          className={classNames(`${SELL_BLOCK_ROOT_CLASSNAME}__subheading`)}
          headingStyle="5"
          priority={3}
        >
          Price
        </Heading>

        <ProductPrice
          className={`${SELL_BLOCK_ROOT_CLASSNAME}__price`}
          price={selectedGiftCard.price}
          regularPrice={selectedGiftCard.regularPrice}
          quantity={selectedQuantity}
        />
      </div>

      <SellBlockQuantitySelector
        onQuantityChanged={onQuantityChanged}
        maxAmount={stockAmount}
        selectedQuantity={selectedQuantity}
      />

      <SellBlockCtas
        pdp={pdp}
        renderedBy={renderedBy}
        selectedGiftCard={selectedGiftCard}
        selectedQuantity={selectedQuantity}
      />
    </div>
  );
};

export default GiftCardSellBlock;
