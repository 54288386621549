import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';

import './UnderlineHeading.scss';

interface IUnderlineHeadingProps {
  className?: string;
  title: string;
}

const ROOT_CLASSNAME = 'UnderlineHeading';

const UnderlineHeading: React.FunctionComponent<IUnderlineHeadingProps> = ({
  className,
  title,
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <Heading
        priority={1}
        className={`${ROOT_CLASSNAME}__heading`}
        children={title}
      />
    </div>
  );
};

export default UnderlineHeading;
