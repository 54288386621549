import classNames from 'classnames';
import React from 'react';

import './Icon.scss';
import IconUrls from './utils/external';
import IconComponents from './utils/inline';
import { EIconType, TIconProps } from './utils/types';

const ROOT_CLASSNAME = 'Icon';

const IconElement =
  (type: EIconType): React.FunctionComponent<TIconProps> =>
  ({ className, inline = false, ...rest }) => {
    const classes = classNames(
      ROOT_CLASSNAME,
      className,
      `${ROOT_CLASSNAME}--${type?.toLowerCase()}`
    );

    if (inline) {
      const Component = IconComponents[type];
      return <Component className={classes} type="icon" />;
    }

    const iconSrc = IconUrls[type]?.default
      ? IconUrls[type]?.default
      : IconUrls[type];

    return (
      <img
        src={iconSrc}
        alt="Gipsy Hill Brew Icon"
        className={classes}
        {...rest}
      />
    );
  };

const Icons: Record<EIconType, React.FunctionComponent<TIconProps>> = {
  [EIconType.Arrow]: IconElement(EIconType.Arrow),
  [EIconType.ArrowLong]: IconElement(EIconType.ArrowLong),
  [EIconType.Bag]: IconElement(EIconType.Bag),
  [EIconType.Beer]: IconElement(EIconType.Beer),
  [EIconType.Burnt]: IconElement(EIconType.Burnt),
  [EIconType.Brewery]: IconElement(EIconType.Brewery),
  [EIconType.Broccoli]: IconElement(EIconType.Broccoli),
  [EIconType.Box]: IconElement(EIconType.Box),
  [EIconType.BoxOpen]: IconElement(EIconType.BoxOpen),
  [EIconType.Drinkaware]: IconElement(EIconType.Drinkaware),
  [EIconType.Eye]: IconElement(EIconType.Eye),
  [EIconType.EyeSlash]: IconElement(EIconType.EyeSlash),
  [EIconType.Medicinal]: IconElement(EIconType.Medicinal),
  [EIconType.Fruity]: IconElement(EIconType.Fruity),
  [EIconType.Floral]: IconElement(EIconType.Floral),
  [EIconType.Citrus]: IconElement(EIconType.Citrus),
  [EIconType.Close]: IconElement(EIconType.Close),
  [EIconType.Employee]: IconElement(EIconType.Employee),
  [EIconType.Sour]: IconElement(EIconType.Sour),
  [EIconType.Fresh]: IconElement(EIconType.Fresh),
  [EIconType.Grain]: IconElement(EIconType.Grain),
  [EIconType.Grassy]: IconElement(EIconType.Grassy),
  [EIconType.Group]: IconElement(EIconType.Group),
  [EIconType.Hoppy]: IconElement(EIconType.Hoppy),
  [EIconType.Logo]: IconElement(EIconType.Logo),
  [EIconType.LogoSocial]: IconElement(EIconType.LogoSocial),
  [EIconType.LogoSymbol]: IconElement(EIconType.LogoSymbol),
  [EIconType.LogoText]: IconElement(EIconType.LogoText),
  [EIconType.Malty]: IconElement(EIconType.Malty),
  [EIconType.Dairy]: IconElement(EIconType.Dairy),
  [EIconType.Strong]: IconElement(EIconType.Strong),
  [EIconType.Nutty]: IconElement(EIconType.Nutty),
  [EIconType.Refreshing]: IconElement(EIconType.Refreshing),
  [EIconType.Salty]: IconElement(EIconType.Salty),
  [EIconType.Peppery]: IconElement(EIconType.Peppery),
  [EIconType.Smokey]: IconElement(EIconType.Smokey),
  [EIconType.Spicy]: IconElement(EIconType.Spicy),
  [EIconType.Earthy]: IconElement(EIconType.Earthy),
  [EIconType.Vegetal]: IconElement(EIconType.Vegetal),
  [EIconType.Woody]: IconElement(EIconType.Woody),
  [EIconType.Yeasty]: IconElement(EIconType.Yeasty),
  [EIconType.Facebook]: IconElement(EIconType.Facebook),
  [EIconType.Instagram]: IconElement(EIconType.Instagram),
  [EIconType.Twitter]: IconElement(EIconType.Twitter),
  [EIconType.Untappd]: IconElement(EIconType.Untappd),
  [EIconType.GooglePay]: IconElement(EIconType.GooglePay),
};

export default Icons;
