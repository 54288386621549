import { FormikBag, withFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  RECAPTCHA_DEFAULT_VALUE,
  RECAPTCHA_YUP,
} from '@jpp/hooks/useGoogleReCaptcha';
import { getAuthIsLoggedIn } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import axios from 'common/utils/axios/internal';
import {
  invalidMessageMap,
  requiredMessageMap,
} from 'common/utils/shared/validation';

import ForgotPasswordForm, {
  IForgotPasswordFormProps,
  IForgotPasswordFormValues,
  IStoreForgotPasswordFormProps,
  TForgotPasswordFormFields,
} from './ForgotPasswordForm';

const mapPropsToValues = (): IForgotPasswordFormValues => ({
  email: '',
  ...RECAPTCHA_DEFAULT_VALUE,
});

const validationSchema: Yup.ObjectSchema<
  Yup.Shape<TForgotPasswordFormFields | undefined, TForgotPasswordFormFields>
> = Yup.object().shape({
  email: Yup.string()
    .email(invalidMessageMap.email)
    .required(requiredMessageMap.email),
  ...RECAPTCHA_YUP,
});

const handleSubmit = async (
  { email, token }: IForgotPasswordFormValues,
  {
    setFieldError,
    setStatus,
  }: FormikBag<IForgotPasswordFormProps, IForgotPasswordFormValues>
) => {
  try {
    const { data: message } = await axios.post<string>(
      '/auth/forgot-password',
      {
        email,
        token,
      }
    );
    setStatus(message);
  } catch (error) {
    const {
      data: { message },
    } = error?.response || {};
    setFieldError('errorMessage', message);
  }
};

const mapStateToProps = (
  state: IReduxState
): IStoreForgotPasswordFormProps => ({
  isLoggedIn: getAuthIsLoggedIn(state),
});

export default connect<
  IStoreForgotPasswordFormProps,
  never,
  IForgotPasswordFormProps
>(mapStateToProps)(
  withFormik<IForgotPasswordFormProps, IForgotPasswordFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
  })(ForgotPasswordForm)
);
