import classNames from 'classnames';
import React from 'react';

import ForgotPasswordForm from '@jpp/molecules/Auth/ForgotPasswordForm';
import Container from '@jpp/shared/Grid/Container';
import Flex from '@jpp/shared/Grid/Flex';
import Row from '@jpp/shared/Grid/Row';

import { AUTH_ROOT_CLASSNAME, IAuthChildProps } from '../../Auth';
import './ForgotPassword.scss';

const ROOT_CLASSNAME = 'ForgotPassword';

const ForgotPassword: React.FunctionComponent<IAuthChildProps> = ({
  className,
}) => {
  return (
    <div className={classNames(ROOT_CLASSNAME, className)}>
      <Container className={`${AUTH_ROOT_CLASSNAME}__container`} fluid={true}>
        <Row>
          <Flex colLg={8} center={true}>
            <ForgotPasswordForm className={`${ROOT_CLASSNAME}__form`} />
          </Flex>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
