import { add, format, parseISO } from 'date-fns';

import {
  ICartLineItem,
  ISubscriptionFirstRenewal,
} from 'common/redux/wooCommerce/cart/reducer';
import { EBillingPeriod } from 'common/typings/enums';

export const hasOnlyVirtualProductsInCart = (
  cartLineItems: ICartLineItem[]
): boolean => {
  const items = cartLineItems.filter(
    (cartItem) => cartItem.virtual === true || cartItem.downloadable === true
  );
  return cartLineItems.length === 0
    ? false
    : items.length === cartLineItems.length;
};

export const isDownloadableProduct = (cartLineItem: ICartLineItem): boolean =>
  !!cartLineItem.downloadable;

export const isVirtualProduct = (cartLineItem: ICartLineItem): boolean =>
  !!cartLineItem.virtual;

export const removeVirtualProducts = (
  cartLineItems: ICartLineItem[]
): ICartLineItem[] =>
  cartLineItems.filter((cartItem) => cartItem.virtual !== true);

export const removeDownloadableProducts = (
  cartLineItems: ICartLineItem[]
): ICartLineItem[] =>
  cartLineItems.filter((cartItem) => cartItem.downloadable !== true);

export const getCartSubtotalLessVirtual = (
  freeDeliveryThreshold: number,
  cartLineItems: ICartLineItem[]
): number => {
  const hasOnlyVirtualProducts = hasOnlyVirtualProductsInCart(cartLineItems);
  const cartItemsLessVirtualAndDownloadable =
    removeVirtualProducts(cartLineItems);
  const deliverySubtotal = cartItemsLessVirtualAndDownloadable
    .map(
      (item) =>
        parseFloat(item?.line_subtotal?.toFixed(2)) + item.line_subtotal_tax
    )
    .reduce((a, b) => a + b, 0);

  const amount = hasOnlyVirtualProducts ? 0 : deliverySubtotal;

  return parseFloat((freeDeliveryThreshold - amount).toFixed(2));
};

export const isSubscribeAndSaveProduct = (cartItem: ICartLineItem) =>
  !!(cartItem.billing_interval && cartItem.billing_period);

export const getSubscribeAndSaveProducts = (
  cartLineItems: ICartLineItem[]
): ICartLineItem[] =>
  cartLineItems.filter((item) => isSubscribeAndSaveProduct(item));

export const isSubscriptionProduct = (cartItem: ICartLineItem) =>
  !!cartItem.subscription_data || isSubscribeAndSaveProduct(cartItem);

export const hasSubscriptionProducts = (cartLineItems: ICartLineItem[]) =>
  cartLineItems.some((item) => isSubscriptionProduct(item));

export const getSubscriptionProducts = (
  cartLineItems: ICartLineItem[]
): ICartLineItem[] =>
  cartLineItems.filter((item) => isSubscriptionProduct(item));

export const getSubscriptionFirstRenewal = (
  fallback: ISubscriptionFirstRenewal = {} as ISubscriptionFirstRenewal,
  interval?: string,
  period?: EBillingPeriod,
  isPremiumSubscription = false
): ISubscriptionFirstRenewal => {
  if (interval && period && !isPremiumSubscription) {
    if (period === EBillingPeriod.Month || period === EBillingPeriod.Week) {
      const date =
        period === EBillingPeriod.Month
          ? add(new Date(), {
              months: parseInt(interval, 10),
            })
          : add(new Date(), {
              weeks: parseInt(interval, 10),
            });

      return {
        date: date?.toISOString()?.split('T')?.[0] + ' 00:00:00',
        string: format(date, 'PPP'),
        time: date.getTime(),
      };
    }
  }
  if (isPremiumSubscription) {
    const date = add(parseISO(fallback.date), {
      months: 1,
    });

    return {
      date: date?.toISOString()?.split('T')?.[0] + ' 00:00:00',
      string: format(date, 'PPP'),
      time: date.getTime(),
    };
  }
  return fallback;
};

export const getCartItemSubscriptionData = (prod: ICartLineItem) => {
  const { subscription_data, billing_interval, billing_period } = prod;
  const interval =
    billing_interval || subscription_data?.billing_period?.interval;
  const period = billing_period || subscription_data?.billing_period?.period;
  const firstRenewal = getSubscriptionFirstRenewal(
    subscription_data?.first_renewal,
    billing_interval,
    billing_period as EBillingPeriod,
    !!subscription_data
  );
  return { interval, period, firstRenewal };
};
