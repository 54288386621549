import { FormikBag, withFormik } from 'formik';
import Router from 'next/router';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  RECAPTCHA_DEFAULT_VALUE,
  RECAPTCHA_YUP,
} from '@jpp/hooks/useGoogleReCaptcha';
import { IAuthStoreState } from 'common/redux/auth/reducer';
import { getAuthIsLoggedIn } from 'common/redux/auth/selectors';
import { IReduxState } from 'common/redux/createStore';
import { getCurrentUser, login } from 'common/redux/rootActions';
import { trackCustomer } from 'common/tracking/customer';
import { EPageType } from 'common/typings/enums';
import axios from 'common/utils/axios';
import internalAxios from 'common/utils/axios/internal';
import {
  invalidMessageMap,
  requiredMessageMap,
} from 'common/utils/shared/validation';

import ConfigProvider from '../../../../services/configProvider';
import LoginForm, {
  IDispatchLoginFormProps,
  ILoginFormProps,
  ILoginFormValues,
  IStoreLoginFormProps,
  TLoginFormFields,
} from './LoginForm';

type TProps = ILoginFormProps & IStoreLoginFormProps & IDispatchLoginFormProps;

const mapPropsToValues = (): ILoginFormValues => ({
  email: '',
  password: '',
  ...RECAPTCHA_DEFAULT_VALUE,
});

const validationSchema: Yup.ObjectSchema<
  Yup.Shape<TLoginFormFields | undefined, TLoginFormFields>
> = Yup.object().shape({
  email: Yup.string()
    .email(invalidMessageMap.email)
    .required(requiredMessageMap.email),
  password: Yup.string().min(6).required(requiredMessageMap.password),
  ...RECAPTCHA_YUP,
});

const handleSubmit = async (
  values: ILoginFormValues,
  {
    props: {
      onLogin,
      onGetUser,
      onSuccess,
      shouldRedirect,
      redirectPath,
      shouldGetCart = true,
    },
    setFieldError,
  }: FormikBag<TProps, ILoginFormValues>
) => {
  try {
    const { data } = await axios.post<IAuthStoreState>(
      `${ConfigProvider.getValue(
        'SHOP_URL',
        'https://shop.gipsyhillbrew.com'
      )}/wp-json/jwt-auth/v1/token?skip_cache=1`,
      {
        username: values.email,
        password: values.password,
        token: values.token,
      }
    );
    const { data: customer } = await internalAxios.post('/payment/customer', {
      customer_email: values.email,
    });

    await onLogin({ data, customer }, shouldGetCart);

    trackCustomer(customer);

    if (onGetUser) {
      await onGetUser();
    }

    if (onSuccess) {
      onSuccess();
    }
    if (shouldRedirect) {
      Router.push(redirectPath || EPageType.Index);
    }
  } catch (error) {
    const { response = {} } = error;
    const {
      data: { message },
    } = response;
    setFieldError('errorMessage', 'Username/Password incorrect.' || message);
  }
};

const mapStateToProps = (state: IReduxState): IStoreLoginFormProps => ({
  isLoggedIn: getAuthIsLoggedIn(state),
});

const mapDispatchToProps: IDispatchLoginFormProps = {
  onLogin: login,
  onGetUser: getCurrentUser,
};

export default connect<
  IStoreLoginFormProps,
  IDispatchLoginFormProps,
  ILoginFormProps
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik<TProps, ILoginFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
  })(LoginForm)
);
