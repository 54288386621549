import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Cookies from 'js-cookie';

import { GHBC_AUTH_TOKEN } from 'common/utils/constants/cookies';

export const configTransformer = (
  config: AxiosRequestConfig,
  isCartAPICall = false
): AxiosRequestConfig => {
  const token = Cookies.get(GHBC_AUTH_TOKEN);

  if (token && token !== 'undefined') {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.url = `${config.url}${
    config.url?.includes('?') ? '&skip_cache=1' : '?skip_cache=1'
  }`;

  if (isCartAPICall) {
    config.withCredentials = true;
  }

  return config;
};

const defaultAxiosInstance: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

defaultAxiosInstance.interceptors.request.use(
  (config: AxiosResponse) => {
    return configTransformer(config);
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default defaultAxiosInstance;
