import classNames from 'classnames';
import React from 'react';

import './Container.scss';

interface IContainerProps {
  fluid?: boolean;
  className?: string;
  tagElement?: Core.TTag;
}

export const Container: React.FunctionComponent<IContainerProps> = ({
  fluid = false,
  children,
  className,
  tagElement = 'div',
  ...props
}) => {
  const TagElement = `${tagElement}` as unknown as React.FunctionComponent<any>;

  return (
    <TagElement
      className={classNames(
        {
          container: !fluid,
          'container--fluid': fluid,
        },
        className
      )}
      {...props}
    >
      {children}
    </TagElement>
  );
};

export default Container;
