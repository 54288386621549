import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { CART_ENDPOINT } from 'common/utils/constants';

import { configTransformer } from '.';
import ConfigProvider from '../../services/configProvider';

const defaultAxiosInstance: AxiosInstance = axios.create({
  baseURL: `${ConfigProvider.getValue('SHOP_URL')}/${CART_ENDPOINT}`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

defaultAxiosInstance.interceptors.request.use(
  (config: AxiosResponse) => {
    return configTransformer(config, true);
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default defaultAxiosInstance;
